import { qx_ePrivilege_enum } from '@/graphql/types';
import { AUDIT_DEFAULT_ICON } from '@/config/audit';
import { ConfigRouteObject } from '@/types';

export const AUDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', AUDIT_DEFAULT_ICON],
  UI_LABEL: 'Audit Logs',
  ROUTE_PATH: 'audit',
  ROUTE_NAME: 'audit.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.Audit]
};

export const AUDIT_VIEW_TEMPLATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', AUDIT_DEFAULT_ICON],
  UI_LABEL: 'View Audit Log',
  ROUTE_PATH: ':auditType',
  ROUTE_NAME: 'audit.view',
  ROUTE_PARAM: 'auditType',
  PRIVILEGES: [qx_ePrivilege_enum.Audit]
};
