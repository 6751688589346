import RedirectUserToHomeIfAuthenticatedMiddleware from '@/router/middleware/redirect-user-to-home-if-authenticated';
import UserTwoFactorNotEnabledMiddleware from '@/router/middleware/user-two-factor-not-enabled';
import UserIsAuthenticatedMiddleware from '@/router/middleware/user-is-authenticated';
import UserHasTwoFactorMiddleware from '@/router/middleware/user-has-two-factor';
import ForceLogoutMiddleware from '@/router/middleware/force-logout';
import { CONFIG_ROUTES } from '@/config/routes';
import { useAuthStore } from '@/store/auth';
import {
  AUTH_TERMS_AGREEMENT_ROUTE,
  AUTH_TWO_FACTOR_ROUTE,
  AUTH_LOGOUT_ROUTE,
  AUTH_LOGIN_ROUTE,
  AUTH_TWO_FACTOR_SETUP_ROUTE
} from '@/config/routes/auth';

/**
 * Pay attention to some routes have a `partialAuth` meta property.
 * This is referred to in `App.vue` to determine whether or not
 * the global app loading indicator is visible on these pages.
 */
export default [{
  path: CONFIG_ROUTES.AUTH_PATH_PREFIX,
  components: {
    default: () => import('@/views/auth/AuthContainer.vue')
  },
  children: [
    {
      path: AUTH_LOGOUT_ROUTE.ROUTE_PATH,
      name: AUTH_LOGOUT_ROUTE.ROUTE_NAME,
      meta: {
        middleware: [ForceLogoutMiddleware]
      },
      components: {
        default: () => import('@/views/BlankPage.vue')
      },
    },
    {
      path: AUTH_LOGIN_ROUTE.ROUTE_PATH,
      name: AUTH_LOGIN_ROUTE.ROUTE_NAME,
      meta: {
        middleware: [RedirectUserToHomeIfAuthenticatedMiddleware]
      },
      components: {
        default: () => import('@/views/auth/AuthLoginPage.vue')
      },
    },
    {
      path: AUTH_TERMS_AGREEMENT_ROUTE.ROUTE_PATH,
      name: AUTH_TERMS_AGREEMENT_ROUTE.ROUTE_NAME,
      meta: {
        middleware: [UserIsAuthenticatedMiddleware, () => {
          const auth = useAuthStore();
          if (auth.user.acceptedTerms) {
            return { name: CONFIG_ROUTES.APP_HOME };
          }
        }],
        partialAuth: true
      },
      components: {
        default: () => import('@/views/auth/TermsAgreementPage.vue')
      }
    },
    {
      path: AUTH_TWO_FACTOR_ROUTE.ROUTE_PATH,
      name: AUTH_TWO_FACTOR_ROUTE.ROUTE_NAME,
      meta: {
        middleware: [UserHasTwoFactorMiddleware],
        partialAuth: true
      },
      components: {
        default: () => import('@/views/auth/AuthVerifyTwoFactorPage.vue')
      }
    },
    {
      path: AUTH_TWO_FACTOR_SETUP_ROUTE.ROUTE_PATH,
      name: AUTH_TWO_FACTOR_SETUP_ROUTE.ROUTE_NAME,
      meta: {
        middleware: [UserTwoFactorNotEnabledMiddleware],
        partialAuth: true
      },
      components: {
        default: () => import('@/views/auth/AuthSetupTwoFactorPage.vue')
      }
    },
    // Catch-all
    {
      path: '',
      redirect: { name: CONFIG_ROUTES.APP_HOME }
    },
    {
      path: ':pathMatch(.*)*',
      redirect: { name: AUTH_LOGIN_ROUTE.ROUTE_NAME }
    }
  ]
}]
