import {
  PLACES_ROUTE,
  PLACE_CREATE_ROUTE,
  PLACE_EDIT_ROUTE,
  PLACE_VIEW_ROUTE,
  PLACE_VIEW_INFO_ROUTE
} from '@/config/routes';

export default [
  {
    path: PLACES_ROUTE.ROUTE_PATH,
    name: PLACES_ROUTE.ROUTE_NAME,
    components: {
      default: () => import(/* webpackChunkName: "container-places" */ './PlacesContainer.vue')
    },
    meta: {
      fillHeight: true,
      privileges: PLACES_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * Create Place
       */
      {
        path: PLACE_CREATE_ROUTE.ROUTE_PATH,
        name: PLACE_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: PLACE_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true,
          fillHeight: true,
        },
        components: {
          default: () => import(/* webpackChunkName: "page-place-create" */ './PlaceCreatePage.vue')
        },
        props: true // doesn't work and I hate it
      },

      /**
       * CONTAINER
       */
      {
        path: PLACE_VIEW_ROUTE.ROUTE_PATH,
        meta: {
          privileges: PLACE_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true,
        },
        components: {
          default: () => import(/* webpackChunkName: "container-place-view" */ './PlacesContainerView.vue')
        },
        children: [

          /**
           * View Place - Info
           */
          {
            path: PLACE_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: PLACE_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: PLACE_VIEW_INFO_ROUTE.PRIVILEGES,
              fillHeight: true,
            },
            components: {
              default: () => import(/* webpackChunkName: "page-place-view-info" */ './PlaceViewInfoPage.vue')
            }
          },
          /**
           * Edit Place
           */
          {
            path: PLACE_EDIT_ROUTE.ROUTE_PATH,
            name: PLACE_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: PLACE_EDIT_ROUTE.PRIVILEGES,
              fillHeight: true,
            },
            components: {
              default: () => import(/* webpackChunkName: "page-place-edit" */ './PlaceEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];
