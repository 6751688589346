<template>
  <CameraMediaProfilesIcon :status="mediaProfilesStatus" v-if="showIcon" />
  <span v-else-if="showLabel">
    {{ mediaProfilesStatusString }}
  </span>
</template>

<script lang="ts">
import { getCameraMediaProfilesDefaultIcon } from '@/constants/camera';
import { smartIconValidator } from './helpers/smart-icon-validator';
import { qx_eDeviceConnectionStatus_enum } from '@/graphql/types';
import { CAMERA_DISABLED_BY } from '@/constants/camera';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    camera: {
      type: Object,
      required: true
    },
    type: {
      required: false,
      default: "icon",
      validator(value) {
        return smartIconValidator(value);
      }
    }
  },
  computed: {
    gatewayIsDisconnected() {
      return !(this.camera?.gatewayConnectionStatus || false)
    },
    cameraIsNotConnected() {
      const allowedStates = [
        qx_eDeviceConnectionStatus_enum.Connected,
        qx_eDeviceConnectionStatus_enum.NoEvents,
      ]
      return !allowedStates.includes(this.camera?.deviceConnectionStatus)
    },
    cameraIsDisabled() {
      return !this.camera?.enabled
    },
    mediaProfiles() {
      return this.camera?.mediaProfiles || [];
    },
    mediaProfilesStatusString() {
      if (this.gatewayIsDisconnected) return "None"; // This is too long: CAMERA_MEDIA_PROFILES_STATUS_ICON[CAMERA_DISABLED_BY.GATEWAY].value;
      if (this.cameraIsDisabled) return "None"; // This is too long: CAMERA_MEDIA_PROFILES_STATUS_ICON[CAMERA_DISABLED_BY.CAMERA_DISABLED].value;
      if (this.cameraIsNotConnected) return "None"; // This is too long: CAMERA_MEDIA_PROFILES_STATUS_ICON[CAMERA_DISABLED_BY.CAMERA_DISCONNECTED].value;
      const status = getCameraMediaProfilesDefaultIcon(this.mediaProfiles)
      return status?.type || ''
    },
    mediaProfilesStatus() {
      if (this.gatewayIsDisconnected) return CAMERA_DISABLED_BY.GATEWAY
      if (this.cameraIsDisabled) return CAMERA_DISABLED_BY.CAMERA_DISABLED
      if (this.cameraIsNotConnected) return CAMERA_DISABLED_BY.CAMERA_DISCONNECTED
      return this.camera?.mediaProfiles
    },
    showIcon() {
      return this.type === "icon";
    },
    showLabel() {
      return this.type === "label";
    }
  }
});
</script>
