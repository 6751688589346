import { qx_ePrivilege_enum } from '@/graphql/types';
import { VIEW_DEFAULT_ICON } from '@/config/view';
import { ConfigRouteObject } from '@/types';
import { LIVE_ROUTE } from './live';

export const VIEWS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', VIEW_DEFAULT_ICON],
  UI_LABEL: 'Views',
  ROUTE_PATH: 'views',
  ROUTE_NAME: 'views.index',
  ROUTE_PARAM: '',
  PRIVILEGES: LIVE_ROUTE.PRIVILEGES
};

export const VIEW_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', VIEW_DEFAULT_ICON],
  UI_LABEL: 'Create View',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'view.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.ViewConfig]
};

export const VIEW_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit View',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'view.edit',
  ROUTE_PARAM: 'viewId',
  PRIVILEGES: [qx_ePrivilege_enum.ViewConfig]
};

// name used between two route names
const VIEW_VIEW_ROUTE_NAME_SHARED = 'view.view';

// this route does not register it's ROUTE_NAME because it would duplicate the info route
export const VIEW_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', VIEW_DEFAULT_ICON],
  UI_LABEL: 'View View',
  ROUTE_PATH: ':viewId',
  ROUTE_NAME: VIEW_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'viewId',
  PRIVILEGES: [qx_ePrivilege_enum.LiveVideo]
};

export const VIEW_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: VIEW_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'viewId',
  PRIVILEGES: [qx_ePrivilege_enum.LiveVideo]
};
