// TODO :: search app for `qx | qumulex` variations and replace with this
export const APP_WINDOW_KEY = 'qxAppConfig';
// TODO :: search app for `QxControl` variations and replace with this
export const APP_NAME = 'QxControl';
export const ROOT_PLACE_NAME = 'Qumulex';
export const ROOT_PLACE_ID = 'qx';
export const ROOT_USER_DOMAIN = 'qumulex.com';
export const APP_PRODUCTION_URL = 'app.qumulex.io'
export const QX_SUPPORT_STAFF = 'Qumulex Support Staff'
export const VENDOR_STAFF = 'Security Dealer'
