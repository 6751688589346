<template>
  <FaLayer v-tooltip="tooltip">
    <FaIcon
      :class="{
        ['IconColor IconColor--'+selectedIcon.color]: selectedIcon.color,
        [selectedIcon.class]: selectedIcon.class
      }"
      :mask="selectedIcon.mask || null"
      :icon="selectedIcon.icon"
      :transform="selectedIcon.transform || null"
      :opacity="selectedIcon.opacity || null"
      :style="statusConfig.style || null"
    />
    <FaIcon
      :class="{
        ['IconColor IconColor--'+selectedIcon.subColor]: selectedIcon.subColor,
        ['IconColor IconColor--'+selectedIcon.color]: !selectedIcon.subColor,
        [selectedIcon.subClass]: selectedIcon.subClass
      }"
      :icon="statusConfig.subIcon"
      :mask="statusConfig.subMask && ['fas', statusConfig.subMask] || null"
      :transform="statusConfig.subTransform || null"
      :opacity="statusConfig.subOpacity || null"
      :style="statusConfig.subStyle || null"
      v-if="statusConfig.subIcon"
    />
  </FaLayer>
</template>

<script lang="ts">
import { qx_ePACSStatus_enum, qx_eDeviceConnectionStatus_enum } from '@/graphql/types';
import { DEVICE_DEFAULT_ICON, getDeviceStatusIcon } from '@/constants/device';
import { mixinFaIcons, mixinStatusProps } from '@/icons/mixins';
import { isBoolean } from '@/functions/boolean';
import { isString } from '@/functions/string';
import { isArray } from '@/functions/array';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [mixinFaIcons],
  props: {
    ...mixinStatusProps,
    gatewayConnected: {
      default: undefined,
      type: Boolean,
    },
    connection: {
      type: [String, Boolean],
      required: false,
      default: undefined,
      validator: val => {
        if (isBoolean(val) && !val) return true
        if (!isString(val)) return false
        // TODO :: yuck
        return ['disabled', ...Object.values(qx_eDeviceConnectionStatus_enum)].includes(val)
      }
    },
    pacs: {
      type: [String, Boolean],
      required: false,
      default: undefined,
      validator: val => {
        if (isBoolean(val) && !val) return true
        if (!isString(val)) return false
        return Object.values(qx_ePACSStatus_enum).includes(val)
      }
    },
    pacsHardware: {
      type: [String, Boolean],
      required: false,
      default: undefined,
      validator: val => {
        if (isBoolean(val)) return val
        if (!isString(val)) return false
        return Object.values(qx_ePACSStatus_enum).includes(val)
      }
    }
  },
  computed: {
    // get information about the icon status
    statusConfig() {
      const { gatewayConnected, connection, pacs, pacsHardware } = this
      if (!connection && !pacs && !pacsHardware && gatewayConnected === undefined) return false
      return getDeviceStatusIcon(gatewayConnected, connection, pacs, pacsHardware)
    },
    // build icon details
    icon() {
      const { disabled, statusConfig } = this
      let icon = ['fas', DEVICE_DEFAULT_ICON]
      if (disabled || !statusConfig) return icon

      icon = isArray(statusConfig.icon) && statusConfig.icon || ['fas', statusConfig.icon]

      return icon
    },
    mask() {
      const { disabled, statusConfig } = this
      let mask = null
      if (disabled) return mask
      if (statusConfig.mask) mask = isArray(statusConfig.mask) && statusConfig.mask || ['fas', statusConfig.mask]
      return mask
    },

    // define icon and tooltip
    selectedIcon() {
      const { icon, mask, statusConfig, disabled, status} = this
      const reversedIcon = statusConfig.mask

      return {
        ...statusConfig,
        color: disabled && 'grey' || (statusConfig.color || null),
        icon: !reversedIcon ? icon : mask,
        mask: !reversedIcon ? mask : icon,
        value: status,
      }
    },
    tooltip() {
      if (!this.selectedIcon?.tooltip) return false
      return {
        triggers: ['click', 'hover', 'focus'],
        content: `Device: ${this.selectedIcon.tooltip}`,
      }
    }
  }
});
</script>
