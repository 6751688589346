<template>
  <BatteryIcon v-if="showIcon"
    :disabled="disabled"
    :parentDisconnected="gatewayOrDeviceNotConnected"
    :status="status"
  />
  <span v-else-if="showLabel">
    {{ label }}
  </span>
</template>
<script lang="ts">
import { qx_eDeviceConnectionStatus_enum, qx_eBatteryLevel_enum } from '@/graphql/types';
import { smartIconValidator } from './helpers/smart-icon-validator';
import { getBatteryIconStatus } from '@/constants/battery';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    door: {
      type: Object,
      required: true,
    },
    type: {
      required: false,
      default: "icon",
      validator(value) {
        return smartIconValidator(value);
      }
    }
  },
  computed: {
    disabled() {
      return !this.door?.enabled || this.door.disabledByLicense;
    },
    status() {
      if (this.door?.type !== 'wireless') return false;
      return this.door?.batteryLevel || qx_eBatteryLevel_enum.Unknown;
    },
    label() {
      if (this.door?.type !== 'wireless') return "none";
      return getBatteryIconStatus(this.status, this.gatewayOrDeviceNotConnected, !this.disabled)?.tooltip || '';
    },
    gatewayOrDeviceNotConnected() {
      const gatewayConnectionStatus = this.door?.gatewayConnectionStatus || this.door?.device.gateway?.connectionStatus
      const deviceConnectionStatus = (this.door?.deviceConnectionStatus || this.door?.device?.connectionStatus) === qx_eDeviceConnectionStatus_enum.Connected;
      const fullyConnected =  gatewayConnectionStatus && deviceConnectionStatus
      return !(fullyConnected || false);
    },
    showIcon() {
      if (this.door?.type !== 'wireless') return false;
      return this.type === "icon";
    },
    showLabel() {
      return this.type === "label";
    }
  }
})
</script>

