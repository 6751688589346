import { onMounted, readonly, ref } from "vue";

export function useSuperSaiyanMode() {
  const isSuperSaiyan = ref(false);
  onMounted(() => {
    const powerLevel = localStorage.getItem('qx.power.level');
    const number = parseInt(powerLevel || '0')
    isSuperSaiyan.value = !isNaN(number) ? (number >= Math.pow(12247.448713915, 2)) : false;
  });
  return readonly(isSuperSaiyan)
}
