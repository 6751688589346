import { hasGroupOperator } from './operators'
import { isArray } from '@/functions/array';
import { RuleCondition } from './../types';

/**
 * Confirms there is at least 1 condition
 * (not a group) within the node.
 */
export const hasAtLeastOneValidCondition = (nodes: RuleCondition[]): boolean => {

  return nodes.some(traverseAndFindAtLeastOneCondition)

  function traverseAndFindAtLeastOneCondition(node: RuleCondition | RuleCondition[]): boolean {
    if (!node) return false;

    if (isArray(node)) {
      // @ts-ignore
      return node.some(traverseAndFindAtLeastOneCondition);
    }

    // @ts-ignore
    const isGroupOperator = hasGroupOperator(node);
    if (isGroupOperator) {
      // @ts-ignore
      return node[isGroupOperator.value].some(traverseAndFindAtLeastOneCondition)
    }

    return isValidCondition(node)
  }
}


/**
 * Checks that a condition has a field, operator, and value.
 * {
 *   field: {
 *     operator: value
 *   }
 * }
 */
export const isValidCondition = (condition: RuleCondition): boolean => {
  try {
    const field = Object.keys(condition).filter(key => key !== 'uuid')?.[0]
    if (!field) return false

    const operator = Object.keys(condition[field])[0]
    if (!operator) return false

    // value could be "false" and that is valid
    const value = condition[field][operator]
    // TODO :: lol, this needs to be something else...
    const hasValue = value ?? '098765-no-value'
    return hasValue !== '098765-no-value'

  } catch (error) {
    return false
  }
}
