import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const GROUPS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'users'],
  UI_LABEL: 'Groups',
  ROUTE_PATH: 'groups',
  ROUTE_NAME: 'groups.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleView]
};

export const GROUP_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'users'],
  UI_LABEL: 'Create Group',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'group.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleConfig]
};

export const GROUP_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Group',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'group.edit',
  ROUTE_PARAM: 'groupId',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleConfig]
};

// name used between two route names
const GROUP_VIEW_ROUTE_NAME_SHARED = 'group.view';

// this route does not register it's ROUTE_NAME
// that is because it would duplicate the info route
export const GROUP_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'users'],
  UI_LABEL: 'View Group',
  ROUTE_PATH: ':groupId',
  ROUTE_NAME: GROUP_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'groupId',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleView]
};

export const GROUP_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: GROUP_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'groupId',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleView]
};
