import { ConfigRouteObject } from '@/types';

export const AUTH_LOGIN_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'sign-in-alt'],
  UI_LABEL: 'Login',
  ROUTE_PATH: 'login',
  ROUTE_NAME: 'auth.account.login',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const AUTH_LOGOUT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'right-from-bracket'],
  UI_LABEL: 'Logout',
  ROUTE_PATH: 'logout',
  ROUTE_NAME: 'auth.account.logout',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const AUTH_TERMS_AGREEMENT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'file-signature'],
  UI_LABEL: 'Terms of Service',
  ROUTE_PATH: 'terms-agreement',
  ROUTE_NAME: 'auth.tos',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const AUTH_TWO_FACTOR_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'shield-keyhole'],
  UI_LABEL: 'Two-Factor Verification',
  ROUTE_PATH: 'two-factor-verify',
  ROUTE_NAME: 'auth.2fa.verify',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const AUTH_TWO_FACTOR_SETUP_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'shield-keyhole'],
  UI_LABEL: 'Two-Factor Setup',
  ROUTE_PATH: 'two-factor-setup',
  ROUTE_NAME: 'auth.2fa.setup',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};
