import { EnvProvider, Envs } from '@/utils/EnvProvider';
import { APP_WINDOW_KEY } from '@/config/app';
import VueGtag from 'vue-gtag';
import type { App } from 'vue';
import router from '@/router';

export default async (app: App) => {
  if (EnvProvider.env !== Envs.Cloud) return;

  const gTagId = window?.[APP_WINDOW_KEY]?.FB_MEASUREMENT_ID;
  if (!gTagId) return;

  app.use(
    VueGtag,
    {
      appName: 'QxControl',
      config: { id: gTagId },

      // DEV NOTE :: in the future provide a method for disabling
      enabled: true,

      // customResourceURL: "https://www.googletagmanager.com/gtag/js",
      // customPreconnectOrigin: "https://www.googletagmanager.com",

      /**
       * Set `bootstrap` to false as we do not want to download
       * the analytics code when the app is loading, doing so
       * may cause a delay in app interaction.
       *
       * Refer to App.vue for bootstrap.
       */
      bootstrap: false,

      /**
       * Page and Screen View tracking
       * and templated information.
       */
      pageTrackerScreenviewEnabled: true,
      pageTrackerTemplate(to) {
        return {
          page_title: to.name,
          page_path: to.fullPath
        };
      }

      /**
       * When the gtag script is successfully loaded.
       */
      // onReady() {
      //   console.info('### gtag - onReady');
      // },
      // onError(error: unknown) {
      //   console.info('### gtag - onError', { error });
      // },
    },
    router
  );

  return;
};
