import type { FirebaseOptions } from "firebase/app";

export { FirebaseOptions };

export type { User } from "firebase/auth";

export interface UserReturn {
  id: string;
  email: string;

  // firebase specific
  avatar?: string,
}

export interface FirebaseStrategyConfig extends FirebaseOptions {
  url: string;
  jwt: string | false;
}

export interface LdapStrategyConfig {
  url: string;
}

export interface MagicEmailStrategyConfig {
  url: string;
}

export interface CreateAuthClientConfig {
  firebase?: FirebaseStrategyConfig;
  ldap?: LdapStrategyConfig;
  magicEmail?: MagicEmailStrategyConfig;
}

export enum AuthProvider {
  Google = 'Google',
}

export enum AuthStrategies {
  TOTP = "totp",
  User = "user",
  LDAP = "ldap",
  Firebase = "firebase",
  MagicEmail = "magic-email",
}

export interface AccountData {
  provider?: AuthProvider;
  firstName?: string;
  lastName?: string;
  password?: string;
  email?: string;
}

export interface AuthStrategy {
  login(data: Required<Pick<AccountData, "provider" | "email">> & Pick<AccountData, "password">): Promise<boolean>;
  logout?(): Promise<void>;
  verifyEmail?(data: Required<Pick<AccountData, "email">>): Promise<boolean>;
  createAccount?(data: AccountData): Promise<boolean>;
  updateProfile?(data: Required<Pick<AccountData, "firstName" | "lastName">>): Promise<boolean>;
  forgotPassword?(data: Required<Pick<AccountData, "email">>): Promise<boolean>;
  sendEmailVerification?(data: Required<Pick<AccountData, "email">>): Promise<boolean>;
  generateToken?(): Promise<string | false>;
  // resetPassword?(data: Required<Pick<AccountData, "email">>): Promise<boolean>;
}

export enum AuthError {
  InvalidCredentials = 'InvalidCredentials',
  EmailNotVerified = 'EmailNotVerified',
  WeakPassword = 'WeakPassword',
  NotAuthorized = 'NotAuthorized',
  SignInWithProvider = 'SignInWithProvider',
  PersonNotFound = 'PersonNotFound',
  MissingPlace = 'MissingPlace',
  NetworkTimeout = 'NetworkTimeout',

  // Firebase...
  // InvalidPassword = 'InvalidPassword',
}
