<template>
  <DeviceIcon v-if="showIcon"
    :gateway-connected="!gatewayIsDisconnected"
    :connection="connection"
    :pacs="pacs"
    :pacs-hardware="pacsHardware"
  />
  <span v-else-if="showLabel">
    {{ label }}
  </span>
</template>

<script lang="ts">
import { hasDeviceProp } from '@/mixins/device'
import { getDeviceStatusIcon } from '@/constants/device'
import { qx_eDeviceKind_enum, qx_eDeviceConnectionStatus_enum } from '@/graphql/types';
import { smartIconValidator } from './helpers/smart-icon-validator'

export default {
  mixins: [hasDeviceProp],
  props: {
    type: {
      required: false,
      default: "icon",
      validator(value) {
        return smartIconValidator(value);
      }
    }
  },
  computed: {
    gatewayIsDisconnected() {
      return !(this.device?.gateway?.connectionStatus || false);
    },
    connection() {
      if (this.device?.connectionStatus == qx_eDeviceConnectionStatus_enum.Disconnected && this.hasAllDisabledChildren) {
        // TODO :: why do we do this? Just use the disabled prop that already exists
        return 'disabled';
      }
      return this.device?.connectionStatus || undefined;
    },
    hasAllDisabledChildren() {
      const hasChildren = this.device?.doors?.length > 0 || this.device?.videoSources?.length > 0;
      const allDoorsDisabled = this.device?.doors?.every(door => {
        return door?.enabled == false;
      });
      const allCamerasDisabled = this.device?.videoSources?.every(camera => {
        return camera?.enabled == false;
      });
      return hasChildren && allDoorsDisabled && allCamerasDisabled;
    },
    isAccess() {
      return this.device?.__typename === 'qx_Door' || this.device?.kind === qx_eDeviceKind_enum.Access;
    },
    pacs() {
      return this.isAccess ? this.device?.pacsStatus : undefined;
    },
    pacsHardware() {
      return this.isAccess ? this.device?.pacsHWUpdate : undefined;
    },
    label() {
      const { gatewayIsDisconnected, connection, pacs, pacsHardware } = this;
      const status = getDeviceStatusIcon(!gatewayIsDisconnected, connection, pacs, pacsHardware);
      return status?.tooltip || '';
    },
    showIcon() {
      return this.type === "icon";
    },
    showLabel() {
      return this.type === "label";
    }
  }
}
</script>
