import ClipsPublicRoutes from '@/views/saved-clips/routes-public';
import { CONFIG_ROUTES } from '@/config/routes';

export default [
  {
    path: '/',
    meta: {
      public: true
    },
    components: {
      default: () => import(/* webpackChunkName: "public-layout" */ '@/views/layouts/public/PublicLayout.vue')
    },
    children: [
      ...ClipsPublicRoutes,

      // This route is considered "App Home"
      // If there are no URL params, redirect to home.
      {
        path: '',
        name: CONFIG_ROUTES.APP_HOME,
        redirect: { name: CONFIG_ROUTES.APP_HOME_REDIRECT }
      },
      // TODO :: redirect to not-found page within public layout
      // {
      //    // TODO :: remove this beforeEnter, doesnt work with `redirect` defined
      //   beforeEnter(to, from, next) {
      //     console.info('router.public-routes.wildcard - redirect to app home')
      //     next();
      //   },
      //   path: '/:pathMatch(.*)*',
      //   redirect: { name: CONFIG_ROUTES.APP_HOME }
      // }
    ]
  },
]
