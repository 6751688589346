// check if arg is a boolean
export const isBoolean = (bool: any) => typeof bool === 'boolean';

/**
 * Handles boolean values that are returned as a string.
 */
export const parseBoolean = (string: string): boolean | string => {
	const val = string.toLowerCase().trim()
	let bool: string | boolean = string

	switch(val) {
		case "true":
			bool = true
			break
		case "false":
			bool = false
			break
	}
	return bool
}
