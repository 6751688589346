import { ROOT_PLACE_ID } from '@/config/app';
import { isObject } from '@/functions/object';
import { isString } from '@/functions/string';

function isNotPlaceID(value: string | { id: string } , placeID: string) {
  if (placeID === '') {
    return true;

  } else if (isObject(value)) {
    return value?.id !== placeID;

  } else if (isString(value)) {
    return value !== placeID;

  } else {
    return true;
  }
}

export function isNotPlace(value: string, placeID: string) {
  // throw 'Please select a different place';
  return isNotPlaceID(value, placeID)
}

export function isNotRootPlace(value: string) {
  // throw 'Root place selection is not allowed'
  return isNotPlaceID(value, ROOT_PLACE_ID);
}
