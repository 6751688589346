import type { App } from 'vue';

// DEV NOTE :: css import in `sass/plugins` folder
// https://github.com/Akryum/floating-vue
import FloatingVue from 'floating-vue'

export default async (app: App) => {
  // options... https://floating-vue.starpad.dev/guide/config.html#default-values
  app.use(FloatingVue, {
    themes: {
      dropdown: {
        placement: 'bottom-start',
      }
    }
  });
  return true;
}
