export const GROUP_LIST_KEY = 'group-list-key';
export const GROUP_DEFAULT_ICON = 'users';

// default form fields
export const GROUP_FORM_OBJECT = Object.freeze({
  name: '',
  thumbnail: '',
  placeID: undefined,
  place: undefined
});
export type GROUP_FORM_OBJECT_TYPE = typeof GROUP_FORM_OBJECT;

/**
 * UI Messages
 */
export const GROUP_INVALID_FORM = 'Please fill in all required fields.';
export const GROUP_CREATE_SUCCESS = 'Successfully created group.';
export const GROUP_UPDATE_SUCCESS = 'Successfully updated group.';
export const GROUP_UPDATE_ERROR = 'An error occurred when trying to update the group.';
export const GROUP_CONFIRM_DELETE = 'Are you sure you want to delete this group?';
export const GROUP_DELETED_ERROR = 'An error occurred when trying to delete the group.';
export const GROUP_DELETED_SUCCESS_FN = (groupName: string = '') =>
  `Group ${groupName ? `(${groupName}) ` : ''}successfully deleted.`;
