<template>
  <FaIcon :icon="['fas', icon]" />
</template>

<script lang="ts">
import { PLACE_DEFAULT_ICON } from '@/constants/place';

export default {
  data: () => ({ icon: PLACE_DEFAULT_ICON })
};
</script>
