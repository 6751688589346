/**
 * TODO :: build breakpoints pipeline
 * https://app.shortcut.com/qumulex/story/28371/build-breakpoints-pipeline
 *
 * This file needs to be built during the pipeline
 * phase and split into this config file plus the
 * CSS Variables file.
 */

export enum BreakpointName {
  Phone = 'phone',
  PhoneLandscape = 'phoneLandscape',
  Tablet = 'tablet',
  TabletLandscape = 'tabletLandscape',
  Desktop = 'desktop',
  DesktopHd = 'desktopHd',
  Nav = 'nav',
}

export const Breakpoints: Record<BreakpointName, number> = {
  phone: 320,
  phoneLandscape: 540,
  tablet: 750,
  tabletLandscape: 1023,
  desktop: 1100,
  nav: 1264, // when the navigation is hidden
  desktopHd: 1400,
}
