export const VIEW_DEFAULT_ICON = 'eye';

export const VIEW_LIST_KEY = 'view-list-key';

// default form fields
export const VIEW_FORM_OBJECT = Object.freeze({
  name: '',
  placeID: undefined,
  isDefault: false
});

export type VIEW_FORM_OBJECT_TYPE = typeof VIEW_FORM_OBJECT;

/**
 * UI Messages
 */
export const VIEW_NOT_SELECTED = 'No View Selected';
export const VIEW_INVALID_FORM = 'Please fill in all required fields.';
export const VIEW_CREATE_SUCCESS = 'Successfully created view.';
export const VIEW_CREATE_ERROR = 'An error occurred when trying to create the view.';
export const VIEW_CREATE_ERROR_NAME_EXISTS_FN = (name: string) =>
  `A view with the name "${name}" already exists. Please use a different name.`;
export const VIEW_UPDATE_SUCCESS = 'Successfully updated view.';
export const VIEW_UPDATE_ERROR = 'An error occurred when trying to update the view.';
export const VIEW_UPDATE_NAME_MISSING = 'A name is required to update a view.';
export const VIEW_CONFIRM_DELETE = 'Are you sure you want to delete this view?';
export const VIEW_DELETED_ERROR = 'An error occurred when trying to delete the view.';
export const VIEW_DELETED_SUCCESS_FN = (viewName: string = '') =>
  `View ${viewName ? `(${viewName}) ` : ''}successfully deleted.`;

/**
 * Card / List View Language
 */
export const VIEW_IS_CURRENT_LABEL = 'Current';
export const VIEW_IS_CURRENT_TOOLTIP = 'Default view for for your current scope.';
export const VIEW_IS_DEFAULT_TOOLTIP_FN = (placeName: string) => `Default view for ${placeName}.`;

/**
 * Actions
 */
export const VIEW_SET_AS_DEFAULT_FN = (placeName: string) => `Set as Default for ${placeName}`;
export const VIEW_REMOVE_AS_DEFAULT_FN = (placeName: string) => `Remove as Default for ${placeName}`;
export const VIEW_DEFAULT_ROOT_SCOPE = 'You cannot set a default view at the root scope.';
