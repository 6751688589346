import { hasPrivilege, hasPrivileges, hasRequiredPrivileges } from "@/utils/Privileges";
import type { AuthUser, AuthUserBaseFields, SetAuthUser } from "./../types";
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { computed, reactive, ComputedRef } from 'vue';
import { qx_ePrivilege_enum } from "@/graphql/types";
import { ApolloClient } from '@/utils/ApolloClient';
import { ROOT_USER_DOMAIN } from '@/config/app';
import { gql } from '@apollo/client/core';

/**
 * The following keys will always
 * be available on the User.
 */
const defaultAuthUser: AuthUserBaseFields = {
  id: '',
  avatar: '',
  suffix: '',
  salutation: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  placeID: '',
  privileges: [],
  // for Offline Login in Gateway Mode
  hasOfflinePassword: false,
  // middleware-esque values
  acceptedTerms: false,
  // account has 2fa enabled
  twoFactorEnabled: false,
  // 2fa delivery method; conditional UI rendering
  twoFactorMethods: [],
  // account has passed 2fa verification
  twoFactorVerified: false,
  // comes from /me, informs the client if the User must have 2fa enabled
  twoFactorRequired: false,
};

const ApplicationUserFragment = gql`
  fragment ApplicationUserFragment on qx_ApplicationUser {
    uid
    personID
    hasOfflinePassword
    termsAgreement {
      id
      updatedAt
      validUntil
    }
    person {
      id
      firstName
      lastName
      suffix
      salutation
      avatar_md
      placeID
    }
    role {
      id
      place {
        id
        type
      }
      name
      toPrivileges {
        privilege
      }
    }
  }
`;

const GQL_GET_APPLICATION_USER = gql`
  query GQL_GET_APPLICATION_USER($email: String!) {
    applicationUsers(where: { person: { email: { _eq: $email } } }) {
      ...ApplicationUserFragment
    }
  }
  ${ApplicationUserFragment}
`;

const GQL_SUB_APPLICATION_USER = gql`
  subscription GQL_SUB_APPLICATION_USER($email: String!) {
    applicationUsers(where: { person: { email: { _eq: $email } } }) {
      ...ApplicationUserFragment
    }
  }
  ${ApplicationUserFragment}
`;

export interface useUserReturn {
  isReady: ComputedRef<boolean>;
  data: ComputedRef<AuthUser>;
  retry: () => void;
}

/**
 * Returns a computed property containing the current user
 * (Auth, ApplicationUser, and Person records combined).
 */
export function useUser(): useUserReturn {

  /**
   * Determine if we have user data or not. It
   * is possible for _user to be populated
   * even though the user has logged out
   */
  const _user = reactive<AuthUserBaseFields>({...defaultAuthUser})

  // determines what ApplicationUser is returned
  const email = computed(() => _user.email);

  /**
   * maintain an up-to-date version of the Application User
   */
  const variables = computed(() => ({
    email: email.value
  }));

  /**
   * email is required to get the ApplicationUser,
   * also check for 2 Factor Authentication
   */
  const isEnabled = computed(() => {
    const hasEmail = !!variables.value.email;
    const has2Fa = !!_user.twoFactorEnabled;
    const isVerified = !!_user.twoFactorVerified;
    return hasEmail && (has2Fa ? isVerified : true);
  })

  /**
   * The Auth Store is used in our router middleware. Because we use this
   * store in our middleware, it is considered to be "outside of setup"
   * and thus any calls to the ApolloClient from composables will
   * fail. We wrap the useQuery call in provideApolloClient to
   * ensure it always has access.
   */
  const { onResult, subscribeToMore, refetch } = provideApolloClient(ApolloClient)(() =>
    useQuery(
      GQL_GET_APPLICATION_USER,
      variables,
      computed(() => ({
        enabled: !!isEnabled.value,
      })),
    )
  );

  subscribeToMore(() => ({
    document: GQL_SUB_APPLICATION_USER,
    variables: variables.value,
  }));

  /**
   * Combined values from Authentication,
   * ApplicationUser, and Person records
   */
  const set: SetAuthUser = async function(data) {
    // reset to defaults, if null
    if (data === null) {
      for (const key of Object.keys(_user)) {
        _user[key] = defaultAuthUser[key as keyof AuthUser];
      }
    // update user with key:vals provided
    } else {
      for (const key of Object.keys(data)) {
        _user[key] = data[key as keyof AuthUser]
      }
    }

    return;
  }

  onResult(result => {
    if (result.loading) return;

    const appUser = result?.data?.applicationUsers?.[0];
    if (!appUser) return;

    Object.assign(_user, {
      privileges: appUser?.role?.toPrivileges?.map?.(p => p.privilege) || [],
      hasOfflinePassword: appUser.hasOfflinePassword,
      role: appUser?.role?.id || '',
      acceptedTerms: !!appUser?.termsAgreement?.id,
      id: appUser?.person?.id,
      firstName: appUser?.person?.firstName,
      lastName: appUser?.person?.lastName,
      suffix: appUser?.person?.suffix,
      salutation: appUser?.person?.salutation,
      avatar: appUser?.person?.avatar_md,
      placeID: appUser?.person?.placeID,
    });
  });

  /**
   * Return the _user object and add in
   * additional values or custom
   * functions to be accessed.
   */
  const data = computed((): AuthUser => {
    return {
      ..._user,
      hasPrivilege: (priv: qx_ePrivilege_enum) => hasPrivilege(priv, _user.privileges),
      hasPrivileges: (privs: qx_ePrivilege_enum[]) => hasPrivileges(privs, _user.privileges),
      hasRequiredPrivileges: (privs: qx_ePrivilege_enum[]) => hasRequiredPrivileges(privs, _user.privileges),
      set,
    }
  });

  function retry() {
    refetch();
    subscribeToMore(() => ({
      document: GQL_SUB_APPLICATION_USER,
      variables: variables.value,
    }));
  }

  return {
    retry,
    isReady: computed(() => {
      const twoFaReady = _user.twoFactorEnabled ? _user.twoFactorVerified : true;
      const hasRootScope = !!data.value.placeID;
      return hasRootScope && twoFaReady;
    }),
    data
  }
}
