import { LIVE_ROUTE } from '@/config/routes';

export default [
  {
    path: LIVE_ROUTE.ROUTE_PATH,
    components: {
      default: () => import('@/views/live/LiveContainer.vue')
    },
    meta: {
      fillHeight: true,
      privileges: LIVE_ROUTE.PRIVILEGES
    },
    children: [
      {
        path: '',
        name: LIVE_ROUTE.ROUTE_NAME,
        components: {
          default: () => import('@/views/live/LivePage.vue')
        }
      }
    ]
  }
];
