import type { Component } from "vue";

export type DialogComponent = Component<{ open: () => Promise<unknown> }>;

export enum DialogOption {
  create = "create",   // neutral
  info = "info",     // grey/background
  confirm = "confirm",  // blue
  warning = "warning",  // yellow/orange
  danger = "danger",   // red
  success = "success",  // green
};

export interface DefinedDialogOptions extends Omit<DialogOptions, 'color'> {};

type DialogAction<T = unknown> = (
  message: string,
  options?: Partial<DefinedDialogOptions>
) => Promise<T>;

export type DialogActionMapType = {
  [key in DialogOption]: DialogAction;
};

export type DialogOptionsMapType = {
  [key in DialogOption]: DialogOptions;
};

interface DialogOptionActionOptions {
  text?: string;
  color?: string;
  value?: unknown;
  disabled?: boolean;
}

export type DialogOptionActionKey = 'ok' | 'cancel' | 'secondary'

type DialogOptionActionMapType = {
  [key in DialogOptionActionKey]?: DialogOptionActionOptions;
};

export interface DialogOptions {
  title?: string;
  color: string;
  actions: DialogOptionActionMapType;

  // props given to the dialog component
  props?: Record<string, unknown>;

  // rendered below the message, clicking ok will emit the component value, component must use v-model behavior
  component?: Component;
  componentOpts?: {
    [key: string]: unknown;
    props?: Record<string, unknown>;
    /**
     * If true, when the User clicks the "ok" button, the value emitted by
     * the component's v-model will be used. If the component never
     * emitted a value, the `componentOpts.props.modelValue` will be used.
     */
    deriveValue?: boolean;
  };
}

export interface DialogConfig {
  id: string;
  name: string;
  message: string;
  promise: undefined | Promise<unknown>;
  isResolving: boolean;
  component: DialogComponent;
  options: DialogOptions;
  resolve: (value: string) => void;
  reject: (value: string) => void;
}

