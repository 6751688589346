/**
 * ::::::::::::::::::::::::::::::::::::::::::::::
 * ::: Developer Notes ::::::::::::::::::::::::::
 * ::::::::::::::::::::::::::::::::::::::::::::::
 * Do not reference this file directly.
 *
 * This file is referenced within the
 * `createUiStore` function.
 *
 * This file is intended to abstract state and methods
 * relating to `AppNavigation.vue`.
 *
 * The state and methods abstracted may need to be referenced
 * by other UI components in the app.
 *
 * `AppNavigation.vue` is considered a global component, there
 * may be other global components that need access to this
 * information to inform their sizing.
 */
import { Ref, ref, reactive } from 'vue';
import { useVisibilityToggle, UseVisibilityToggle } from '@/composables/use-visibility-toggle'

export type AppElement = UseVisibilityToggle & {
  element: Ref<HTMLElement | null>;
  // style: Ref<Record<string, unknown>>;
}

export const useAppElement = (): AppElement => {

  const { isVisible, toggleVisibility } = useVisibilityToggle(true)

  const element = ref(null);
  // const style = reactive<Record<string, unknown>>({})

  return {
    element,
    // style,
    isVisible,
    toggleVisibility
  };
};
