import { SEARCH_ROUTE } from '@/config/routes';

export default [
  {
    path: SEARCH_ROUTE.ROUTE_PATH,
    name: SEARCH_ROUTE.ROUTE_NAME,
    components: {
      default: () => import(/* webpackChunkName: "clip-search-page" */ '@/views/clip-search/ClipSearchPage.vue')
    },
    meta: {
      fillHeight: true,
      privileges: SEARCH_ROUTE.PRIVILEGES
    }
  }
];
