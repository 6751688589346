import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const SAVED_CLIPS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'clips'],
  UI_LABEL: 'Clips',
  ROUTE_PATH: 'saved-clips',
  ROUTE_NAME: 'saved-clips.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.ClipManagement]
};

export const SAVED_CLIPS_PUBLIC_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'clips'],
  UI_LABEL: 'View Export',
  ROUTE_PATH: 'export',
  ROUTE_NAME: 'export-clip.index',
  PRIVILEGES: [], // no privs because this is a public route
  ROUTE_PARAM: 'exportID'
};

export const SAVED_CLIP_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Clip',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'clip.edit',
  ROUTE_PARAM: 'clipId',
  PRIVILEGES: [qx_ePrivilege_enum.ClipManagement]
};

// name used between two route names
const SAVED_CLIP_VIEW_ROUTE_NAME_SHARED = 'clip.view';

// this route does not register it's ROUTE_NAME
// that is because it would duplicate the info route
export const SAVED_CLIP_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'eye'],
  UI_LABEL: 'View Clip',
  ROUTE_PATH: ':clipId',
  ROUTE_NAME: SAVED_CLIP_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'clipId',
  PRIVILEGES: [qx_ePrivilege_enum.ClipManagement]
};

export const SAVED_CLIP_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: SAVED_CLIP_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'clipId',
  PRIVILEGES: [qx_ePrivilege_enum.ClipManagement]
};

export const SAVED_CLIP_AUDIT_LOG_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'clipboard'],
  UI_LABEL: 'Audit Logs',
  ROUTE_PATH: 'audit',
  ROUTE_NAME: 'clip.audit',
  ROUTE_PARAM: 'clipId',
  PRIVILEGES: [qx_ePrivilege_enum.Audit]
};
