import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const PERMISSIONS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'key'],
  UI_LABEL: 'Permissions',
  ROUTE_PATH: 'permissions',
  ROUTE_NAME: 'permissions.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleView]
};

export const PERMISSION_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'key'],
  UI_LABEL: 'Create Permission',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'permission.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleConfig]
};

export const PERMISSION_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Permission',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'permission.edit',
  ROUTE_PARAM: 'permissionId',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleConfig]
};

// name used between two route names
const PERMISSION_VIEW_ROUTE_NAME_SHARED = 'permission.view';

// this route does not register it's ROUTE_NAME
// that is because it would duplicate the info route
export const PERMISSION_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'key'],
  UI_LABEL: 'View Permission',
  ROUTE_PATH: ':permissionId',
  ROUTE_NAME: PERMISSION_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'permissionId',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleView]
};

export const PERMISSION_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: PERMISSION_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'permissionId',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleView]
};
