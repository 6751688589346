import IsRootUserMiddleware from '@/router/middleware/is-root-user';

export default [
  {
    path: 'dx',
    name: 'dx',
    components: {
      default: () => import(/* webpackChunkName: "container-developer" */ '@/views/_dev/DevContainer.vue')
    },
    meta: {
      label: 'Developer Experience',
      middleware: [IsRootUserMiddleware],
      privileges: []
    },
    children: [
      {
        path: '',
        name: 'dx.jwt',
        components: {
          default: () => import('@/views/_dev/GenerateToken.vue')
        },
        meta: {
          privileges: []
        }
      },
      {
        path: 'env',
        name: 'dx.env',
        components: {
          default: () => import('@/views/_dev/Environment.vue')
        },
        meta: {
          privileges: []
        }
      },
      {
        path: 'icons',
        name: 'dx.icons',
        components: {
          default: () => import('@/icons/IconTest.vue')
        },
        meta: {
          privileges: []
        }
      },
      {
        path: 'colors',
        name: 'dx.colors',
        meta: {
          privileges: [],
        },
        components: {
          default: () => import('@/views/_dev/Colors.vue')
        }
      },
      {
        path: 'bandwidth',
        name: 'dx.bandwidth',
        meta: {
          privileges: [],
        },
        components: {
          default: () => import('@/views/_dev/BandwidthCharts.vue')
        }
      },
      {
        path: 'virt-scroller',
        name: 'dx.virt-scroller',
        meta: {
          privileges: [],
        },
        components: {
          default: () => import('@/views/_dev/VirtScroller.vue')
        }
      },
      {
        path: 'random-query',
        name: 'dx.random-query',
        meta: {
          privileges: [],
        },
        components: {
          default: () => import('@/views/_dev/RandomQuery.vue')
        }
      },
      {
        path: ':pathMatch(.*)*',
        redirect: { name: 'dx.jwt' }
      }
    ]
  }
];
