import { useAuthStore } from '@/store/auth';
import { CONFIG_ROUTES } from '@/config/routes';
import { AUTH_LOGOUT_ROUTE, AUTH_TWO_FACTOR_ROUTE } from '@/config/routes/auth';

const UserHasTwoFactor: Middleware = async function (ctx) {
  const auth = useAuthStore();
  const user = !auth.isReady ? await auth.account.authenticate() : auth.user;

  if (!user) {
    return { name: AUTH_LOGOUT_ROUTE.ROUTE_NAME, query: { redirect: ctx.to.fullPath } };
  }

  const isEnabled = user.twoFactorEnabled;
  const isVerified = user.twoFactorVerified;
  const is2FaPage = ctx?.to?.name === AUTH_TWO_FACTOR_ROUTE.ROUTE_NAME;

  if (!isEnabled || (isEnabled && isVerified && !is2FaPage)) {
    return;
  }

  // if user is not verified and they are not going to the 2fa page
  if (!isVerified && !is2FaPage) {
    // redirect them to the 2fa page
    return { name: AUTH_TWO_FACTOR_ROUTE.ROUTE_NAME, query: { redirect: ctx.to.fullPath } };
  }

  // if user has 2fa enabled, is not
  if (isVerified && is2FaPage) {
    return { name: CONFIG_ROUTES.APP_HOME, query: { redirect: ctx.to.fullPath } };
  }
}

export default UserHasTwoFactor;
