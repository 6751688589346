import { RunMigrationsReturn, Migration, MigrationImport } from "@/types";

export default function Migrations<M, V extends { schemaVersion: number; }>(
  // glob import
  modules: Record<string, () => Promise<MigrationImport<M, V>>>
): {
  version: number,
  migrate: (meta: M, value?: V) => RunMigrationsReturn<V | false>
} {

  const migrations: Migration<M, V>[] = [];

  for (const path in modules) {
    const version = Number(path.match(/\d+/)?.[0] || '0')
    if (version === 0) continue;

    migrations.push({
      schemaVersion: version,
      getMigration: modules[path]
    });
  }

  const latestVersion = migrations[migrations.length - 1].schemaVersion;

  async function migrate(meta: M, value?: V) {

    try {
      // if no value, use latest migration to return a default value

      if (!value) {
        const migrate = await migrations[latestVersion - 1].getMigration();
        return await migrate.default(meta)
      }

      // if value exists but there is no schemaVersion, start with 1st migration
      else if (value && !value?.schemaVersion) {
        for (let i = (migrations.length - 1); i < latestVersion; i++) {
          const migrate = await migrations[i].getMigration();
          return await migrate.default(meta, value)
        }
        return
      }

      // if value is older than latest migration, run migrations
      else if (value.schemaVersion < latestVersion) {
        for (let i = value.schemaVersion; i < latestVersion; i++) {
          const migrate = await migrations[i].getMigration();
          return await migrate.default(meta, value)
        }

      // if value is from the future, be concerned...
      }

      else if (value.schemaVersion > latestVersion) {
        console.warn('User must be from the future. Their schema version is newer than the current migration version.', {
          currentVersion: value.schemaVersion,
          latestVersion: latestVersion
        })
      }

      // assume User has latest, do nothing
      return false;

    } catch (error) {
      throw error
    }
  }

  return {
    version: latestVersion,
    migrate
  }
}
