<template>
  <FaIcon :icon="['fas', faIcon]" />
</template>

<script lang="ts">
import { getTimeZone } from '@/utils/DateTime';

const timeZoneIcons = {
  europe: 'earth-europe',
  asia: 'earth-asia',
  america: 'earth-americas',
  africa: 'earth-africa',
  australia: 'earth-asia',
  pacific: 'earth-africa',
  atlantic: 'earth-americas',
}

export default {
  props: {
    timezone: {
      type: String,
      default: '',
    },
  },
  data: () => {
    const currentTimeZone = getTimeZone().split('/')[0]

    return {
      currentTimeZone
    }
  },
  computed: {
    faIcon() {
      const { currentTimeZone, timezone } = this

      const globeIcon = timezone ? getGlobeKey(timezone) : getGlobeKey(currentTimeZone)

      return timeZoneIcons?.[globeIcon] || timeZoneIcons.america

      function getGlobeKey(string) {
        const stringToLowerCase = string.toLowerCase()
        return Object.keys(timeZoneIcons).find(key => stringToLowerCase?.includes(key))
      }
    }
  }
}
</script>
