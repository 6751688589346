import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const TIMELINE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'qx-timeline'],
  UI_LABEL: 'Timeline',
  ROUTE_PATH: 'timeline',
  ROUTE_NAME: 'timeline.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.RecordedVideo]
};
