import { ApolloClient } from "@/utils/ApolloClient";
import { gql } from "@apollo/client/core";
import { UserSettingValue } from "./types";

const GQL_UPDATE_USER_SETTING = gql`
  mutation GQL_UPDATE_USER_SETTING(
    $id: String!,
    $value: jsonb!
  ) {
    updateUserSetting(
      _set: {
        value: $value
      },
      pk_columns: { id: $id }
    ) {
      id
      name
      value
    }
  }
`
// only to be called within `useUserSettings`
export function updateSetting(id: string) {

  return async (value: UserSettingValue) => {
    try {
      const response = await ApolloClient.mutate({
        mutation: GQL_UPDATE_USER_SETTING,
        variables: {
          id,
          value
        }
      })

      return true;

    } catch (error) {
      throw error
    }
  }
}
