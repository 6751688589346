import { FlowActions, RuleConditionObject } from '@/models/rule/types';
import { Flow } from 'qx-flow-orm';

export const RULE_LIST_KEY = 'rule-list-key';
export const RULE_TRACE_ICON = 'rule-trace';

// used in RuleForm.vue when using `provide` for createNestedFormToggleStore()
export const RULE_FORM_NESTED_FORM_STORE_KEY = 'rule-form-store-key';

export const RULE_ACTION_DEFAULT_RATE_LIMIT_IN_SECONDS = 60;

export type RuleForm = {
  id?: string;
  enabled: boolean;
  name: string;
  description?: string;
  flow: Flow;
  scheduleID: string;
  schedule?: null | Record<string, unknown>;
  placeID: string;
  place?: null | Record<string, unknown>;
};

// @ts-ignore - it doesn't like flow.trigger.conditions having an empty `_and` value
export const RULE_FORM_OBJECT: RuleForm = {
  enabled: true,
  name: '',
  description: '',
  placeID: '',
  place: null, // only used in the form, not sent in API call
  schedule: null, // only used in the form, not sent in API call
  scheduleID: '',
  flow: {
    name: '', // unsure about this...
    triggers: [     // 1
      {
        type: 'flow_event',
        // @ts-ignore
        conditions: [{ _and: [] }]
      }
    ],
    preGuards: [],    // 2
    conditions: [],   // 3
    postGuards: [],   // 4
    actions: []       // 5
  }
};

export const RULE_DEFAULT_ICON = 'code-branch';
export const RULE_ICON_TRIGGER = 'circle-dot';
export const RULE_ICON_PRE_GUARD = 'shield-halved';
export const RULE_ICON_CONDITION = 'code-branch';
export const RULE_ICON_POST_GUARD = 'shield-halved';
export const RULE_ICON_ACTION = 'play';

export const RULE_FORM_ENABLED_OPTIONS = [
  {
    label: 'Enabled',
    value: true
  },
  {
    label: 'Disabled',
    value: false
  }
];

export const RULE_ACTION_SMS_BODY_CHARACTER_LIMIT = 120;
export const RULE_ACTION_SMS_BODY_CHARACTER_MINIMUM = 2;

export const RULE_CONDITION_FORM_OBJECT: RuleConditionObject = {
  model: '',
  modelID: '',
  field: '',
  operator: '',
  type: '',
  operators: [],
  options: [],
  value: null
};

/**
 * Rule Tooltip Helpers
 */
export const RULE_TOOLTIP_ACTION_RATE_LIMIT =
  'Will prevent the action from occurring more than once during the duration specified. If no duration is specified, the value is defaulted to 60 seconds.';
export const RULE_TOOLTIP_GUARD_OCCURENCE = 'The Rule trigger must occur X times before executing Actions.';
export const RULE_TOOLTIP_GUARD_DELAY =
  'Actions will be delayed for the duration specified, and will cancel any pending actions if conditions are met.';

export const RULE_CONFIRM_CLEAR_CACHE_FN = (ruleName: string) =>
  `Are you sure you want to reset the rule${ruleName ? ` "${ruleName}"` : ''}?`;
export const RULE_CLEAR_CACHE_SUCCESS_FN = (ruleName: string) =>
  `Successfully reset the rule${ruleName ? ` "${ruleName}"` : ''}.`;
export const RULE_CLEAR_CACHE_FAILURE_FN = (ruleName: string) =>
  `An error occurred when trying to reset the rule${ruleName ? ` "${ruleName}"` : ''}.`;

export const RULE_DELETED_SUCCESS_FN = (ruleName: string = '') =>
  `Rule ${ruleName ? `"${ruleName}" ` : ''}successfully deleted.`;

export const RULE_EXTERNAL_HELP_LINK = 'https://www.qumulex.com/kb/qumulex-rule-examples';
export const RULE_FORM_PLACE_TIMEZONE_TIP = 'A timezone must be defined on the selected Place for the Rule to operate correctly.';

/**
 * Rule Form Validation Messages
 */
export const RULE_FORM_SUCCESS = {
  RULE_CREATE_SUCCESS: 'Successfully created rule.',
  RULE_UPDATE_SUCCESS: 'Successfully updated rule.',
  RULE_CONFIRM_DELETE: 'Are you sure you want to delete this rule?'
};

export const RULE_FORM_ERRORS = {
  /**
   * Generic RuleForm Errors
   */
  RULE_INVALID_FORM: 'Please fill in all required fields.',
  RULE_UPDATE_ERROR: 'An error occurred when trying to update the rule.',
  RULE_DELETED_ERROR: 'An error occurred when trying to delete the rule.',

  /**
   * Operators
   */
  OPERATOR_SELECTION_INVALID: 'Operator selection is invalid',

  /**
   * Conditions
   */
  RULE_CONDITION_DISPLAY_RELATION_VALUE_IS_NULL_TITLE: 'The selected value is not available',
  RULE_CONDITION_DISPLAY_RELATION_VALUE_IS_NULL_DESC:
    'There was either an issue attempting to retrieve the value, you no longer have access to it, or it no longer exists.',
  RULE_CONDITION_FORM_VALUE_NOT_SUPPORTED:
    'Condition value not supported. Please remove the condition and try building it again.',

  /**
   * Triggers
   */
  RULE_INVALID_TRIGGER_MISSING: 'At least 1 valid trigger is required.',

  /**
   * Actions
   */
  RULE_ACTION_AT_LEAST_ONE_ACTION_REQUIRED: 'At least 1 Action is required for a Rule.',
  RULE_ACTION_ONE_OR_MORE_INVALID: 'At least 1 or more Actions are invalid.',

  RULE_ACTION_EMAIL_AT_LEAST_ONE_EMAIL_REQUIRED: 'At least 1 email address is required for an Email Notification.',
  RULE_ACTION_EMAIL_SUBJECT_REQUIRED: 'Subject is required for an Email Notification.',
  RULE_ACTION_EMAIL_BODY_REQUIRED: 'Body is required for an Email Notification.',

  // TODO :: this wont work... I need the following two for showing a message below the textarea, but at the same time I need it to be descriptive for a toast notification
  RULE_ACTION_SMS_BODY_EXCEEDED_CHARACTER_LIMIT: `No more than ${RULE_ACTION_SMS_BODY_CHARACTER_LIMIT} characters`,
  RULE_ACTION_SMS_BODY_BELOW_CHARACTER_MINIMUM: `At least ${RULE_ACTION_SMS_BODY_CHARACTER_MINIMUM} characters required`,
  RULE_ACTION_SMS_AT_LEAST_ONE_PHONE_NUMBER_REQUIRED: 'At least 1 phone number is required for a SMS Notification.',

  RULE_ACTION_WEBHOOK_INVALID_URL: 'Please enter a valid URL for the Webhook.',
  RULE_ACTION_REVERT_DOOR_DOOR_ID_REQUIRED: 'Door is required for a Revert Door Action.',

  /**
   * Guards
   */
  RULE_GUARD_INVALID: 'One or more of your Guards are invalid.',
  RULE_GUARD_UNIQUE_GUARDS: 'You may not have duplicate guards.',
  RULE_GUARD_PREGUARD_INVALID: 'One or more of your Pre Guards are invalid.',
  RULE_GUARD_DELAY_MISSING_CONDITIONS: 'At least 1 condition is required for a Delay Guard.',

  /**
   * Data/Logic failure messages meant for a
   * developer, but masked for an end-user.
   */
  // 'The `conditionObject` was not provided'
  RULE_CONDITION_OBJECT_NOT_PROVIDED: 'Unable to determine the condition. Please delete the condition and try again.'
}

export const RULE_FLOW_SCHEMA = {
  firing: {
    title: 'Firing Data',
    description: `
      A Rule in the "Firing" state indicates it has been triggered
      and at least one source is still active. Before the rule
      can re-trigger and initiate actions, all active triggers
      must be resolved or the rule must be reset.
    `,
    clearCache: 'Reset Rule'
  },
  schedule: {
    title: 'Schedule',
    description: `
      The Rule can only be triggered during the provided Schedule.
      Setting the Rule to disabled will prevent the Rule from
      running, regardless of the selected Schedule.<br />
      <br />
      Associated Holiday Sets are not applied to Rules.
    `,
    disabled: `
      <strong>Your Rule is currently disabled.</strong> Please enable
      your Rule for the Schedule to take effect.
    `
  },
  triggers: {
    title: 'Triggers',
    description: `
      Triggers initiate Rule processing. Depending on the type of
      Trigger(s) added will affect when the Rule is processed.
    `,
  },
  preGuards: {
    title: 'Pre Guards',
    description: `
      Pre Guards...
    `,
  },
  conditions: {
    title: 'Conditions',
    description: `
      Conditions...
    `,
  },
  postGuards: {
    title: 'Guards',
    description: `
      Guards may prevent execution of the Rule's Actions.
    `,
  },
  actions: {
    title: 'Actions',
    description: `
      Actions are executed once the Rule is triggered
      and any configured guards have been resolved.
    `,
  },
  guards: {
    delay: {
      title: (string: string) => `
        The Rule actions will be delayed from execution
        for <strong>${string} seconds</strong>
      `,
      description: `
        During a Rule's Delay Guard, its firing status will be indicated. If the Delay
        Guard conditions are met during the delay period, the firing status will be
        reset and the Rule Actions will not execute. If the Delay Guard conditions
        are not met, the Rule Actions will execute, the Rule status will continue
        firing, and the Rule Actions will not execute again until the trigger
        sources are reset or the Rule is manually reset.
      `
    }
  }
}
