import { DateTimeISO } from '@/types';
import { DateTime } from 'luxon';
// Settings.defaultLocale = DateTime.local().resolvedLocaleOpts().locale;

export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getTimeZoneShort = () => {
  return new Intl.DateTimeFormat(undefined, { timeZoneName: 'short' })
    .formatToParts(new Date())
    .find(part => part.type == "timeZoneName")
    .value
};

export const fromIsoToLocal = (dtISO: string, weekday?: boolean) => {
  if (!dtISO) return '';
  const date = DateTime.fromISO(dtISO);
  return date.toLocaleString(weekday ? DateTime.DATETIME_MED_WITH_WEEKDAY : DateTime.DATETIME_MED_WITH_SECONDS);
};

/**
 * UI needs to take an ISO String (utc) and convert it to local
 * time, but it needs to maintain the same ISO String format.
 * https://stackoverflow.com/a/51643788
 */
export const getLocalStringInISOFormatFromISOFormat = (dtISO: string): string => {
  const t = new Date(dtISO)

  const z = t.getTimezoneOffset() * 60 * 1000;

  let tLocal = t - z
  tLocal = new Date(tLocal)

  let iso = tLocal.toISOString()
  iso = iso.slice(0, 19)

  return iso
}

export const getDateTimeLocalFromISO = (dtISO: string): string => {
  const dt = DateTime.fromISO(dtISO);
  const date = dt.toFormat('yyyy-MM-dd');
  const time = dt.toFormat('HH:mm:ss');
  const string = `${date}T${time}`;
  return string;
};

/**
 *
 */
export const getNowToISO = (): DateTimeISO => {
  const date = new Date().toISOString()
  const dateParts = date.split(':')
  dateParts.pop()
  const newDate = dateParts.join(':') + ':00.000Z'
  return newDate
};

export const dateTimeIsPast = (dtISO: string) => {
  const dt = DateTime.fromISO(dtISO);
  const now = (DateTime as any).now();
  return dt < now;
};

type DateTimeRange = {
  start: DateTimeISO;
  end: DateTimeISO;
};

export type DateTimeRangeOption = {
  label: string;
  value: string;
  get: () => DateTimeRange;
};

export const dateTimeRangeOptions: DateTimeRangeOption[] = [
  {
    label: 'Today',
    value: 'today',
    get: () => {
      const dt = DateTime.now();
      const start = dt.startOf('day').toISO();
      const end = dt.endOf('day').toISO();

      return { start, end };
    }
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    get: () => {
      const dt = DateTime.now().minus({ days: 1 });
      const start = dt.startOf('day').toISO();
      const end = dt.endOf('day').toISO();

      return { start, end };
    }
  },
  {
    label: 'Past 7 days',
    value: 'past7days',
    get: () => {
      const dt = DateTime.now();
      const start = dt.minus({ days: 7 }).startOf('day').toISO();
      const end = dt.endOf('day').toISO();

      return { start, end };
    }
  },
  {
    label: 'Past 30 days',
    value: 'past30days',
    get: () => {
      const dt = DateTime.now();
      const start = dt.minus({ days: 30 }).startOf('day').toISO();
      const end = dt.endOf('day').toISO();

      return { start, end };
    }
  },
  {
    label: 'Past 60 days',
    value: 'past60days',
    get: () => {
      const dt = DateTime.now();
      const start = dt.minus({ days: 60 }).startOf('day').toISO();
      const end = dt.endOf('day').toISO();

      return { start, end };
    }
  },
  {
    label: 'Past 90 days',
    value: 'past90days',
    get: () => {
      const dt = DateTime.now();
      const start = dt.minus({ days: 90 }).startOf('day').toISO();
      const end = dt.endOf('day').toISO();

      return { start, end };
    }
  },
  {
    label: 'Past 120 days',
    value: 'past120days',
    get: () => {
      const dt = DateTime.now();
      const start = dt.minus({ days: 120 }).startOf('day').toISO();
      const end = dt.endOf('day').toISO();

      return { start, end };
    }
  },
  {
    label: 'This month',
    value: 'thismonth',
    get: () => {
      const dt = DateTime.now();
      const start = dt.startOf('month').toISO();
      const end = dt.endOf('month').toISO();

      return { start, end };
    }
  },
  {
    label: 'Last month',
    value: 'lastmonth',
    get: () => {
      const dt = DateTime.now();
      const start = dt.minus({ months: 1 }).startOf('month').toISO();
      const end = dt.minus({ months: 1 }).endOf('month').toISO();

      return { start, end };
    }
  },
  {
    label: 'Past 6 months',
    value: 'past6months',
    get: () => {
      const dt = DateTime.now();
      const start = dt.minus({ months: 6 }).startOf('month').toISO();
      const end = dt.endOf('month').toISO();

      return { start, end };
    }
  },
  {
    label: 'This year',
    value: 'thisyear',
    get: () => {
      const dt = DateTime.now();
      const start = dt.startOf('year').toISO();
      const end = dt.endOf('year').toISO();

      return { start, end };
    }
  }
  // {
  //   label: 'Last year',
  //   value: 'lastyear',
  //   get: () => {
  //     const dt = DateTime.now().minus({ years: 1 });
  //     const start = dt.startOf('year').toISO();
  //     const end = dt.endOf('year').toISO();

  //     return { start, end };
  //   }
  // }
];

export const getExpirationDate = (days: number = 1) => {
  const now = new Date();
  now.setDate(now.getDate() + days);
  return now;
}
