import { AuthClient, AuthStrategies } from "@/utils/AuthClient";
import { computedAsync, useStorage } from "@vueuse/core";
import { ApolloClient } from "@/utils/ApolloClient";
import { EnvProvider } from "@/utils/EnvProvider";
import { MeResponse, AuthUser } from './../types';
import { gql } from "@apollo/client/core";
import { ComputedRef, ref } from 'vue';

const GQL_SIGNOUT = gql`
  mutation GQL_SIGNOUT {
    signout {
      success
    }
  }
`;

const GQL_SIGNOUT_SESSION = gql`
  mutation GQL_SIGNOUT_SESSION($sid: String!) {
    signout(sessionID: $sid) {
      success
    }
  }
`;

const GQL_SIGNOUT_EVERYWHERE = gql`
  mutation GQL_SIGNOUT_EVERYWHERE {
    signoutEverywhere {
      success
    }
  }
`;

export function useAuthentication(user: ComputedRef<AuthUser>) {
  // when calling `/me` we cache the value and use this to check if we need to make the `/me` call again
  const meResponseCached = useStorage<null | MeResponse>(
    'qx.auth.me',
    null,
    undefined,
    {
      serializer: {
        read: (v: any) => v ? JSON.parse(v) : null,
        write: (v: any) => JSON.stringify(v),
      },
    },
  );

  // we always want to force a `/me` call the first time we authenticate
  // afterward we can use the cached version if available
  const firstMeCall = ref(false);

  // keep track of `authenticate` attempts, no other logic is bound to this variable in this file
  const attempts = ref(0);

  // determines if the User is authenticated
  const isAuthenticated = ref(false);
  if (EnvProvider.jwt)  {
    isAuthenticated.value = !!AuthClient.getToken();
  }

  // refers to authenticate() function query call
  const isAuthenticating = ref(false);

  // refers to login/logout calls
  const isLoading = ref(false);

  /**
   * Similar to how we set the User, there are several
   * paths for how a User can be logged out. When
   * that happens we need to clean the state.
   */
  function clearUser() {
    meResponseCached.value = null;
    user.value.set(null);
    firstMeCall.value = false;
    isAuthenticated.value = false;
    isAuthenticating.value = false;
  }

  /**
   * Used to determine if authenticated, and if not will attempt
   * to authenticate. Not to be confused with `isAuthenticated`,
   * a non-synchronous value.
   */
  async function authenticate(force?: boolean): Promise<MeResponse | null> {
    try {
      let isForced = !firstMeCall.value || (force ?? false);
      isAuthenticating.value = true;

      // if not forcing and we have a cached value, use the cached value
      if (meResponseCached.value && !isForced) {
        isAuthenticated.value = true;

        return {
          email: user.value?.email || '',
          acceptedTerms: user.value?.acceptedTerms || false,
          twoFactorRequired: user.value.twoFactorRequired || false,
          twoFactorEnabled: user.value.twoFactorEnabled || false,
          twoFactorVerified: user.value.twoFactorVerified || false,
          role: user.value?.role || '',
          privileges: user.value?.privileges || []
        };
      }

      if (!firstMeCall.value) {
        firstMeCall.value = true;
      }

      // inc attempt count
      attempts.value++

      const response = await fetch(`${EnvProvider.endpoints.baseUrl}/me`, {
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          ...(EnvProvider.jwt && AuthClient.getToken() ? {
            Authorization: `Bearer ${AuthClient.getToken()}`
          } : {})
        },
      });

      // only clear the User on a 401 status
      if (response.status === 401) {
        clearUser();
        return null;
      }

      meResponseCached.value = await response.json()

      // update the current user, will refetch all settings if email changes
      if (meResponseCached.value) {
        const updatedUserObject: Partial<AuthUser> = {
          email: meResponseCached.value.email,
          acceptedTerms: meResponseCached.value.acceptedTerms,
          twoFactorRequired: meResponseCached.value.twoFactorRequired || false,
          twoFactorEnabled: meResponseCached.value.twoFactorEnabled,
          twoFactorVerified: meResponseCached.value.twoFactorVerified,
          twoFactorMethods: meResponseCached.value.twoFactorMethods,
          role: meResponseCached.value?.profile?.roleID,
          privileges: meResponseCached.value?.profile?.privileges
        };
        await user.value.set(updatedUserObject)
        isAuthenticated.value = true;
        return updatedUserObject;
      }

      // This should not occur...
      return meResponseCached.value;

    } catch (error) {
      console.error('Authentication error:', {error})
      // if an error occurred and we never cached a previous User value, assume the User was never authenticated
      if (!meResponseCached.value) {
        clearUser();
        return null;
      }
      return meResponseCached.value;

    } finally {
      isAuthenticating.value = false;
    }
  }

  async function logout() {
    try {
      // prevent spamming of logout
      if (isLoading.value || (!isAuthenticated.value && !user.value?.email)) {
        return;
      }

      isLoading.value = true;
      await ApolloClient.mutate({ mutation: GQL_SIGNOUT })
      await AuthClient.logout({}, AuthStrategies.Firebase);
      clearUser();

    } catch (error) {
      throw error

    } finally {
      isLoading.value = false;
    }
  }

  const login = async (
    // data?: AccountData,
    // strategy?: AuthStrategies
  ) => {
    isLoading.value = true
    try {
      // if (data) {
      //   const success = await AuthClient.login(data, strategy);
      //   if (!success) {
      //     console.warn('Login failed. Not Authorized.');
      //     throw "Not authorized.";
      //   }
      // }
      return await authenticate(true)

    } catch (error) {
      throw error
    } finally {
      isLoading.value = false
    }
  }

  /**
   * Used by the login page to determine
   * what login capabilities exist.
   */
  // DEV NOTE :: why computedAsync for a one-time call?
  const loadingStrategies = ref(false)
  const strategies = computedAsync(
    async () => await AuthClient.getStrategies(),
    [],
    { lazy: true, evaluating: loadingStrategies }
  )

  /**
   * Provide a Session ID for single signout.
   * If no ID is provided all sessions will
   * be signed out.
   */
  async function signoutSession(id?: string) {
    try {
      const mutation = id ? GQL_SIGNOUT_SESSION : GQL_SIGNOUT_EVERYWHERE;
      const response = await ApolloClient.mutate({
        mutation,
        variables: id ? { sid: id } : {}
      })
      if (id) {
        return !!response.data?.signout?.success;
      }
      return !!response.data?.signoutEverywhere?.success;
    } catch (error) {
      throw error
    }
  }

  return {
    attempts,
    authenticate,
    isAuthenticated,
    isAuthenticating,
    isLoading,
    login,
    logout,
    signoutSession,
    strategies,
    loadingStrategies,
    verifyEmail: AuthClient.verifyEmail,
    createAccount: AuthClient.createAccount,
    forgotPassword: AuthClient.forgotPassword,
    sendEmailVerification: AuthClient.sendEmailVerification,
    generateToken: AuthClient.generateToken,
    getToken: AuthClient.getToken,
    // resetPassword: AuthClient.resetPassword,
  }
}
