export const GATEWAY_LIST_KEY = 'gateway-list-key';

export enum GATEWAY_CONNECTION_STATUS_LABEL {
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected'
}

// default form fields
export const GATEWAY_FORM_OBJECT = Object.freeze({
  name: '',
  description: '',
  thumbnail: '',
  placeID: undefined,
  place: undefined,
  services: {}
});

export type GATEWAY_FORM_OBJECT_TYPE = typeof GATEWAY_FORM_OBJECT;

// DEV NOTE :: temporary until this data can live in the DB
// TODO :: create a story asking luke to move this data to the backend somehow
export const GATEWAY_SERVICES_DEFAULT_ARGUMENTS = Object.freeze({
  SMTP: ['host', 'port', 'password', 'username', 'from']
});

export const GATEWAY_ALLOWED_SERVICES = ['SMTP'];

/**
 * UI Messages
 */
export const GATEWAY_MISSING_FEATURE = 'Feature not available for this Gateway.';
export const GATEWAY_INVALID_FORM = 'Please fill in all required fields.';
export const GATEWAY_CREATE_SUCCESS = 'Successfully created gateway.';
export const GATEWAY_UPDATE_SUCCESS = 'Successfully updated gateway.';
export const GATEWAY_UPDATE_ERROR = 'An error occurred when trying to update the gateway.';
export const GATEWAY_CONFIRM_DELETE = 'Are you sure you want to delete this gateway?';
export const GATEWAY_DELETED_ERROR = 'An error occurred when trying to delete the gateway.';
export const GATEWAY_DELETED_SUCCESS_FN = (gatewayName: string = '') =>
  `Gateway ${gatewayName ? `(${gatewayName}) ` : ''}successfully deleted.`;

export const GATEWAY_SERVICE_CLEAR_FORM_NOTICE_CUSTOM = `
  The configuration for the Service you are resetting has
  custom fields that were requested and created through
  Qumulex Support. Resetting this will remove access
  to the custom fields possibly required for your
  SMTP settings, as well as clear the main
  settings. Do you wish to reset and
  clear it?
`;

export const GATEWAY_SERVICE_CLEAR_FORM_NOTICE = `
  This will clear all configuration for the SMTP Service.
   Do you wish to clear it completely?
`;

export const GATEWAY_SERVICE_CUSTOM_FIELD_TOOLTIP = `
  This is a custom field, likely set by outside support.
  Take care when editing this information as it could
  cause service disruptions.
`;

export const GATEWAY_SERVICE_CLEAR_FORM_BUTTON_LABEL = `Yes, clear form`

export const GATEWAY_QUEUE_DEPTH_LABEL = `Cloud Upload Metrics`

// TODO :: license expiration tooltip
  // for qxp - you wont have access to cloud stored video... but system will continue to record to local drives
  // for qx0 - you do not receive additional software updates, but the system will still function

// offline mode
export const GATEWAY_OFFLINE_ACCESS_INFO = `
  If a Qumulex Gateway loses internet access, you can still connect and use the system by
  configuring either your DNS Server (local network) or your hosts file (local Client
  machine) and creating an Offline-specific Password. This will allow you to
  connect and view the Gateway from the Local Network while the Internet is
  down. It is highly recommended that you configure a DHCP Reservation for
  the network connected to the internet (or statically assign it if
  needed) if you are going to use this feature.
`

export const GATEWAY_OFFLINE_ACCESS_CONFIGURE = `
  Click Download DNS List to receive a text file with a list of available Gateways at
  your current Scope that contains the Name, Hostname, and IP address. Please be
  aware of your current Scope to ensure you only download the list of Gateways
  desired.
`

export const GATEWAY_OFFLINE_ACCESS_DNS_WARNING = `
  Warning, without properly configuring your host
  file or DNS server you will not be able to
  connect to the Gateway when offline.
`

export const GATEWAY_SYNC_SUCCESS = 'Gateway has been queued for syncing';
export const GATEWAY_SYNC_ERROR_RESPONSE = 'Error Syncing Gateway';
export const GATEWAY_CUSTOMER_PERMISSION_UPDATED_NOTICE = 'Customer Permissions are being updated, this process may take a moment to update depending on the number of Cameras, Doors, and other Configuration settings your Place contains.';
