import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const GATEWAYS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'hard-drive'],
  UI_LABEL: 'Gateways',
  ROUTE_PATH: 'gateways',
  ROUTE_NAME: 'gateways.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.GatewayView]
};

export const GATEWAY_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'hard-drive'],
  UI_LABEL: 'Create Gateway',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'gateway.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.GatewayConfig]
};

export const GATEWAY_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Gateway',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'gateway.edit',
  ROUTE_PARAM: 'gatewayId',
  PRIVILEGES: [qx_ePrivilege_enum.GatewayConfig]
};

export const GATEWAY_DEVICE_DISCOVERY_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'network-wired'],
  UI_LABEL: 'Discover Devices',
  ROUTE_PATH: 'device-discovery',
  ROUTE_NAME: 'gateway.deviceDiscovery',
  ROUTE_PARAM: 'gatewayId',
  PRIVILEGES: [qx_ePrivilege_enum.DeviceConfig]
};

// name used between two route names
const GATEWAY_VIEW_ROUTE_NAME_SHARED = 'gateway.view';

// this route does not register it's ROUTE_NAME because it would duplicate the info route
export const GATEWAY_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'hard-drive'],
  UI_LABEL: 'View Gateway',
  ROUTE_PATH: ':gatewayId',
  ROUTE_NAME: GATEWAY_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'gatewayId',
  PRIVILEGES: [qx_ePrivilege_enum.GatewayView]
};

export const GATEWAY_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: GATEWAY_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'gatewayId',
  PRIVILEGES: [qx_ePrivilege_enum.GatewayView]
};

export const GATEWAY_VIEW_SYSTEM_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'layer-group'],
  UI_LABEL: 'System',
  ROUTE_PATH: 'system',
  ROUTE_NAME: 'gateway.system',
  ROUTE_PARAM: 'gatewayId',
  PRIVILEGES: [qx_ePrivilege_enum.GatewayConfig]
};

export const GATEWAY_NETWORKING_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'server'],
  UI_LABEL: 'Networking',
  ROUTE_PATH: 'networking',
  ROUTE_NAME: 'gateway.networking',
  ROUTE_PARAM: 'gatewayId',
  PRIVILEGES: [qx_ePrivilege_enum.GatewayConfig]
};
