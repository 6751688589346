<template>
  <FaIcon :icon="['fas', 'clock']" />
</template>

<script lang="ts">

  export default {

  }
</script>
