/**
 * For arguments where we do not have predefined
 * configs for how to render... Parse the
 * argument type to determine if it's an
 * array, required, and the type.
 * ---
 * To check if it's an array, we can look for square
 * brackets at the start of the string.
 * This can be done using ^\[?.
 * ---
 * To check if it's required, we can look for an
 * exclamation mark at the end of the string.
 * This can be done using !$.
 * ---
 * To get the value type, we can look for a word that
 * is not an exclamation mark or square brackets.
 * This can be done using [^\[\]!]+.
 */
export function getRuleActionArgumentBreakdown(arg: string): {
  isArray: boolean;
  isRequired: boolean;
  isType: string;
} {

  const argTypeRegex = /^(?<isArray>\[?)(?<type>[^\[\]!]+)(?<isRequired>!?)/;
  const match = argTypeRegex.exec(arg);

  return {
    isArray: !!match?.groups?.isArray,
    isRequired: !!match?.groups?.isRequired,
    isType: match?.groups?.type ?? 'String'
  }
}
