import { useNotifyStore } from '@/store/notify';
import canRouteToRoleEditPage from './utilities/can-route-to-role-edit-page';
import {
  ROLES_VIEW_INFO_ROUTE,
  ROLES_CREATE_ROUTE,
  ROLES_EDIT_ROUTE,
  ROLES_VIEW_ROUTE,
  ROLES_ROUTE
} from '@/config/routes/roles';
import { ROLE_DENY_ROOT_ROLE_EDIT } from '@/config/role';
import { ErrorHandler } from '@/utils/ErrorHandler';


export default [
  /**
   * CONTAINER - All Role Routes will pass through this container
   *  This container is responsible for handling any global Role-related fetching.
   */
  {
    path: ROLES_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "container-roles" */ './RolesContainer.vue')
    },
    meta: {
      privileges: ROLES_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * Create Role
       */
      {
        path: ROLES_CREATE_ROUTE.ROUTE_PATH,
        name: ROLES_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: ROLES_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "page-roles-create" */ './RolesCreatePage.vue')
        }
      },

      /**
       * List of Roles
       */
      {
        path: '',
        name: ROLES_ROUTE.ROUTE_NAME,
        // meta: {},
        components: {
          default: () => import(/* webpackChunkName: "page-roles-list" */ './RolesListPage.vue')
        }
      },

      /**
       * CONTAINER - View Role Routes will pass through this container
       *  Parent Route is a container to fetch the Role object
       *  Child routes (includes view page) will reference the Role object pulled in the container
       *  This container will hold some generic/global elements for all Role View Pages
       */
      {
        path: ROLES_VIEW_ROUTE.ROUTE_PATH,
        // name: ROLE_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: ROLES_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "container-roles-view" */ './RolesContainerView.vue')
        },
        children: [
          /**
           * View Role - Info
           */
          {
            path: ROLES_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: ROLES_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: ROLES_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-roles-view-info" */ './RolesViewInfoPage.vue')
            }
          },
          /**
           * Edit Role
           */
          {
            beforeEnter: async (to, from, next) => {
              try {
                await canRouteToRoleEditPage(to.params[ROLES_EDIT_ROUTE.ROUTE_PARAM])
                next();
              } catch (error) {
                useNotifyStore().error(ErrorHandler(error).message)
                next({ name: ROLES_VIEW_INFO_ROUTE.ROUTE_NAME, params: to.params });
              }
            },
            path: ROLES_EDIT_ROUTE.ROUTE_PATH,
            name: ROLES_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: ROLES_EDIT_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-roles-edit" */ './RolesEditPage.vue')
            }
          },
        ]
      }
    ]
  }
];
