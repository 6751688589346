// TODO :: Vue 3 - catch all no longer supported https://stackoverflow.com/a/64186073/865803
import { NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router';
import { getAppHomeRouteConfig } from './get-app-home-route-config';
import { getDeepRouteTreeProperty } from './utilities';
import { qx_ePrivilege_enum } from '@/graphql/types';
import { hasPrivileges } from '@/utils/Privileges';
import { validateMiddleware } from './middleware';
import { CONFIG_ROUTES } from '@/config/routes';
import { useAuthStore } from '@/store/auth';

/**
 * Determines if the `from` or `to` route has a redirect query.
 * If yes return a `route.path` string.
 */
// export const routesHaveRedirect = (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded) => {
//   const fromPath = from?.query?.[CONFIG_ROUTES.QUERY_REDIRECT_KEY] || false;
//   const toPath = to?.query?.[CONFIG_ROUTES.QUERY_REDIRECT_KEY] || false;
//   if (toPath) return toPath;
//   return fromPath !== to.fullPath ? fromPath : false;
// };

export const routeAuthorization = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext
) => {
  try {

    /**
     * Check for a redirect after confirming initially receiving the User
     * information object. It's possibly the User logged back in after
     * attempting to visit an authenticated route. We should redirect
     * them to where they were originally headed.
     *
     * This redirect logic must always come after
     * successfully retrieving the User object.
     */
    // TODO :: redirects are likely broken
    // const hasRedirectPath: string | false = routesHaveRedirect(to, from);
    // if (hasRedirectPath) {
    //   return next({
    //     path: hasRedirectPath as string
    //   });
    // }

    /**
     * Perform privilege checks if they exist on the route.
     */
    const privsPerRoute = getDeepRouteTreeProperty<string>(to, 'privileges') as qx_ePrivilege_enum[];
    const privsExist = [...new Set(privsPerRoute?.flat?.() || [])];
    if (!!privsExist?.length) {
      const auth = useAuthStore();
      let userPrivs = auth.user.privileges;
      // router may load before auth is ready
      if (!auth.user.privileges.length) {
        // perform a /me request to get the user's privileges
        const user = await auth.account.authenticate(true)
        if (user) {
          userPrivs = user?.privileges || []
        }
      }

      const isMissingPrivs = privsPerRoute.some(privs => {
        return !hasPrivileges(privs, userPrivs);
      });

      if (isMissingPrivs) {
        // try going up 1 level in the route tree
        const toPath = to.path.split('/');
        toPath.pop();
        const routeCount = toPath.length;
        const newPath = toPath.join('/');
        if (newPath && routeCount > 2) {
          return next({ path: newPath });
        }

        // otherwise re-route user to their home page
        return next(getAppHomeRouteConfig(userPrivs));
      }
    }

    return await validateMiddleware({ to, from, next });

  } catch (error) {
    // This catch should never occur, but never say never...
    console.error('routeAuthorization Error - This catch should never occur, but never say never...', { error });
    return next({ name: CONFIG_ROUTES.APP_HOME });
  }
};

/**
 * Maybe we want to capture some analytics information
 * about the confirmed route the User is going to.
 */
// export const postRouteAuthorization = async (to: Route, from: Route): Promise<void> => {
  // TODO :: log screen view
  // mutations.logScreenView(to.path);
// };
