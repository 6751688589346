import { AUTH_LOGIN_ROUTE, AUTH_LOGOUT_ROUTE, AUTH_TWO_FACTOR_ROUTE } from '@/config/routes/auth';
import { watch, ref, defineComponent, onBeforeUnmount, onMounted, computed } from 'vue';
import { useDebounceFn, watchDebounced } from '@vueuse/core';
import { GqlErrorBus } from '@/events/gql-error-bus';
import { useRoute, useRouter } from 'vue-router';
import { CONFIG_ROUTES } from '@/config/routes';
import { AuthUser } from './types';
import { useAuthStore } from '.';
import { v4 } from 'uuid';

/**
 * The only logic that should live in here are things
 * that can occur at a global level that would need
 * to directly affect the UI.
 */
export const AuthManager = defineComponent({
  name: 'AuthManager',
  setup(props: any, context: any) {

    const auth = useAuthStore();
    const router = useRouter();
    const route = useRoute();

    const hasForcedLogout = ref(false);

    /**
     * Changes this value will re-mount the entire
     * app; essentially a "soft page refresh".
     */
    const appKey = ref(v4());

    watch(() => [
      auth.scope.root.id,
      auth.scope.root.path,
      auth.scope.current.id,
      auth.scope.current.path,
    ], async (newData, oldData) => {
      if (oldData.filter(v => !!v).length !== 4) {
        return;
      }

      // do not refresh app unless user is authenticated
      if (!auth.account.isAuthenticated) {
        return;
      }

      // check if the scope values have changed
      const n = [...new Set(newData)].filter(v => !!v);
      const o = [...new Set(oldData)].filter(v => !!v);

      if (auth.account.isAuthenticated && JSON.stringify(n) !== JSON.stringify(o)) {
        appKey.value = v4();
        console.warn('App refreshed due to auth change.')
      }
    });

    /**
     * Check for authentication errors; debounced.
     * - access-denied (not authenticated; needs to login)
     * - validation-failed (authenticated; "permission issue" or "needs 2fa verification")
     */
    const _checkForAuthenticationError = useDebounceFn(async (errorCode: string) => {
      try {
        if (route?.name?.startsWith?.('auth')) {
          return;
        }

        console.warn(`${errorCode}: Re-authenticating...`);
        const authedUser = await auth.account.authenticate(true)
        if (!authedUser) {
          await router.push({
            name: AUTH_LOGOUT_ROUTE.ROUTE_NAME,
            query: { redirect: route.fullPath }
          }).catch(() => {});
        }
      } catch (error) {
        return;
      }
    }, 500);

    /**
     * Should the User ever become unauthenticated,
     * and we are not already on the Login page,
     * we must immediately redirect them back
     * to the login page.
     */
    watchDebounced(() => [
      auth.user,
      auth.account.isAuthenticated,
    ], async (newData, oldData) => {

      // we need to know route info before doing anything
      if (!route.name) return;

      const [ user, isAuthenticated ] = newData as [ AuthUser | null, boolean, boolean, boolean ];
      // const [ oldUser ] = oldData as [ AuthUser | null ] || [ null ];


      const isAuthRoute = [
        AUTH_LOGIN_ROUTE.ROUTE_NAME,
        AUTH_LOGOUT_ROUTE.ROUTE_NAME
      ].includes(route.name as string)

      // check that the User object has changed, there are things that can trigger the value to get set more than once.
      // const hasChangedUser = JSON.stringify(user) !== JSON.stringify(oldUser);

      // reset force logout if user becomes authenticated again
      if (isAuthenticated && !hasForcedLogout.value) {
        hasForcedLogout.value = false;
      }

      /**
       * If the User is not authenticated, and is not
       * on the Login page, force them to logout.
       */
      if (!isAuthRoute && user?.twoFactorEnabled && !user?.twoFactorVerified) {
        console.warn('User has 2FA Enabled but is not Verified. Redirecting to 2FA authorization page...');
        await router.push({
          name: AUTH_TWO_FACTOR_ROUTE.ROUTE_NAME,
          query: { redirect: route.fullPath }
        }).catch(() => {});
        return;
      }

      /**
       * If the User is already logged in, is logged in by redirect, or
       * logged in via Email, we should look to the `isAuthenticated`
       * and `user` reactive variables. If both variables are true
       * we redirect the User to the app homepage.
       */
      if (isAuthenticated && isAuthRoute && user) {
        console.warn('User is authenticated. Redirecting to app home...', route.query);

        const routeTo = route.query.redirect ? {
          path: route.query.redirect,
        } : {
          name: CONFIG_ROUTES.APP_HOME,
        };

        await router.push(routeTo).catch(() => {});
        return;
      }

      /**
       * This should always run last, and only ever run if
       * a User is on a page and is suddenly logged out.
       */
      const isPublicRoute = computed(() => route.matched?.[0]?.meta?.public);
      if (!isAuthenticated && !isAuthRoute && !isPublicRoute.value) {
        console.warn('User is not authenticated. Redirecting to logout.');
        await router.push({
          name: AUTH_LOGOUT_ROUTE.ROUTE_NAME,
          query: { redirect: route.fullPath }
        }).catch(() => {});
        return;
      }

    }, { debounce: 500, deep: true, immediate: true })

    /**
     * Listen for Network Errors that
     * may relate to authentication.
     */
    onMounted(() => {

      GqlErrorBus.on('error', ({ detail }) => {
        // console.error('GqlErrorBus', detail)

        // do nothing on logout error
        if (detail.operation.operationName == 'GQL_LOG_OUT') {
          return;
        }

        const accessDenied = detail.graphQLErrors?.some?.((error: any) => {
          return [
            'access-denied',
          ].includes(error?.extensions?.code) ? error?.extensions?.code : false;
        }) || false;

        if (accessDenied) {
          _checkForAuthenticationError(accessDenied)
        }
      });
    });

    onBeforeUnmount(() => {
      GqlErrorBus.off('error', () => {});
    });

    /**
     * Render the App.
     */
    return () => context.slots.default({
      appKey: appKey.value
    });
  },
});
