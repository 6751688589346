import { SETTINGS_ROUTE } from '@/config/routes';
import { useDeviceStore } from '@/store/device';
import { ROOT_USER_DOMAIN } from '@/config/app';
import { useAuthStore } from '@/store/auth';

// TODO :: useSupportConditions has this core logic... should we merge somehow?
const IsRootUser: Middleware = async function() {
  const user = await useAuthStore().account.authenticate();
  const device = useDeviceStore();
  if (!user || !user?.email?.includes?.(ROOT_USER_DOMAIN) && !device.isSuperSaiyan) {
    return { name: SETTINGS_ROUTE.ROUTE_NAME };
  }
}

export default IsRootUser;
