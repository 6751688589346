import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const PEOPLE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'user'],
  UI_LABEL: 'People',
  ROUTE_PATH: 'people',
  ROUTE_NAME: 'people.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleView]
};

export const PERSON_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'user'],
  UI_LABEL: 'Create Person',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'person.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleConfig]
};

export const PERSON_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Person',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'person.edit',
  ROUTE_PARAM: 'personId',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleConfig]
};

// name used between two route names
const PERSON_VIEW_ROUTE_NAME_SHARED = 'person.view';

// this route does not register it's ROUTE_NAME
// that is because it would duplicate the info route
export const PERSON_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'user'],
  UI_LABEL: 'View Person',
  ROUTE_PATH: ':personId',
  ROUTE_NAME: PERSON_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'personId',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleView]
};

export const PERSON_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: PERSON_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'personId',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleView]
};

export const PERSON_PRIVILEGES_ROUTE = Object.freeze<ConfigRouteObject>({
  UI_ICON: ['fas', 'user'],
  UI_LABEL: 'Privileges',
  ROUTE_PATH: 'privileges',
  ROUTE_NAME: 'person.privileges',
  ROUTE_PARAM: 'personId',
  PRIVILEGES: [qx_ePrivilege_enum.UserCreation]
});
