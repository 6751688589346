import { onError } from "@apollo/client/link/error";

/**
 * All GQL Errors are sent to the client via EventBus.
 */
export const errorLink = (
  errorHandler?: (errors: any) => void
) => onError((error) => {

  const {
    graphQLErrors,
    networkError,
    operation,
    forward
  } = error;

  if (errorHandler) {
    errorHandler(error)
  }

  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      // do nothing
      switch (err?.extensions?.code) {
        case "access-denied":
          break;
        default:
          console.error(
            `%c[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
            'color: red; font-family:monospace; font-size: 12px;'
          )
          break;
      }
    }
  }

  // TODO :: network errors
  if (networkError) {
    console.warn(`Network Error: ${networkError.message}`, operation.operationName, {
      // context: operation.getContext(),
      // operation,
      // networkError
    });

    if (operation?.query?.definitions?.[0]?.operation === 'query') {
      operation.setContext({
        fetchPolicy: 'cache-only'
      });

      const result = operation.getContext().cache.read({
        query: operation.query,
        variables: operation.variables
      })

      console.warn('network error cache result', operation.operationName, { result })
    }

    return forward(operation);
  }
});
