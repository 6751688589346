<template>
  <div class="DialogManager" v-show="store.instances.length">
    <div class="DialogManager__con">
      <div class="DialogManager__item" v-for="(dialog, index) in store.instances">
        <component
          :ref="(node: unknown) => store.setRef(dialog.id, node)"
          :key="dialog.id"
          :is="dialog.component"
          :id="dialog.id"
          :name="dialog.name"
          :message="dialog.message"
          :options="dialog.options"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { useDialogStore } from '.';
import { useUiStore } from '../ui';
const store = useDialogStore();
const ui = useUiStore();

watch(() => store.instances.length, (val) => {
  ui.scroll.toggle(!!val)
});
</script>

<style lang="scss" scoped>
.DialogManager {
  position: fixed;
  inset: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(var(--size-1));
  padding: var(--size-6);

  &:after {
    display: block;
    content: '';
    position: absolute;
    z-index: 1;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: var(--color-background-700);
  }

  &__con {
    pointer-events: all;
  }

  &__item {
    position: relative;
    z-index: 5;
  }
}
</style>
