export const isArray = (obj) => Object.prototype.toString.call(obj) == '[object Array]';

export const insertIntoArrayIf = (condition, ...elements) => {
  return condition ? elements : [];
};

export const filterArrayDuplicates = (existingArray: [], incomingArray: []) => {
  return Array.from(new Set([...existingArray, ...incomingArray].map(JSON.stringify)), JSON.parse);
};

export const asyncSome = async <T>(
  array: T[],
  predicate: (item: T) => Promise<boolean>
): Promise<boolean> => {
  try {
    for (const item of array) {
      if (await predicate(item)) return true;
    }
    return false;
  } catch (error) {
    throw error
  }
};

// return an array of integers from [0 ...to... end]
export const createRangeFromZero = (end: number) => {
  return Array.from(Array(end), (_, x) => x)
}
