import { ref } from 'vue';

export function useAppScrollbar() {

  const isLocked = ref(false);

  const toggle = (val: boolean) => {
    const html = document.getElementsByTagName('html')[0];

    if (val && !html.classList.contains('no-scroll')) {
      html.classList.add('no-scroll');

    } else {
      html.classList.remove('no-scroll');
    }
  };

  return {
    isLocked,
    toggle
  }
}
