<template>
  <div class="App">
    <LoadingOverlay v-show="showOverlay" :message="auth.loadingMessage" />
    <RouterView v-show="!showOverlay" />
  </div>
</template>

<script setup lang="ts">
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import { EnvProvider, Envs } from '@/utils/EnvProvider';
import { bootstrap, event, customMap } from 'vue-gtag';
import { CONFIG_ROUTES } from '@/config/routes';
import { useAuthStore } from '@/store/auth';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const auth = useAuthStore();
const route = useRoute();

const buildVersion = '__VERSION__';
const buildHash = '__HASH__';
const version = `${buildVersion}-${buildHash}`;

const showOverlay = computed(() => {
  /**
   * partial auth pages may have an authenticated user, but not
   * fully authenticated for certain gql operations, we need
   * those pages to render without a user object
   */
  const isPartialAuth = route?.meta?.partialAuth;

  // app mounted w/ route middleware, no route defined yet, and awaiting authentication check
  const isAppInit = (!route.name && auth.account.isAuthenticating)

  // auth routes
  const isAuthRoute = route.name?.startsWith?.('auth.'); // && !isTermsPage && !isVerifyPage;
  // ... while awaiting authentication check
  const isAuthRouteLoading = (isAuthRoute && auth.account.isAuthenticating)
  // ... while authenticated
  const isAuthRouteAuthenticated = (isAuthRoute && auth.account.isAuthenticated)

  // protected routes while auth setup not completed
  const isProtectedRoute = route.fullPath?.startsWith?.(CONFIG_ROUTES.APP_PATH_PREFIX+'/');
  const isProtectedRouteWithoutAuth = (isProtectedRoute && !auth.isReady);

  const isAuthLoading = isAppInit
    || isAuthRouteLoading
    || isAuthRouteAuthenticated
    || isProtectedRouteWithoutAuth;

  // Do not show overlay if we are on a partial auth page
  return isAuthLoading && !(isPartialAuth);
});

onMounted(() => {
  if (EnvProvider.env !== Envs.Cloud) return;

  /**
   * The bootstrap method will return a promise that will be
   * resolved when the script has been loaded completely.
   */
  bootstrap().then(() => {
    customMap({ dimension2: 'version' });
    event('client', { version });
  }).catch((error) => {
    console.error('Failed to load analytics', {error});
  });
})
</script>

<style lang="scss" scoped>
.App {
  height: 100%;
  width: 100%;
  box-shadow: none;
  position: relative;
  z-index: 5;
}
</style>
