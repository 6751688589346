import { ConfigRouteObject } from '@/types';

export const INTEGRATIONS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'puzzle'],
  UI_LABEL: 'Integrations',
  ROUTE_PATH: 'integrations',
  ROUTE_NAME: 'integrations.index',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};
