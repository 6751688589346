<template>
  <CameraIcon :status="cameraStatus" v-if="showIcon" />
  <span v-else-if="showLabel">
    {{ cameraStatusLabel }}
  </span>
</template>

<script lang="ts">
import { CAMERA_DISABLED_BY, CAMERA_DEVICE_CONNECTION_STATUS_ICON } from '@/constants/camera';
import { smartIconValidator } from './helpers/smart-icon-validator'
import { qx_eDeviceConnectionStatus_enum } from '@/graphql/types';
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    camera: {
      type: Object,
      required: true
    },
    type: {
      required: false,
      default: "icon",
      validator(value) {
        return smartIconValidator(value);
      }
    }
  },
  computed: {
    gatewayIsDisconnected() {
      return !(this.camera?.gatewayConnectionStatus || false)
    },
    isDeleting() {
      return [
        qx_eDeviceConnectionStatus_enum.Deleting,
        qx_eDeviceConnectionStatus_enum.DeleteOnCloudStorageExpire
      ].includes(this.camera?.deviceConnectionStatus)
    },
    cameraDisabled() {
      return !this.camera?.enabled
    },
    cameraStatus() {
      if (this.isDeleting) {
        return this.camera?.deviceConnectionStatus
      }
      if (this.gatewayIsDisconnected) {
        return CAMERA_DISABLED_BY.GATEWAY
      }
      if (this.cameraDisabled) {
        return CAMERA_DISABLED_BY.CAMERA_DISABLED
      }
      return this.camera?.deviceConnectionStatus
    },
    cameraStatusLabel() {
      if (this.isDeleting) {
        return CAMERA_DEVICE_CONNECTION_STATUS_ICON[this.camera.deviceConnectionStatus].tooltip
      }
      if (this.gatewayIsDisconnected) {
        return CAMERA_DEVICE_CONNECTION_STATUS_ICON[CAMERA_DISABLED_BY.GATEWAY].value;
      }
      if (this.cameraDisabled) {
        return CAMERA_DEVICE_CONNECTION_STATUS_ICON[CAMERA_DISABLED_BY.CAMERA_DISABLED].value;
      }
      return this.camera?.deviceConnectionStatus;
    },
    showIcon() {
      return this.type === "icon";
    },
    showLabel() {
      return this.type === "label";
    }
  }
});
</script>
