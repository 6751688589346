import { qx_Clip, qx_VideoSource, qx_MediaProfile } from "@/graphql/types";

export enum StunTurnOverride {
  StunOnly = 'Stun Only',
  TurnOnly = 'Turn Only',
  RelayOnly = 'Relay Only',
  StunAndTurn = 'Stun And Turn'
};

export enum VideoPlayerMode {
  event = 'event',
  live = 'live',
  timeline = 'timeline',
  file = 'file'
};

export interface ClipPlayerInfo {
  id: string;
  eventToken: string;
  cloudEventToken: string;
  deviceID: string;
  videoDeviceID: string;
  mediaProfileID: string;
  mediaProfile: Partial<qx_MediaProfile>;
  refID: String;
  gatewayID: string;
  placeID: string;
  place: string;
  name: string;
  camera: string;
  videoSourceID: string;
  videoSource: Partial<qx_VideoSource>;
  autoPlay: boolean;
  isTimeline?: boolean;
  isTimelapse?: boolean;
  hasVideo: boolean;
  cloudPlayback: boolean;
  viewSettings: Record<string, string>;
}

interface LivePlayerInfo extends Pick<ClipPlayerInfo, 'videoSource' | 'viewSettings' > {}

export enum VideoPlayerEventName {
  cloudClipExportNotification = 'cloud-clip-export-notification',
  playbackNotification = 'playback-notification',
  epochTimeUpdate = 'epoch-time-update',
  playPauseUpdate = 'play-pause-update',
  playableRangesUpdated = 'playable-ranges-updated',
  emitInitialVideoSourceSettings = 'emit-initial-video-source-settings',
  remountPlayer = 'remount-player',
  invalidViewToken = 'invalid-view-token',
  defragSlowWarn = 'defrag-slow-warn',
  defragWorkerError = 'defrag-worker-error',
  playerEvent = 'player-event',
  fullWindow = 'full-window',
  dataChannelStatus = 'data-channel-status',
  playerReady = 'player-ready',
  saveViewSettings = 'save-view-settings',
}

export type VideoPlayerEventCallback = (...args: any[]) => void;

// TODO :: fix typing
export type VideoPlayerEventCallbacks = {
  [key: keyof typeof VideoPlayerEventName]?: VideoPlayerEventCallback;
}

interface BasePlayerOptions {
  info?: Partial<ClipPlayerInfo>;
  autoPlay?: boolean;
  fullscreenId?: string;
  controls?: boolean;
  contained?: boolean;
  hud?: boolean;
  stunTurnOverride?: StunTurnOverride;
  // file playback options
  source?: string;
  clip?: qx_Clip;
  public?: boolean;
  email?: string; // only needed for auth in public clip page
}

interface EventPlayerOptions extends Omit<BasePlayerOptions, 'clip' | 'email'> {
  mode: VideoPlayerMode.event;
  info: ClipPlayerInfo;
}

interface FilePlayerOptions extends Omit<BasePlayerOptions, 'cloudPlayback'> {
  mode: VideoPlayerMode.file;
}

interface LivePlayerOptions extends Omit<BasePlayerOptions, 'autoPlay' | 'clip' | 'email'> {
  mode: VideoPlayerMode.live;
  info: LivePlayerInfo;
}

interface TimelinePlayerOptions extends Omit<BasePlayerOptions, 'clip' | 'email'> {
  mode: VideoPlayerMode.timeline;
}

/**
 * The code below is a TypeScript module that exports a type called
 * VideoPlayerOptions. This type is a union type that can have one
 * of four possible values: LivePlayerOptions,
 * TimelinePlayerOptions, EventPlayerOptions,
 * or FilePlayerOptions.
 *
 * Each of these options represents a different type of video player.
 * The LivePlayerOptions type represents a video player that
 * streams live video content. The TimelinePlayerOptions
 * type represents a video player that allows the user
 * to navigate through a timeline of video content.
 * The EventPlayerOptions type represents a video
 * player that is designed for playing back
 * recorded events. The FilePlayerOptions
 * type represents a video player that
 * plays back pre-recorded video files.
 *
 * By using a union type, the VideoPlayerOptions type allows the
 * user to specify which type of video player they want to use.
 * This makes the code more flexible and easier to maintain,
 * as new types of video players can be added to the union
 * type without having to modify the code that uses it.
 *
 * Overall, this code demonstrates the power of TypeScript's type system, which allows developers to create complex types that can be used to represent different types of data in a clear and concise way.
 */
export type VideoPlayerOptions =
  | LivePlayerOptions
  | TimelinePlayerOptions
  | EventPlayerOptions
  | FilePlayerOptions
