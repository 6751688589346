import { CONFIG_ROUTES } from '@/config/routes';
import { useAuthStore } from '@/store/auth';

const RedirectUserToHomeIfAuthenticated: Middleware = async function(ctx) {
  if (await useAuthStore().account.authenticate()) {
    console.warn('User is authenticated. Redirecting to app home.');
    if (ctx.to.query.redirect) {
      return { path: ctx.to.query.redirect }
    }
    return { name: CONFIG_ROUTES.APP_HOME };
  }
}

export default RedirectUserToHomeIfAuthenticated;
