import {
  SAVED_CLIPS_ROUTE,
  SAVED_CLIP_EDIT_ROUTE,
  SAVED_CLIP_VIEW_ROUTE,
  SAVED_CLIP_VIEW_INFO_ROUTE,
  SAVED_CLIP_AUDIT_LOG_ROUTE
} from '@/config/routes/saved-clips';

export default [
  /**
   * CONTAINER - All Clip Routes will pass through this container
   *  This container is responsible for handling any global clip-related fetching.
   */
  {
    path: SAVED_CLIPS_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "container-clip" */ './SavedClipsContainer.vue')
    },
    meta: {
      privileges: SAVED_CLIPS_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * List of Clips
       */
      {
        path: '',
        name: SAVED_CLIPS_ROUTE.ROUTE_NAME,
        // meta: {},
        components: {
          default: () => import(/* webpackChunkName: "page-clip-list" */ './SavedClipsListPage.vue')
        }
      },

      /**
       * CONTAINER - View Clip Routes will pass through this container
       *  Parent Route is a container to fetch the clip object
       *  Child routes (includes view page) will reference the clip object pulled in the container
       *  This container will hold some generic/global elements for all Clip View Pages
       *
       * View route: Parent/container for view info, edit, audit log routes
       * View info route: shows clip view, saved clip details, shared link panel
       * Edit route: Edit saved clip info
       * Audit log route: View audit log panel/page
       */
      {
        path: SAVED_CLIP_VIEW_ROUTE.ROUTE_PATH,
        // name: SAVED_CLIP_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: SAVED_CLIP_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "container-clip-view" */ './SavedClipContainerView.vue')
        },
        children: [
          /**
           * View Clip - Info
           */
          {
            path: SAVED_CLIP_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: SAVED_CLIP_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: SAVED_CLIP_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-clip-view-info" */ './SavedClipViewInfoPage.vue')
            }
          },

          /**
           * Edit Clip
           */
          {
            path: SAVED_CLIP_EDIT_ROUTE.ROUTE_PATH,
            name: SAVED_CLIP_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: SAVED_CLIP_EDIT_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-clip-edit" */ './SavedClipEditPage.vue')
            }
          },

          /**
           * Clip Audit Logs
           */
          {
            path: SAVED_CLIP_AUDIT_LOG_ROUTE.ROUTE_PATH,
            name: SAVED_CLIP_AUDIT_LOG_ROUTE.ROUTE_NAME,
            meta: {
              privileges: SAVED_CLIP_AUDIT_LOG_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-clip-audit-log" */ './SavedClipAuditLogPage.vue')
            }
          }
        ]
      }
    ]
  }
];
