import { createEventHook, useWindowSize, useDebounceFn } from "@vueuse/core";
import { watch } from "vue";

export function useViewport() {
  const window = useWindowSize();
  const windowResize = createEventHook<any>();

  /**
   * Do not run the trigger excessively. Give
   * the viewport resizing a moment to stop
   * before attempting callbacks.
   */
  const trigger = useDebounceFn(({ width, height }) => {
    windowResize.trigger({ width, height });
  }, 300);

  // trigger callbacks on window resize
  watch(() => window, ({ width, height }) => {
    trigger({ width, height });
  }, { immediate: true, deep: true });

  return {
    width: window.width,
    height: window.height,
    onResize: windowResize.on
  }
}
