<template>
  <FaIcon
    v-if="status"
    v-tooltip="tooltip"
    :icon="['fa', icon]"
    :class="{
      ['IconColor IconColor--' + color]: color
    }"
  />
</template>

<script lang="ts">
import { mixinStatusProps, mixinFaIcons } from '@/icons/mixins';
import { defineComponent, computed, reactive } from 'vue';
import { qx_eClipStatus_enum } from '@/graphql/types';

export default defineComponent({
  mixins: [mixinFaIcons],
  props: {
    ...mixinStatusProps,
  },
  setup(props: {
    status: string;
    disabled: boolean;
  }) {
    const icon = computed(() => {
      switch(props.status) {
        case qx_eClipStatus_enum.Initialized:
          return 'cloud-arrow-up'
        case qx_eClipStatus_enum.Processing:
          return 'gears'
        case qx_eClipStatus_enum.Finished:
        case qx_eClipStatus_enum.FinishedWithErrors:
          return 'floppy-disk'
        case qx_eClipStatus_enum.Error:
          return 'triangle-exclamation'
        default:
          return 'triangle-exclamation'
      }
    })
    const color = computed(() => {
      if (props.status === qx_eClipStatus_enum.Finished) return 'green';
      else if (props.status === qx_eClipStatus_enum.Error) return 'red';
      return 'blue';
    });
    const tooltip = reactive({
      triggers: ['click', 'hover', 'focus'],
      content: `Upload Status:  ${props.status[0].toUpperCase()}${props.status.substring(1)}`,
    });

    return {
      icon,
      color,
      tooltip
    };
  }
});
</script>
