<template>
  <FaIcon :icon="icon" v-tooltip="tooltip" />
</template>

<script setup lang="ts">
import { guardComponentMap } from '@/models/rule/utils/rule-guards';
import { FlowGuardOperator } from 'qx-flow-orm';
import { computed } from 'vue';

const props = defineProps<{
  type: FlowGuardOperator
}>()

const hasGuardConfig = computed(() => guardComponentMap?.[props.type]);
const icon = computed(() => hasGuardConfig.value?.icon || ['fas', 'circle-question']);

const tooltip = computed(() => {
  if (!hasGuardConfig.value) return false;
  return {
    html: true,
    content: `<strong>${hasGuardConfig.value.title}</strong><br /><small>${hasGuardConfig.value.desc}</small>`
  }
});
</script>
