import {
  DEVICES_ROUTE,
  DEVICE_CREATE_ROUTE,
  DEVICE_EDIT_ROUTE,
  DEVICE_VIEW_ROUTE,
  DEVICE_VIEW_INFO_ROUTE,
  DEVICE_VIEW_WIRING_ROUTE
} from '@/config/routes/devices';

export default [
  /**
   * CONTAINER - All Device Routes will pass through this container
   *  This container is responsible for handling any global Device-related fetching.
   */
  {
    path: DEVICES_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "container-device" */ './DeviceContainer.vue')
    },
    meta: {
      privileges: DEVICES_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * Create Device
       */
      {
        path: DEVICE_CREATE_ROUTE.ROUTE_PATH,
        name: DEVICE_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: DEVICE_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "page-device-create" */ './DeviceCreatePage.vue')
        }
      },

      /**
       * List of Devices
       */
      {
        path: '',
        name: DEVICES_ROUTE.ROUTE_NAME,
        // meta: {},
        components: {
          default: () => import(/* webpackChunkName: "page-device-list" */ './DeviceListPage.vue')
        }
      },

      /**
       * CONTAINER - Device View Routes will pass through this container
       *  Parent Route is a container to fetch the Device object
       *  Child routes (includes view page) will reference the Device object pulled in the container
       *  This container will hold some generic/global elements for all Device View Pages
       */
      {
        path: DEVICE_VIEW_ROUTE.ROUTE_PATH,
        // name: DEVICE_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: DEVICE_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "container-device-view" */ './DeviceContainerView.vue')
        },
        children: [
          /**
           * Device View - Info
           */
          {
            path: DEVICE_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: DEVICE_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: DEVICE_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-device-view-info" */ './DeviceViewInfoPage.vue')
            }
          },

          /**
           * Device View - Wiring
           */
          {
            path: DEVICE_VIEW_WIRING_ROUTE.ROUTE_PATH,
            name: DEVICE_VIEW_WIRING_ROUTE.ROUTE_NAME,
            meta: {
              privileges: DEVICE_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-device-view-wiring" */ './DeviceViewWiringPage.vue')
            }
          },

          /**
           * Edit Device
           */
          {
            path: DEVICE_EDIT_ROUTE.ROUTE_PATH,
            name: DEVICE_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: DEVICE_EDIT_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-device-edit" */ './DeviceEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];
