import {
  DOOR_VIEW_SETTINGS_ROUTE,
  DOOR_VIEW_INFO_ROUTE,
  DOOR_CREATE_ROUTE,
  DOOR_EDIT_ROUTE,
  DOOR_VIEW_ROUTE,
  DOORS_ROUTE,
} from '@/config/routes/doors';

export default [
  {
    path: DOORS_ROUTE.ROUTE_PATH,
    components: {
      default: () => import('./DoorContainer.vue')
    },
    meta: {
      privileges: DOORS_ROUTE.PRIVILEGES
    },
    children: [
      {
        path: DOOR_CREATE_ROUTE.ROUTE_PATH,
        name: DOOR_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: DOOR_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import('./DoorCreatePage.vue')
        }
      },
      {
        path: '',
        name: DOORS_ROUTE.ROUTE_NAME,
        components: {
          default: () => import('./DoorListPage.vue')
        }
      },

      /**
       * CONTAINER - View Door Routes will pass through this container
       *  Parent Route is a container to fetch the door object
       *  Child routes (includes view page) will reference the door object pulled in the container
       *  This container will hold some generic/global elements for all Door View Pages
       */
      {
        path: DOOR_VIEW_ROUTE.ROUTE_PATH,
        meta: {
          privileges: DOOR_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import('./DoorContainerView.vue')
        },
        children: [
          {
            path: DOOR_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: DOOR_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: DOOR_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import('./DoorViewInfoPage.vue')
            }
          },
          {
            path: DOOR_VIEW_SETTINGS_ROUTE.ROUTE_PATH,
            name: DOOR_VIEW_SETTINGS_ROUTE.ROUTE_NAME,
            meta: {
              privileges: DOOR_VIEW_SETTINGS_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import('./DoorViewConfigPage.vue')
            }
          },
          {
            path: DOOR_EDIT_ROUTE.ROUTE_PATH,
            name: DOOR_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: DOOR_EDIT_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import('./DoorEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];
