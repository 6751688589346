import { qx_eBatteryLevel_enum } from '@/graphql/types';

export const BATTERY_DEFAULT_ICON = 'battery-empty';

export const getBatteryIconStatus = (
  status: qx_eBatteryLevel_enum,
  parentDisconnected: boolean = false,
  enabled: boolean = true
) => {
  if (parentDisconnected) {
    return {
      icon: 'battery-half',
      color: 'grey',
      tooltip: 'Unknown'
    };
  }
  if (!enabled) {
    return {
      icon: 'battery-half',
      color: 'grey',
      tooltip: 'Disabled'
    };
  }
  // TODO :: need to understand what `status` looks like, logic from backend doesnt exist yet
  switch (status) {
    case qx_eBatteryLevel_enum.Normal:
      return {
        icon: 'battery-full',
        color: 'success',
        tooltip: 'Full'
      };
    // case qx_eBatteryLevel_enum.Low:
    //   return {
    //     icon: 'battery-three-quarters',
    //     color: 'success',
    //     tooltip: '~75%'
    //   };
    // case qx_eBatteryLevel_enum.Flat:
    //   return {
    //     icon: 'battery-half',
    //     color: 'info',
    //     tooltip: '~50%'
    //   };
    case qx_eBatteryLevel_enum.Low:
      return {
        icon: 'battery-quarter',
        color: 'warn',
        tooltip: '<25%'
      };
    case qx_eBatteryLevel_enum.Flat:
      return {
        icon: 'battery-empty',
        color: 'danger',
        tooltip: 'Dead'
      };
    default:
      return {
        icon: 'battery-slash',
        color: 'danger',
        tooltip: 'Dead or Unknown'
      };
  }
};
