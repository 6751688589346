export const themes = {
  light: {
    background: {
      100: 'hsl(0, 0%, 100%)',
      200: 'hsl(220, 33%, 98%)',
      300: 'hsl(220, 30%, 96%)',
      400: 'hsl(220, 29%, 94%)',
      500: 'hsl(222, 32%, 92%)',
      600: 'hsl(224, 29%, 90%)',
      700: 'hsl(221, 31%, 88%)',
      800: 'hsl(222, 30%, 85%)',
      900: 'hsl(222, 29%, 84%)',
    },
    disabledBackground: {
      100: 'hsla(221, 13%, 33%, 0.1)',
      200: 'hsla(223, 12%, 29%, 0.8)',
      300: 'hsla(221, 12%, 26%, 0.8)',
      400: 'hsla(223, 12%, 23%, 0.8)',
      500: 'hsla(225, 12%, 20%, 0.8)',
      600: 'hsla(227, 10%, 17%, 0.8)',
      700: 'hsla(220,  9%, 13%, 0.8)',
      800: 'hsla(228, 10%, 10%, 0.8)',
      900: 'hsla(240,  8%,  5%, 0.8)',
    },
    primary: {
      100: 'hsl(215, 100%, 93%)',
      200: 'hsl(215,  94%, 81%)',
      300: 'hsl(215,  84%, 71%)',
      400: 'hsl(215,  74%, 61%)',
      500: 'hsl(215,  64%, 51%)',
      600: 'hsl(215,  60%, 41%)',
      700: 'hsl(215,  53%, 23%)',
      800: 'hsl(213,  45%, 17%)',
      900: 'hsl(213,  35%, 14%)',
    },
    confirm: {
      100: 'hsl(215, 100%, 93%)',
      200: 'hsl(215,  94%, 81%)',
      300: 'hsl(215,  84%, 71%)',
      400: 'hsl(215,  74%, 61%)',
      500: 'hsl(215,  64%, 51%)',
      600: 'hsl(215,  60%, 41%)',
      700: 'hsl(215,  53%, 23%)',
      800: 'hsl(213,  45%, 17%)',
      900: 'hsl(213,  35%, 14%)',
    },
    blue: {
      100: 'hsl(215, 100%, 93%)',
      200: 'hsl(215,  94%, 81%)',
      300: 'hsl(215,  84%, 71%)',
      400: 'hsl(215,  74%, 61%)',
      500: 'hsl(215,  64%, 51%)',
      600: 'hsl(215,  60%, 41%)',
      700: 'hsl(215,  53%, 23%)',
      800: 'hsl(213,  45%, 17%)',
      900: 'hsl(213,  35%, 14%)',
    },
    danger: {
      100: 'hsl(359, 89%, 96%)',
      200: 'hsl(359, 72%, 89%)',
      300: 'hsl(359, 70%, 73%)',
      400: 'hsl(359, 61%, 64%)',
      500: 'hsl(359, 63%, 59%)',
      600: 'hsl(359, 50%, 50%)',
      700: 'hsl(359, 54%, 39%)',
      800: 'hsl(359, 58%, 31%)',
      900: 'hsl(359, 56%, 25%)',
    },
    red: {
      100: 'hsl(359, 89%, 96%)',
      200: 'hsl(359, 72%, 89%)',
      300: 'hsl(359, 70%, 73%)',
      400: 'hsl(359, 61%, 64%)',
      500: 'hsl(359, 63%, 59%)',
      600: 'hsl(359, 50%, 50%)',
      700: 'hsl(359, 54%, 39%)',
      800: 'hsl(359, 58%, 31%)',
      900: 'hsl(359, 56%, 25%)',
    },
    success: {
      100: 'hsl(136, 100%, 97%)',
      200: 'hsl(139,  73%, 87%)',
      300: 'hsl(141,  61%, 75%)',
      400: 'hsl(143,  55%, 62%)',
      500: 'hsl(145,  46%, 51%)',
      600: 'hsl(148,  48%, 43%)',
      700: 'hsl(150,  48%, 35%)',
      800: 'hsl(152,  45%, 28%)',
      900: 'hsl(152,  42%, 23%)',
    },
    green: {
      100: 'hsl(136, 100%, 97%)',
      200: 'hsl(139,  73%, 87%)',
      300: 'hsl(141,  61%, 75%)',
      400: 'hsl(143,  55%, 62%)',
      500: 'hsl(145,  46%, 51%)',
      600: 'hsl(148,  48%, 43%)',
      700: 'hsl(150,  48%, 35%)',
      800: 'hsl(152,  45%, 28%)',
      900: 'hsl(152,  42%, 23%)',
    },
    info: {
      100: 'hsl(60, 100%, 97%)',
      200: 'hsl(58,  97%, 87%)',
      300: 'hsl(55,  91%, 76%)',
      400: 'hsl(51,  89%, 67%)',
      500: 'hsl(47,  81%, 61%)',
      600: 'hsl(40,  67%, 51%)',
      700: 'hsl(36,  71%, 42%)',
      800: 'hsl(32,  75%, 34%)',
      900: 'hsl(30,  74%, 26%)',
    },
    yellow: {
      100: 'hsl(60, 100%, 97%)',
      200: 'hsl(58,  97%, 87%)',
      300: 'hsl(55,  91%, 76%)',
      400: 'hsl(51,  89%, 67%)',
      500: 'hsl(47,  81%, 61%)',
      600: 'hsl(40,  67%, 51%)',
      700: 'hsl(36,  71%, 42%)',
      800: 'hsl(32,  75%, 34%)',
      900: 'hsl(30,  74%, 26%)',
    },
    neutral: {
      100: 'hsl(205, 25%, 96%)',
      200: 'hsl(210, 25%, 92%)',
      300: 'hsl(211, 24%, 88%)',
      400: 'hsl(211, 22%, 82%)',
      500: 'hsl(210, 18%, 65%)',
      600: 'hsl(208, 15%, 54%)',
      700: 'hsl(209, 16%, 35%)',
      800: 'hsl(210, 16%, 26%)',
      900: 'hsl(209, 28%, 15%)',
    },
    grey: {
      100: 'hsl(205, 25%, 96%)',
      200: 'hsl(210, 25%, 92%)',
      300: 'hsl(211, 24%, 88%)',
      400: 'hsl(211, 22%, 82%)',
      500: 'hsl(210, 18%, 65%)',
      600: 'hsl(208, 15%, 54%)',
      700: 'hsl(209, 16%, 35%)',
      800: 'hsl(210, 16%, 26%)',
      900: 'hsl(209, 28%, 15%)',
    },
    secondary: {
      100: 'hsl(306, 100%, 94%)',
      200: 'hsl(307,  95%, 84%)',
      300: 'hsl(307,  90%, 72%)',
      400: 'hsl(307,  78%, 60%)',
      500: 'hsl(307,  91%, 43%)',
      600: 'hsl(307,  95%, 36%)',
      700: 'hsl(307,  87%, 30%)',
      800: 'hsl(307,  75%, 25%)',
      900: 'hsl(307,  64%, 21%)',
    },
    purple: {
      100: 'hsl(306, 100%, 94%)',
      200: 'hsl(307,  95%, 84%)',
      300: 'hsl(307,  90%, 72%)',
      400: 'hsl(307,  78%, 60%)',
      500: 'hsl(307,  91%, 43%)',
      600: 'hsl(307,  95%, 36%)',
      700: 'hsl(307,  87%, 30%)',
      800: 'hsl(307,  75%, 25%)',
      900: 'hsl(307,  64%, 21%)',
    },
    warning: {
      100: 'hsl(26, 100%, 90%)',
      200: 'hsl(26,  95%, 80%)',
      300: 'hsl(26,  96%, 70%)',
      400: 'hsl(26,  98%, 60%)',
      500: 'hsl(26, 100%, 50%)',
      600: 'hsl(26, 100%, 42%)',
      700: 'hsl(26, 100%, 34%)',
      800: 'hsl(26, 100%, 27%)',
      900: 'hsl(26, 100%, 20%)',
    },
    orange: {
      100: 'hsl(26, 100%, 90%)',
      200: 'hsl(26,  95%, 80%)',
      300: 'hsl(26,  96%, 70%)',
      400: 'hsl(26,  98%, 60%)',
      500: 'hsl(26, 100%, 50%)',
      600: 'hsl(26, 100%, 42%)',
      700: 'hsl(26, 100%, 34%)',
      800: 'hsl(26, 100%, 27%)',
      900: 'hsl(26, 100%, 20%)',
    },
  },
  dark: {
    background: {
      100: 'hsl(221, 13%, 33%)',
      200: 'hsl(223, 12%, 29%)',
      300: 'hsl(221, 12%, 26%)',
      400: 'hsl(223, 12%, 23%)',
      500: 'hsl(225, 12%, 20%)',
      600: 'hsl(227, 10%, 17%)',
      700: 'hsl(220,  9%, 13%)',
      800: 'hsl(228, 10%, 10%)',
      900: 'hsl(240,  8%,  5%)',
    },
    disabledBackground: {
      100: 'hsla(221, 13%, 33%, 0.1)',
      200: 'hsla(223, 12%, 29%, 0.8)',
      300: 'hsla(221, 12%, 26%, 0.8)',
      400: 'hsla(223, 12%, 23%, 0.8)',
      500: 'hsla(225, 12%, 20%, 0.8)',
      600: 'hsla(227, 10%, 17%, 0.8)',
      700: 'hsla(220,  9%, 13%, 0.8)',
      800: 'hsla(228, 10%, 10%, 0.8)',
      900: 'hsla(240,  8%,  5%, 0.8)',
    },
    primary: {
      100: 'hsl(215, 100%, 93%)',
      200: 'hsl(215,  94%, 81%)',
      300: 'hsl(215,  84%, 71%)',
      400: 'hsl(215,  74%, 61%)',
      500: 'hsl(215,  64%, 51%)',
      600: 'hsl(215,  60%, 41%)',
      700: 'hsl(215,  53%, 23%)',
      800: 'hsl(213,  45%, 17%)',
      900: 'hsl(213,  35%, 14%)',
    },
    confirm: {
      100: 'hsl(215, 100%, 93%)',
      200: 'hsl(215,  94%, 81%)',
      300: 'hsl(215,  84%, 71%)',
      400: 'hsl(215,  74%, 61%)',
      500: 'hsl(215,  64%, 51%)',
      600: 'hsl(215,  60%, 41%)',
      700: 'hsl(215,  53%, 23%)',
      800: 'hsl(213,  45%, 17%)',
      900: 'hsl(213,  35%, 14%)',
    },
    blue: {
      100: 'hsl(215, 100%, 93%)',
      200: 'hsl(215,  94%, 81%)',
      300: 'hsl(215,  84%, 71%)',
      400: 'hsl(215,  74%, 61%)',
      500: 'hsl(215,  64%, 51%)',
      600: 'hsl(215,  60%, 41%)',
      700: 'hsl(215,  53%, 23%)',
      800: 'hsl(213,  45%, 17%)',
      900: 'hsl(213,  35%, 14%)',
    },
    danger: {
      100: 'hsl(359, 100%, 96%)',
      200: 'hsl(359, 85%, 89%)',
      300: 'hsl(359, 70%, 73%)',
      400: 'hsl(359, 61%, 64%)',
      500: 'hsl(359, 58%, 59%)',
      600: 'hsl(359, 52%, 50%)',
      700: 'hsl(359, 49%, 39%)',
      800: 'hsl(359, 45%, 27%)',
      900: 'hsl(359, 30%, 14%)',
    },
    red: {
      100: 'hsl(359, 100%, 96%)',
      200: 'hsl(359, 85%, 89%)',
      300: 'hsl(359, 70%, 73%)',
      400: 'hsl(359, 61%, 64%)',
      500: 'hsl(359, 58%, 59%)',
      600: 'hsl(359, 52%, 50%)',
      700: 'hsl(359, 49%, 39%)',
      800: 'hsl(359, 45%, 27%)',
      900: 'hsl(359, 30%, 14%)',
    },
    success: {
      100: 'hsl(136, 100%, 97%)',
      200: 'hsl(139,  73%, 87%)',
      300: 'hsl(141,  61%, 75%)',
      400: 'hsl(143,  55%, 62%)',
      500: 'hsl(145,  46%, 51%)',
      600: 'hsl(148,  48%, 43%)',
      700: 'hsl(150,  48%, 35%)',
      800: 'hsl(152,  45%, 28%)',
      900: 'hsl(152,  42%, 23%)',
    },
    green: {
      100: 'hsl(136, 100%, 97%)',
      200: 'hsl(139,  73%, 87%)',
      300: 'hsl(141,  61%, 75%)',
      400: 'hsl(143,  55%, 62%)',
      500: 'hsl(145,  46%, 51%)',
      600: 'hsl(148,  48%, 43%)',
      700: 'hsl(150,  48%, 35%)',
      800: 'hsl(152,  45%, 28%)',
      900: 'hsl(152,  42%, 23%)',
    },
    info: {
      100: 'hsl(60, 100%, 97%)',
      200: 'hsl(58,  97%, 87%)',
      300: 'hsl(55,  91%, 76%)',
      400: 'hsl(51,  89%, 67%)',
      500: 'hsl(47,  81%, 61%)',
      600: 'hsl(40,  67%, 51%)',
      700: 'hsl(36,  71%, 42%)',
      800: 'hsl(32,  75%, 34%)',
      900: 'hsl(30,  74%, 26%)',
    },
    yellow: {
      100: 'hsl(60, 100%, 97%)',
      200: 'hsl(58,  97%, 87%)',
      300: 'hsl(55,  91%, 76%)',
      400: 'hsl(51,  89%, 67%)',
      500: 'hsl(47,  81%, 61%)',
      600: 'hsl(40,  67%, 51%)',
      700: 'hsl(36,  71%, 42%)',
      800: 'hsl(32,  75%, 34%)',
      900: 'hsl(30,  74%, 26%)',
    },
    neutral: {
      100: 'hsl(205, 25%, 96%)',
      200: 'hsl(210, 25%, 92%)',
      300: 'hsl(211, 24%, 88%)',
      400: 'hsl(211, 22%, 82%)',
      500: 'hsl(210, 18%, 65%)',
      600: 'hsl(208, 15%, 54%)',
      700: 'hsl(209, 16%, 35%)',
      800: 'hsl(210, 16%, 26%)',
      900: 'hsl(209, 28%, 15%)',
    },
    grey: {
      100: 'hsl(205, 25%, 96%)',
      200: 'hsl(210, 25%, 92%)',
      300: 'hsl(211, 24%, 88%)',
      400: 'hsl(211, 22%, 82%)',
      500: 'hsl(210, 18%, 65%)',
      600: 'hsl(208, 15%, 54%)',
      700: 'hsl(209, 16%, 35%)',
      800: 'hsl(210, 16%, 26%)',
      900: 'hsl(209, 28%, 15%)',
    },
    secondary: {
      100: 'hsl(306, 100%, 94%)',
      200: 'hsl(307,  95%, 84%)',
      300: 'hsl(307,  90%, 72%)',
      400: 'hsl(307,  78%, 60%)',
      500: 'hsl(307,  91%, 43%)',
      600: 'hsl(307,  95%, 36%)',
      700: 'hsl(307,  87%, 30%)',
      800: 'hsl(307,  75%, 25%)',
      900: 'hsl(307,  64%, 21%)',
    },
    purple: {
      100: 'hsl(306, 100%, 94%)',
      200: 'hsl(307,  95%, 84%)',
      300: 'hsl(307,  90%, 72%)',
      400: 'hsl(307,  78%, 60%)',
      500: 'hsl(307,  91%, 43%)',
      600: 'hsl(307,  95%, 36%)',
      700: 'hsl(307,  87%, 30%)',
      800: 'hsl(307,  75%, 25%)',
      900: 'hsl(307,  64%, 21%)',
    },
    warning: {
      100: 'hsl(26, 100%, 90%)',
      200: 'hsl(26,  95%, 80%)',
      300: 'hsl(26,  96%, 70%)',
      400: 'hsl(26,  98%, 60%)',
      500: 'hsl(26, 100%, 50%)',
      600: 'hsl(26, 100%, 42%)',
      700: 'hsl(26, 100%, 34%)',
      800: 'hsl(26, 100%, 27%)',
      900: 'hsl(26, 100%, 20%)',
    },
    orange: {
      100: 'hsl(26, 100%, 90%)',
      200: 'hsl(26,  95%, 80%)',
      300: 'hsl(26,  96%, 70%)',
      400: 'hsl(26,  98%, 60%)',
      500: 'hsl(26, 100%, 50%)',
      600: 'hsl(26, 100%, 42%)',
      700: 'hsl(26, 100%, 34%)',
      800: 'hsl(26, 100%, 27%)',
      900: 'hsl(26, 100%, 20%)',
    },
  },
};
