export const SCHEDULE_LIST_KEY = 'schedule-list-key';
export const READ_ONLY_SCHEDULE_IDS = ['always', 'never'];

export interface toHolidaySet {
  holidaySet: {
    id: string;
    name: string;
  };
  holidaySetID: string;
  scheduleBlockID: string;
};

export type ScheduleBlock = {
  id: string;
  scheduleID: string;
  date: string;
  duration: string;
  toHolidaySets: toHolidaySet[];
  weekdays: string;
};

export interface ScheduleForm {
  id?: string;
  name: string;
  placeID: string;
  description?: string;
  blocks: ScheduleBlock[];
}

export const ScheduleFormDefault: ScheduleForm = {
  id: '',
  name: '',
  description: '',
  placeID: '',
  blocks: []
};

export const SCHEDULE_INVALID_FORM = 'Please fill in all required fields.';
export const SCHEDULE_CREATE_SUCCESS = 'Successfully created schedule.';
export const SCHEDULE_UPDATE_SUCCESS = 'Successfully updated schedule.';
export const SCHEDULE_UPDATE_ERROR = 'An error occurred when trying to update the schedule.';
export const SCHEDULE_CONFIRM_DELETE = 'Are you sure you want to delete this schedule?';
export const SCHEDULE_DELETED_ERROR = 'An error occurred when trying to delete the schedule.';
export const SCHEDULE_DELETED_SUCCESS_FN = (scheduleName: string = '') =>
  `Schedule ${scheduleName ? `(${scheduleName}) ` : ''}successfully deleted.`;
export const SCHEDULE_OPERATION_READ_ONLY_FN = (operation: 'update' | 'delete') =>
  `You cannot ${operation} a Read Only schedule.`;
export const SCHEDULE_CONFIRM_PERMISSION_ACTION_FN = (count: number) =>
  `${count} Permission${count > 1 ? 's are' : ' is'} associated with this Schedule. Do you want to DELETE or RE-ASSIGN ${count > 1 ? 'these' : 'this'} Permission${count > 1 ? 's' : ''}?`;
export const SCHEDULE_CONFIRM_PERMISSION_REASSIGN_FN = (count: number) =>
  `Please select a Schedule to re-assign ${count} Permission${count > 1 ? 's' : ''}.`
export const SCHEDULE_PERMISSIONS_DELETED_FN = (count: number) =>
  `${count} Permission${count > 1 ? 's were' : ' was'} deleted.`
export const SCHEDULE_PERMISSIONS_REASSIGNED_FN = (count: number, name: string) =>
  `${count} Permission${count > 1 ? 's were' : ' was'} re-assigned to the Schedule named "${name}".`

export const defaultScheduleWeekdayRows: {
  label: string;
  value: string;
}[] = [
  {
    label: 'Sunday',
    value: 'su'
  },
  {
    label: 'Monday',
    value: 'mo'
  },
  {
    label: 'Tuesday',
    value: 'tu'
  },
  {
    label: 'Wednesday',
    value: 'we'
  },
  {
    label: 'Thursday',
    value: 'th'
  },
  {
    label: 'Friday',
    value: 'fr'
  },
  {
    label: 'Saturday',
    value: 'sa'
  }
];
