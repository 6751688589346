export const REPORT_DEFAULT_ICON = 'chart-simple';
export const REPORT_LOCALSTORAGE_KEY = 'reports-list-key';
export const REPORT_DEFAULT_DATE_RANGE_PRESET = 'past30days';

/**
 * Record request limit
 */
export const REPORT_ITEMS_PER_PAGE = [100, 250, 500, 1000];
export const REPORT_ITEMS_PER_PAGE_DEFAULT = REPORT_ITEMS_PER_PAGE[1];

/**
 * UI Language
 */
export const REPORT_MESSAGE_GET_LABEL = 'Generate Report';
export const REPORT_MESSAGE_DOWNLOAD_LABEL = 'Download';
export const REPORT_MESSAGE_ROOT_SCOPE = 'Reports cannot be generated at Root scope. Please select a different scope.';
export const REPORT_MESSAGE_SELECT_FILTERS = 'Select filters before generating the report.';
export const REPORT_MESSAGE_EMPTY_STATE = 'No data found, try changing the filters above.';
export const REPORT_MESSAGE_FAILED_TO_GET_REPORT =
  'Failed to retrieve report. If the problem persists please contact your administrator.';
export const REPORT_MESSAGE_FAILED_TO_DOWNLOAD_REPORT =
  'Failed to download report. If the problem persists please contact your administrator.';
export const REPORT_MESSAGE_SUCCESS_DOWNLOAD_REPORT = 'Attempting to download report. Please wait.';
export const REPORT_COLUMN_LABELS = {
  card: 'Card',
  serverTime: 'Time',
  time: 'Time',
  type: 'Type',
  status: 'Status',
  firstName: 'First Name',
  lastName: 'Last Name',
  name: 'Name',
  description: 'Description',
  credentials: 'Credentials',
  email: 'Email',
  department: 'Department',
  title: 'Title',
  groups: 'Groups',
  createdAt: 'Created At',
  doorName: 'Door Name',
  place: 'Place',
  deviceName: 'Device Name',
  gatewayName: 'Gateway Name',
  videoSourceName: 'Camera Name',
  videoSourceID: 'CameraID',
  lastMotionAt: 'Last Motion At',
  cloudStorageDays: 'Cloud Storage Days',
};

export const REPORT_COLUMN_FORMAT = {
  credentials: (value: string[]) => (value?.length ? value.join(', ') : value),
  groups: (value: string[]) => (value?.length ? value.join(', ') : value)
};
