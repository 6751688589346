<template>
  <FaLayer v-tooltip="tooltip">
    <FaIcon
      v-if="expirationStatus === 0"
      :icon="['fa', 'hourglass-start']"
      :class="{
        ['IconColor IconColor--' + color]: color,
      }"
    />

    <FaIcon
      v-else-if="expirationStatus === 1"
      :icon="['fa', 'hourglass']"
      :class="{
        ['IconColor IconColor--' + color]: color,
      }"
    />

    <FaIcon
      v-else-if="expirationStatus === 2"
      :icon="['fa', 'hourglass']"
      :class="{
        ['IconColor IconColor--' + color]: color,
      }"
    />
  </FaLayer>
</template>

<script setup lang="ts">
import { DAYS_TO_SHOW_EXPIRES_SOON, DAYS_TO_SHOW_EXPIRES_VERY_SOON } from '@/config/saved-clips';
import { FontAwesomeLayers as FaLayer } from '@fortawesome/vue-fontawesome'
import { fromIsoToLocal } from '@/utils/DateTime';
import { mixinFaIcons } from '@/icons/mixins';
import { daysUntil } from '@/functions/time';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  expiresAt: string,
  disabled?: boolean
}>();

const formatDate = (date: string) => {
  if (date) {
    return fromIsoToLocal(date);
  } else {
    return '-';
  }
}

const clipExpiration = computed(() => formatDate(props.expiresAt));
const daysUntilExpiration = computed(() => daysUntil(props.expiresAt));
const expirationStatus = ref(0);
const color = ref('');

watch(daysUntilExpiration, val => {
  expirationStatus.value = 0;
  if (daysUntilExpiration.value <= DAYS_TO_SHOW_EXPIRES_VERY_SOON) {
    color.value = 'orange'
    expirationStatus.value += 1;
  } else if (daysUntilExpiration.value <= DAYS_TO_SHOW_EXPIRES_SOON) {
    color.value = 'yellow'
    expirationStatus.value += 1;
  } else {
    color.value = 'green'
  }
}, { immediate: true });

const tooltip = computed(() => ({
  triggers: ['click', 'hover', 'focus'],
  content: `Expires: ${clipExpiration.value}`,
}))
</script>
