import { HOLIDAY_SET_DEFAULT_ICON } from '@/constants/holiday-set';
import { qx_ePrivilege_enum } from '@/graphql/types';
import { SCHEDULES_ROUTE } from './schedules';
import { ConfigRouteObject } from '@/types';

export const HOLIDAY_SETS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', HOLIDAY_SET_DEFAULT_ICON],
  UI_LABEL: 'Holiday Sets',
  ROUTE_PATH: 'holiday-sets',
  ROUTE_NAME: 'holiday-sets.index',
  ROUTE_PARAM: '',
  PRIVILEGES: SCHEDULES_ROUTE.PRIVILEGES
};

export const HOLIDAY_SET_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', HOLIDAY_SET_DEFAULT_ICON],
  UI_LABEL: 'Create Holiday Set',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'holiday-set.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.ScheduleConfig]
};

export const HOLIDAY_SET_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Holiday Set',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'holiday-set.edit',
  ROUTE_PARAM: 'holidaySetId',
  PRIVILEGES: [qx_ePrivilege_enum.ScheduleConfig]
};

const HOLIDAY_SET_VIEW_ROUTE_NAME_SHARED = 'holiday-set.view';

export const HOLIDAY_SET_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', HOLIDAY_SET_DEFAULT_ICON],
  UI_LABEL: 'View Holiday Set',
  ROUTE_PATH: ':holidaySetId',
  ROUTE_NAME: HOLIDAY_SET_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'holidaySetId',
  PRIVILEGES: []
};

export const HOLIDAY_SET_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: HOLIDAY_SET_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'holidaySetId',
  PRIVILEGES: []
};
