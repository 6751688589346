
import * as phoneFormatter from '@/functions/phoneNumber';

export const formatPhoneNumberByCountryCode = (phoneNumber: string, countryCode: string): string => {
  phoneNumber = phoneFormatter.stripPhoneFormatting(phoneNumber);
  switch (parseInt(countryCode)) {
    case 1:
      return phoneFormatter.formatUs(phoneNumber.toString());
    case 44:
        return phoneFormatter.formatUk(phoneNumber.toString());
    case 61:
      return phoneFormatter.formatAus(phoneNumber.toString());
    default:
      return phoneNumber;
  }
};

export const displayPhoneNumberWithCountryCode = (phoneNumber: string, countryCode: string): string => {
  if (!countryCode) return phoneNumber;
  const formattedPhoneNumber = formatPhoneNumberByCountryCode(phoneNumber, countryCode);
  return `+${countryCode} ${formattedPhoneNumber}`;
};

export const validatePhoneNumberByCountryCode = (phoneNumber: string, countryCode: string): boolean => {
  switch (countryCode) {
    case '1':
      return phoneFormatter.isUsPhoneNumber(phoneNumber);
    default:
      return phoneFormatter.isPhoneNumber(phoneNumber);
  }
};

export const formatPhoneNumberForDisplay = (phoneNumbers: string | { countryCode: string; number: string }) => {
  if (!phoneNumbers) return;

  if (typeof phoneNumbers === 'string') {
    // TODO :: handle case where phoneNumbers may be a string that contains a country code
    // https://app.shortcut.com/qumulex/story/27187/handle-case-where-phonenumbers-may-be-a-string-that-contains-a-country-code
    const phone = phoneNumbers;
    // If country code can be determined, add it
    let code = '';
    if (phoneFormatter.isUsPhoneNumber(phone)) {
      code = '1';
    }
    phoneNumbers = { countryCode: code, number: phone };
  }

  return formatPhoneNumberByCountryCode(phoneNumbers.number, phoneNumbers.countryCode);
};
