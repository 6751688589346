<template>
  <FontAwesomeLayers v-tooltip="renderedTooltip">
    <FaIcon v-bind="{...icon.icon,...attrs}" />
    <FaIcon v-bind="icon.mask" v-if="!!icon?.mask" />
  </FontAwesomeLayers>
</template>

<script setup lang="ts">
import { UNKNOWN_RULE_ACTION, ruleActionOverrides } from '@/models/rule/utils/rule-actions';
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { qx_eFlowTraceActionStatus_enum } from '@/graphql/types';
import { FlowActions } from '@/models/rule/types';
import { FAIcon, FAIconName } from "@/types";
import { computed, useAttrs } from 'vue';

const props = defineProps<{
  type?: FlowActions,
  status?: qx_eFlowTraceActionStatus_enum,
  tooltip?: string,
}>();

const attrs = useAttrs();

function getRuleTraceActionIcon(
  status?: qx_eFlowTraceActionStatus_enum,
  icon?: FAIconName | FAIcon
) {
  switch (status) {
    case qx_eFlowTraceActionStatus_enum.RateLimited:
      return {
        icon: {
          icon: 'triangle',
          mask: icon,
          transform: 'shrink-1 right-7 up-4',
        },
        mask: {
          icon: 'triangle-exclamation',
          transform: 'shrink-5 right-8 up-4',
          color: 'var(--color-info-500)'
        },
      }
    case qx_eFlowTraceActionStatus_enum.RunOnCloudOnly:
      return {
        icon: {
          icon: 'cloud',
          mask: icon,
          transform: 'shrink-4 right-5 up-3',
        },
        mask: {
          icon: 'cloud',
          transform: 'shrink-5 right-6 up-4',
          color: 'var(--color-info-500)'
        },
      }
    case qx_eFlowTraceActionStatus_enum.Suppressed:
      return {
        icon: {
          icon: 'square',
          mask: icon,
          transform: 'shrink-4 right-5 up-3',
        },
        mask: {
          icon: 'water',
          transform: 'shrink-6 right-8 up-4',
          color: 'var(--color-info-500)'
        },
      }
    default:
      return null;
  }
}

const hasActionConfig = computed(() => ruleActionOverrides?.[props?.type] || UNKNOWN_RULE_ACTION);
const defaultIcon = computed(() => hasActionConfig.value?.icon || ['fas', 'circle-question']);
const hasLayers = computed(() => getRuleTraceActionIcon(props.status, defaultIcon.value));

const icon = computed(() => {
  return hasLayers.value || {
    icon: {
      icon: defaultIcon.value,
    },
    mask: null
  }
});

const renderedTooltip = computed(() => {
  if (!hasActionConfig.value) {
    return props.tooltip ?? false;
  }
  return {
    html: true,
    content: `<strong>${hasActionConfig.value.title}</strong><br /><small>${(props.status || props.tooltip) || hasActionConfig.value.desc}</small>`
  }
});
</script>
