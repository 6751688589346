import { ROLE_DENY_ROOT_ROLE_EDIT } from '@/config/role';
import { ApolloClient } from '@/utils/ApolloClient';
import { ROOT_PLACE_ID } from '@/config/app';
import { gql } from '@apollo/client/core';

/**
 * This function throws an error if the user
 * is not allowed to edit the role.
 */
export default async function canRouteToRoleEditPage(roleID: string) {
  try {
    const cachedRoleObject = ApolloClient.readFragment({
      id: `qx_Role:${roleID}`,
      fragment: gql`
        fragment validateRoleNotRoot on qx_Role {
          id
          placeID
          place {
            id
          }
        }
      `
    });

    const placeID = cachedRoleObject?.role?.placeID || cachedRoleObject?.role?.place?.id;

    // if we have cached data, perform a check
    if (placeID && placeID === ROOT_PLACE_ID) {
      throw ROLE_DENY_ROOT_ROLE_EDIT;
    }

    // if role is not cached... fetch it
    const { data } = await ApolloClient.query({
      query: gql`
        query GQL_VALIDATE_ROLE_EDIT($id: String!) {
          role(id: $id) {
            id
            placeID
          }
        }
      `,
      variables: { id: roleID }
    });

    if (data?.role?.placeID === ROOT_PLACE_ID) {
      throw ROLE_DENY_ROOT_ROLE_EDIT;
    }

    return;

  } catch (error) {
    throw error;
  }
}
