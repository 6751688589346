import { qx_eDoorAlarm_enum, qx_eDoorMode_enum, qx_eDoorState_enum } from '@/graphql/types';
import { FaIconComplex } from '@/types';

export const DOOR_DEFAULT_ICON = 'door-open';
export const READER_DEFAULT_ICON = 'reader';
export const LOCK_DEFAULT_ICON = 'lock-keyhole';
export const UNLOCK_DEFAULT_ICON = 'lock-keyhole-open';

/**
 * Returns icon settings for the given
 * door alarm and state statuses.
 */
export const getDoorStatusIcon = (
  alarm: qx_eDoorAlarm_enum,
  state: qx_eDoorState_enum,
  parentDisconnected: boolean = false,
  disabledByLicense: boolean = false,
  hasMonitor: boolean = true,
  enabled: boolean = true
): FaIconComplex => {
  // Disabled
  if (!enabled) {
    return {
      tooltip: 'Disabled',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'door-disabled'
    };
  }

  // Parent (Gateway or Device) not connected
  if (parentDisconnected) {
    return {
      tooltip: 'Unknown',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'door-disabled'
    };
  }

  /**
   * Disabled by License has a custom icon
   */
  if (disabledByLicense) {
    return {
      tooltip: 'Disabled by License',
      color: 'grey',
      iconStyle: 'fas',
      icon: 'door-disabled'
    };
  }

  // if door...LockJammed
  if (alarm === qx_eDoorAlarm_enum.LockJammed) {
    return {
      tooltip: 'Lock Jammed',
      color: 'red',
      iconStyle: 'fas',
      icon: 'door-jammed'
    };
  }

  // if door...Tampering
  if (alarm === qx_eDoorAlarm_enum.Tampering) {
    return {
      tooltip: 'Tampering',
      color: 'red',
      iconStyle: 'fas',
      icon: 'door-tamper'
    };
  }

  // if door...RadioDisturbance
  if (alarm === qx_eDoorAlarm_enum.RadioDisturbance) {
    return {
      tooltip: 'Radio Disturbance',
      color: 'red',
      iconStyle: 'fas',
      icon: 'door-radio-disturbance'
    };
  }

  // if door...BatteryAlarm
  if (alarm === qx_eDoorAlarm_enum.BatteryAlarm) {
    return {
      tooltip: 'Battery is low',
      color: 'yellow',
      iconStyle: 'fas',
      icon: 'door-battery-alarm'
    };
  }

  // if door open too long (held open warning)
  if (alarm === qx_eDoorAlarm_enum.DoorOpenTooLongWarn) {
    return {
      tooltip: 'Held Open Warning',
      color: 'yellow',
      iconStyle: 'fas',
      icon: 'door-held-open'
    };
  }

  // if door open too long (held open)
  if (alarm === qx_eDoorAlarm_enum.DoorOpenTooLong) {
    return {
      tooltip: 'Held Open',
      color: 'red',
      iconStyle: 'fas',
      icon: 'door-held-open'
    };
  }

  // if door forced open
  if (alarm === qx_eDoorAlarm_enum.DoorForcedOpen) {
    return {
      tooltip: 'Forced Open',
      color: 'red',
      iconStyle: 'fas',
      icon: 'door-forced-open'
    };
  }

  if (alarm === qx_eDoorAlarm_enum.PowerFault) {
    return {
      tooltip: 'Power Fault',
      color: 'grey',
      iconStyle: 'fas',
      icon: DOOR_DEFAULT_ICON,
      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subColor: 'orange',
      subIcon: 'triangle-exclamation',
      subTransform: 'shrink-2'
    };
  }

  // if closed
  if (state === qx_eDoorState_enum.Closed) {
    return {
      tooltip: 'Closed',
      color: 'green',
      iconStyle: 'fas',
      icon: 'door-closed'
    };
  }

  // if open
  if (state === qx_eDoorState_enum.Open) {
    return {
      tooltip: 'Open',
      color: 'blue',
      iconStyle: 'fas',
      icon: DOOR_DEFAULT_ICON
    };
  }

  // if fault
  if (state === qx_eDoorState_enum.Fault) {
    return {
      tooltip: 'Fault',
      color: 'grey',
      iconStyle: 'fas',
      icon: DOOR_DEFAULT_ICON,
      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subColor: 'orange',
      subIcon: 'triangle-exclamation',
      subTransform: 'shrink-2'
    };
  }

  // if there is no monitor, display a custom icon
  if (!hasMonitor) {
    return {
      tooltip: 'Contact: None',
      color: 'black',
      iconStyle: 'fas',
      icon: 'door-closed',
      // mask: 'triangle',
      // transform: 'grow-2',
      opacity: '1'
      // subColor: 'grey',
      // subIcon: 'question',
      // subMask: 'triangle',
      // subTransform: 'shrink-7 down-2',
      // subStyle: {
      //   transform: 'scale(0.85) translateX(0.5px) !important'
      // }
    };
  }

  // if unknown
  if (state === qx_eDoorState_enum.Unknown) {
    return {
      tooltip: 'Unknown',
      color: 'grey',
      iconStyle: 'fas',
      icon: DOOR_DEFAULT_ICON,
      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subColor: 'orange',
      subIcon: 'question',
      subMask: 'triangle',
      subTransform: 'shrink-7 down-2',
      subStyle: {
        transform: 'scale(0.85) translateX(0.5px) !important'
      }
    };
  }

  // if not supported
  if (state === qx_eDoorState_enum.NotSupported) {
    return {
      tooltip: 'Not Supported',
      color: 'grey',
      iconStyle: 'fas',
      icon: DOOR_DEFAULT_ICON,

      mask: 'triangle',
      transform: 'grow-2',
      opacity: '1',
      subColor: 'red',
      subIcon: 'xmark',
      subMask: 'triangle',
      subTransform: 'shrink-7 down-2',
      subStyle: {
        transform: 'scale(0.9) translateX(1px) !important'
      }
    };
  }

  // if door alarm is back to normal and there are no other statuses to show...
  if (alarm === qx_eDoorAlarm_enum.Normal) {
    return {
      tooltip: 'Normal',
      color: 'green',
      iconStyle: 'fas',
      icon: 'door-closed'
    };
  }

  // if status is not found, display a generic icon
  return {
    tooltip: false,
    color: 'grey',
    iconStyle: 'fas',
    icon: DOOR_DEFAULT_ICON,

    mask: 'triangle',
    transform: 'grow-2',
    opacity: '1',
    subIcon: 'triangle-exclamation',
    subColor: 'grey',
    subTransform: 'shrink-2'
  };
};

export const getLockStatus = (
  status: qx_eDoorMode_enum,
  parentDisconnected: boolean = false,
  enabled: boolean = true
) => {

  // Parent (Gateway or Device) not connected
  if (parentDisconnected) {
    return {
      tooltip: 'Unknown',
      color: 'grey',
      iconStyle: 'fas',
      icon: LOCK_DEFAULT_ICON
    };
  }

  // Disabled
  if (!enabled) {
    return {
      tooltip: 'Disabled',
      color: 'grey',
      iconStyle: 'fas',
      icon: LOCK_DEFAULT_ICON
    };
  }

  return LOCK_STATUS[status]
}

const LOCK_STATUS = {
  // Locked
  [qx_eDoorMode_enum.Locked]: {
    tooltip: 'Locked',
    color: 'green',
    iconStyle: 'fas',
    icon: LOCK_DEFAULT_ICON
  },
  // Double Locked
  [qx_eDoorMode_enum.DoubleLocked]: {
    tooltip: 'Double Locked',
    color: 'green',
    iconStyle: 'fas',
    icon: LOCK_DEFAULT_ICON
  },
  // TODO :: Overridden Locked (not implemented yet)
  // [qx_eDoorMode_enum.]: {
  //   tooltip: 'Overridden Locked',
  //   color: 'yellow',
  //   iconStyle: 'fas',
  //   icon: LOCK_DEFAULT_ICON,
  // },
  // Lockdown
  [qx_eDoorMode_enum.LockedDown]: {
    tooltip: 'Locked Down',
    color: 'red',
    iconStyle: 'fas',
    icon: LOCK_DEFAULT_ICON
  },

  // Unlocked
  [qx_eDoorMode_enum.Unlocked]: {
    tooltip: 'Unlocked',
    color: 'blue',
    iconStyle: 'fas',
    icon: UNLOCK_DEFAULT_ICON
  },

  [qx_eDoorMode_enum.Accessed]: {
    tooltip: 'Accessed',
    color: 'blue',
    iconStyle: 'fas',
    icon: UNLOCK_DEFAULT_ICON
  },

  // Something is blocking the door, yet is treated as a Door Mode event... not entirely sure the context of what causes this status
  [qx_eDoorMode_enum.Blocked]: {
    tooltip: 'Blocked',
    color: 'yellow',
    iconStyle: 'fas',
    icon: LOCK_DEFAULT_ICON
  },

  // Lock is still locked, but Door is open...
  [qx_eDoorMode_enum.LockedOpen]: {
    tooltip: 'Locked Open',
    color: 'yellow',
    iconStyle: 'fas',
    icon: LOCK_DEFAULT_ICON
  },

  /**
   * TODO :: rethink
   * understand that this is in a different state and the
   * user needs to go back and "revert to schedule"
   */
  [qx_eDoorMode_enum.OverriddenUnlocked]: {
    tooltip: 'Overridden Unlocked',
    color: 'yellow',
    iconStyle: 'fas',
    icon: UNLOCK_DEFAULT_ICON
  },

  /**
   * TODO :: rethink
   * understand that this is in a different state and the
   * user needs to go back and "revert to schedule"
   */
  [qx_eDoorMode_enum.OverriddenLocked]: {
    tooltip: 'Overridden Locked',
    color: 'yellow',
    iconStyle: 'fas',
    icon: LOCK_DEFAULT_ICON
  },

  //
  [qx_eDoorMode_enum.LockdownDoorOpen]: {
    tooltip: 'Lockdown Door Open',
    color: 'red',
    iconStyle: 'fas',
    icon: LOCK_DEFAULT_ICON
  },
  [qx_eDoorMode_enum.Unknown]: {
    tooltip: 'Unknown',
    color: 'grey',
    iconStyle: 'fas',
    icon: UNLOCK_DEFAULT_ICON,
    mask: 'triangle',
    transform: 'grow-2',
    opacity: '1',
    subColor: 'orange',
    subIcon: 'question',
    subMask: 'triangle',
    subTransform: 'shrink-7 down-2',
    subStyle: {
      transform: 'scale(0.9) translateX(0) !important'
    }
  }

  // status: null, type: AccessTaken
  // status: null, type: AccessGranted
};

// create a meeting to talk about lockdown updates across the app (josh, dan, patrick, me, jeffrey)
// see about patrick wrapping-up the combined Camera/Door widget for live
