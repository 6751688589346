import { ref } from "vue";

export function useAppScopeButton() {
  const elementRef = ref(null);
  const show = () => elementRef.value?.show?.();
  const hide = () => elementRef.value?.hide?.();

  return {
    elementRef,
    hide,
    show
  }
}
