<template>
  <FaIcon :icon="['fas', HOLIDAY_SET_DEFAULT_ICON]" />
</template>

<script lang="ts">
import { HOLIDAY_SET_DEFAULT_ICON } from '@/constants/holiday-set'

export default {
  data: () => ({
    HOLIDAY_SET_DEFAULT_ICON
  })
}
</script>
