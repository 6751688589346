import { usePermission } from '@vueuse/core';
import { reactive } from 'vue';

export function useDevicePermissions() {
  const accelerometer = reactive(usePermission('accelerometer', { controls: true }));
  const accessibilityEvents = reactive(usePermission('accessibility-events', { controls: true }));
  const ambientLightSensor = reactive(usePermission('ambient-light-sensor', { controls: true }));
  const backgroundSync = reactive(usePermission('background-sync', { controls: true }));
  const camera = reactive(usePermission('camera', { controls: true }));
  const clipboardRead = reactive(usePermission('clipboard-read', { controls: true }));
  const clipboardWrite = reactive(usePermission('clipboard-write', { controls: true }));
  const gyroscope = reactive(usePermission('gyroscope', { controls: true }));
  const magnetometer = reactive(usePermission('magnetometer', { controls: true }));
  const microphone = reactive(usePermission('microphone', { controls: true }));
  const notifications = reactive(usePermission('notifications', { controls: true }));
  // const paymentHandler = reactive(usePermission('payment-handler', { controls: true }));
  const persistentStorage = reactive(usePermission('persistent-storage', { controls: true }));
  const push = reactive(usePermission('push', { controls: true }));
  const speaker = reactive(usePermission('speaker', { controls: true }));

  return {
    _icons: {
      accelerometer: 'signal-stream',
      accessibilityEvents: '',
      ambientLightSensor: '',
      backgroundSync: 'rotate',
      camera: 'camera',
      clipboardRead: 'clipboard',
      clipboardWrite: 'clipboard',
      gyroscope: 'solar-system',
      magnetometer: 'magnet',
      microphone: 'microphone',
      notifications: 'envelope',
      paymentHandler: '',
      persistentStorage: 'database',
      push: 'message-lines',
      speaker: 'volume',
    },
    accelerometer,
    accessibilityEvents,
    ambientLightSensor,
    backgroundSync,
    camera,
    clipboardRead,
    clipboardWrite,
    gyroscope,
    magnetometer,
    microphone,
    notifications,
    // paymentHandler,
    persistentStorage,
    push,
    speaker,
  }
}
