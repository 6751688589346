import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const BADGE_PRINT_ROUTE = Object.freeze<ConfigRouteObject>({
  UI_ICON: ['fas', 'user'],
  UI_LABEL: 'Print Badge',
  ROUTE_PATH: 'print-badge/:personId',
  ROUTE_NAME: 'badge.print',
  ROUTE_PARAM: 'personId',
  PRIVILEGES: [qx_ePrivilege_enum.PeopleConfig]
});
