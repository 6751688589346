/* http://emailregex.com/ */
const EMAIL_VALIDATION_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

function validateEmail(email: string): boolean {
  email = email?.toLowerCase();

  if (!EMAIL_VALIDATION_REGEX.test(email)) return false;

  if (!/^[a-zA-Z0-9]/.test(email) || !/[a-zA-Z0-9]$/.test(email)) return false;
  if (/[~`@#$%^&*()_"'=<,>.?\-\+\{\}\[\]\|\\\:\;\/]{2,}/.test(email)) return false;

  if (email.includes(' ')) return false;

  const atMatch = email.match(/@/g);
  if (atMatch && atMatch.length > 1) return false;

  return true;
}

export { EMAIL_VALIDATION_REGEX, validateEmail };
