import { AUTH_LOGIN_ROUTE } from '@/config/routes/auth';
import { useAuthStore } from '@/store/auth';

const UserIsAuthenticated: Middleware = async function(ctx) {
  const auth = useAuthStore();
  if (!(await auth.account.authenticate())) {
    console.warn('User is not authenticated. Redirecting to login.')
    // disable any in-progress reattempts
    auth.account.attempts = -1;
    return { name: AUTH_LOGIN_ROUTE.ROUTE_NAME, query: { redirect: ctx.to.fullPath } };
  }
}

export default UserIsAuthenticated;
