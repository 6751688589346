import { CanManageRulesMiddleware } from '@/models/rule/utils/can-manage-rules';
import {
  RULE_VIEW_INFO_ROUTE,
  RULE_CREATE_ROUTE,
  RULE_TRACE_ROUTE,
  RULE_EDIT_ROUTE,
  RULE_VIEW_ROUTE,
  RULES_ROUTE
} from '@/config/routes/rules';

export default [
  /**
   * CONTAINER - All Rule Routes will pass through this container
   *  This container is responsible for handling any global Rule-related fetching.
   */
  {
    path: RULES_ROUTE.ROUTE_PATH,
    components: {
      default: () => import('./RuleContainer.vue')
    },
    meta: {
      privileges: RULES_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * Create Rule
       */
      {
        path: RULE_CREATE_ROUTE.ROUTE_PATH,
        name: RULE_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: RULE_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true,
          middleware: [
            CanManageRulesMiddleware
          ]
        },
        components: {
          default: () => import('./RuleCreatePage.vue')
        }
      },

      /**
       * List of Rules
       */
      {
        path: '',
        name: RULES_ROUTE.ROUTE_NAME,
        // meta: {},
        components: {
          default: () => import('./RuleListPage.vue')
        }
      },

      /**
       * CONTAINER - View Rule Routes will pass through this container
       *  Parent Route is a container to fetch the Rule object
       *  Child routes (includes view page) will reference the Rule object pulled in the container
       *  This container will hold some generic/global elements for all Rule View Pages
       */
      {
        path: RULE_VIEW_ROUTE.ROUTE_PATH,
        // name: RULE_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: RULE_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import('./RuleContainerView.vue')
        },
        children: [
          {
            path: RULE_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: RULE_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: RULE_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import('./RuleViewInfoPage.vue')
            }
          },
          {
            path: RULE_TRACE_ROUTE.ROUTE_PATH,
            name: RULE_TRACE_ROUTE.ROUTE_NAME,
            meta: {
              privileges: RULE_TRACE_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import('./RuleTracePage.vue')
            }
          },
          {
            path: RULE_EDIT_ROUTE.ROUTE_PATH,
            name: RULE_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: RULE_EDIT_ROUTE.PRIVILEGES,
            },
            components: {
              default: () => import('./RuleEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];
