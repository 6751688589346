export type { ToastOptions as NotifyOptions } from "vue-toastification/dist/types/types";
import { defineStore, acceptHMRUpdate } from 'pinia';
import { useToast } from "vue-toastification";

export const useNotifyStore = defineStore('notifications', () => {

  const toast = useToast();

  return {
    toast,
    // notifications: toast,
    clear: toast.clear,
    dismiss: toast.dismiss,
    update: toast.update,
    success: toast.success,
    info: toast.info,
    error: toast.error,
    warning: toast.warning,
  };
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotifyStore, import.meta.hot))
}
