import { qx_ePrivilege_enum } from '@/graphql/types';
import { DEVICE_DEFAULT_ICON } from '@/constants/device';
import { ConfigRouteObject } from '@/types';

export const DEVICES_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', DEVICE_DEFAULT_ICON],
  UI_LABEL: 'Devices',
  ROUTE_PATH: 'devices',
  ROUTE_NAME: 'devices.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.DeviceView]
};

export const DEVICE_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', DEVICE_DEFAULT_ICON],
  UI_LABEL: 'Create Device',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'device.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.DeviceConfig]
};

export const DEVICE_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'edit'],
  UI_LABEL: 'Edit Device',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'device.edit',
  ROUTE_PARAM: 'deviceId',
  PRIVILEGES: [qx_ePrivilege_enum.DeviceConfig]
};

// name used between two route names
const DEVICE_VIEW_ROUTE_NAME_SHARED = 'device.view';

// this route does not register it's ROUTE_NAME because it would duplicate the info route
export const DEVICE_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', DEVICE_DEFAULT_ICON],
  UI_LABEL: 'View Device',
  ROUTE_PATH: ':deviceId',
  ROUTE_NAME: DEVICE_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'deviceId',
  PRIVILEGES: [qx_ePrivilege_enum.DeviceView]
};

export const DEVICE_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'info-circle'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: DEVICE_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'deviceId',
  PRIVILEGES: [qx_ePrivilege_enum.DeviceView]
};

export const DEVICE_VIEW_WIRING_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'network-wired'],
  UI_LABEL: 'Wiring Info',
  ROUTE_PATH: 'wiring',
  ROUTE_NAME: 'device.wiring',
  ROUTE_PARAM: 'deviceId',
  PRIVILEGES: [qx_ePrivilege_enum.DeviceConfig]
};
