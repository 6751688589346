<template>
  <FaIcon
    v-tooltip="tooltip"
    :class="`IconColor IconColor--${selectedIcon.color}`"
    :icon="['fas', selectedIcon.icon]"
  />
</template>

<script lang="ts">
import { getBatteryIconStatus } from '@/constants/battery';
import { mixinStatusProps } from '@/icons/mixins';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    ...mixinStatusProps,
    parentDisconnected: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  computed: {
    selectedIcon() {
      return getBatteryIconStatus(this.status, this.parentDisconnected, !this.disabled)
    },
    tooltip() {
      if (!this.selectedIcon?.tooltip) return false
      return {
        triggers: ['click', 'hover', 'focus'],
        content: `Battery: ${this.selectedIcon.tooltip}`,
      }
    }
  }
});
</script>
