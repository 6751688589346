import { qx_ePrivilege_enum } from '@/graphql/types';

/**
 * Accepts a Privilege String and returns a
 * boolean determining whether or not the
 * User has the Privilege.
 */
export const hasPrivilege = (
  privilege: qx_ePrivilege_enum,
  existingPrivileges: qx_ePrivilege_enum[]
): boolean => {
  if (!privilege) return true;
  return existingPrivileges?.includes?.(privilege);
};

/**
 * Has at least 1 of the privileges.
 */
export const hasPrivileges = (
  privileges: qx_ePrivilege_enum[],
  existingPrivileges: qx_ePrivilege_enum[]
): boolean => {
  if (!privileges.length) return true;
  return privileges.some(priv => {
    return hasPrivilege(priv, existingPrivileges)
  })
};

/**
 * Accept an array of requirements and an array of existing privileges
 * and compare that the existing satisfies the requirements. Returns
 * a boolean.
 */
export function hasRequiredPrivileges(
  privileges: qx_ePrivilege_enum[],
  existingPrivileges: qx_ePrivilege_enum[]
): boolean {
  // return an array of privileges that were not found in `existingPrivileges`
  const missingPrivileges = privileges.filter((priv) => !existingPrivileges?.includes?.(priv));
  return !missingPrivileges.length;
}
