import { INTEGRATIONS_ROUTE } from '@/config/routes/integrations';

export default [
  {
    path: INTEGRATIONS_ROUTE.ROUTE_PATH,
    name: INTEGRATIONS_ROUTE.ROUTE_NAME,
    components: {
      default: () => import('./IntegrationsPage.vue'),
    },
    meta: {
      privileges: INTEGRATIONS_ROUTE.PRIVILEGES
    }
  }
];
