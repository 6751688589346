import { useAuthentication } from "./composables/use-authentication";
import { useUserSettings } from "./composables/use-user-settings";
import { useUserScope } from "./composables/use-user-scope";
import { defineStore, acceptHMRUpdate } from 'pinia';
import { useUser } from "./composables/use-user";
import { computed, reactive, ref, watch } from 'vue';
import { createEventHook } from "@vueuse/core";
import { Timeout } from "@/types";

/**
 * Improve DX by allowing imports of
 * relevant types from the store.
 */
export { UserSettingsName } from './composables/use-user-settings/types';
export { AuthProvider } from '@/utils/AuthClient';

/**
 * Creates out auth store to be consumed by our app
 * and injected when components use `useAuthStore`.
 */
export const useAuthStore = defineStore('auth', () => {

  const loadingMessage = ref('Authenticating...');

  // user (AppUser and Person combined)
  // - a writable computed ref to hold the current user
  // - ready once useAuth sets the email value
  // - fetches the ApplicationUser and Person record
  const user = useUser();
  watch(user.isReady, val => {
    if (val) {
      loadingMessage.value = 'Authenticated, loading settings...';
    }
  })

  // - manage all auth handling and write to user state
  const account = useAuthentication(user.data);

  // TODO :: rework this... don't rely on attempts alone... we need to understand if we are pending authentication requests... get AppUser, get UserSettings, get UserScope... we need context if they are loading
  // if the user has attempted to authenticate 3 times, wait 15s and retry
  // this can occur when auth fails due to a network issue
  const reattempt = ref<Timeout | null>(null);
  watch(account.attempts, async (count) => {
    if (count === -1) {
      account.attempts.value = 0;
      if (reattempt.value) {
        clearTimeout(reattempt.value);
        reattempt.value = null;
      };
      return;
    }
    if (count >= 3) {
      loadingMessage.value = 'Unable to authenticate, retrying in 15 seconds...';
      if (reattempt.value) {
        clearTimeout(reattempt.value);
        reattempt.value = null;
      };
      reattempt.value = setTimeout(async () => {
        loadingMessage.value = 'Retrying authentication...';
        try {
          // TODO :: need to cleanup this code, its possible this will run once more than needed
          await account.authenticate(true);
          await user.retry();
          account.attempts.value = 0;

        } catch (error) {
          account.attempts.value = count + 1;

        } finally {
          loadingMessage.value = '';
        }
      }, 15000)
    }
  });

  // settings (eventSearch, currentScope, liveLayout)
  // - user-specific config for various parts of the app
  const settings = reactive(useUserSettings(user));
  watch(() => settings.isReady, val => {
    if (val) {
      loadingMessage.value = 'Settings loaded, gathering scope information...';
    }
  })

  // scope (current / root)
  // - information about the User's root and current scopes
  const scope = reactive(useUserScope(user, settings));
  watch(() => scope.isReady, val => {
    if (val) {
      loadingMessage.value = 'Scope loaded, redirecting...';
    }
  })

  // determines if the store is ready
  const isReady = computed(() => {
    const hasUser = !!user.isReady.value;
    const isAuthenticated = account.isAuthenticated.value
    const scopeIsReady = scope.isReady
    const settingsAreReady = settings.isReady

    return hasUser
      && isAuthenticated
      && scopeIsReady
      && settingsAreReady
  })

  const onReadyHook = createEventHook<any>();

  watch(isReady, val => {
    if (val) {
      loadingMessage.value = '';
      onReadyHook.trigger(true)
    }
  })

  function waitForReady() {

    let resolver: (value: boolean) => void;

    onReadyHook.on(() => {
      resolver(true)
    })

    return new Promise((resolve, reject) => {
      resolver = resolve;
      if (isReady.value) {
        resolve(true)
      }
    })
  }

  return {
    // state
    user: user.data,
    loadingMessage,
    scope,
    settings: computed(() => settings.data),

    // auth store and all sub-stores are ready
    isReady,
    waitForReady,

    // auth actions for login/logout/etc
    account,

    // if failed to query or subscribe to the appUser, retry; typically used due to post-terms agreement acceptance
    // unable to query or subscribe to appUsers when the user has not accepted terms yet
    retry: user.retry,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
