import Toast from 'vue-toastification';
import type { App } from 'vue';

export default async (app: App) => {
  app.use(Toast, {
    duration: 3000,
    position: 'bottom-right',
    maxToasts: 4,
  })
  return;
};
