import { SCHEDULE_DEFAULT_ICON } from '@/constants/schedule';
import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

const ScheduledViewPrivileges = Object.keys(qx_ePrivilege_enum)
                                  .filter(key => {
                                    if (!key.includes('Config')) {
                                      return false;
                                    }
                                    return key !== qx_ePrivilege_enum.ViewConfig;
                                  }) as qx_ePrivilege_enum[];

export const SCHEDULES_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', SCHEDULE_DEFAULT_ICON],
  UI_LABEL: 'Schedules',
  ROUTE_PATH: 'schedules',
  ROUTE_NAME: 'schedules.index',
  ROUTE_PARAM: '',
  PRIVILEGES: ScheduledViewPrivileges
};

export const SCHEDULE_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', SCHEDULE_DEFAULT_ICON],
  UI_LABEL: 'Create Schedule',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'schedule.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.ScheduleConfig]
};

export const SCHEDULE_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Schedule',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'schedule.edit',
  ROUTE_PARAM: 'scheduleId',
  PRIVILEGES: [qx_ePrivilege_enum.ScheduleConfig]
};

const SCHEDULE_VIEW_ROUTE_NAME_SHARED = 'schedule.view';

export const SCHEDULE_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', SCHEDULE_DEFAULT_ICON],
  UI_LABEL: 'View Schedule',
  ROUTE_PATH: ':scheduleId',
  ROUTE_NAME: SCHEDULE_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'scheduleId',
  PRIVILEGES: []
};

export const SCHEDULE_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: SCHEDULE_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'scheduleId',
  PRIVILEGES: []
};
