<template>
  <FaLayer>
    <FaIcon :icon="['fas', 'octagon']" :mask="['fas', 'building']" transform="grow-6 down-5 left-9" />
    <FaIcon :icon="['fas', 'house']" transform="shrink-4 down-2 left-7" />
  </FaLayer>
</template>

<script lang="ts">
import { mixinFaIcons } from '@/icons/mixins'

export default {
  mixins: [mixinFaIcons],
}
</script>
