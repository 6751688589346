// TODO :: look into `useColorMode` from @vueuse/core
import { computed, onMounted, ref } from "vue";
import { themes } from "@/utils/Theme";

type ColorMode = 'light' | 'dark';

export function useAppTheme() {

  // current color theme
  const mode = ref<ColorMode>('dark');

  // colors for the current mode
  const colors = computed(() => themes[mode.value]);

  // available color modes
  const options = computed(() => Object.keys(themes));

  onMounted(() => {
    const value = document.documentElement.getAttribute('color-mode') as ColorMode;
    mode.value = value;
  });

  function toggleMode() {
    mode.value = mode.value === 'light' ? 'dark' : 'light';
    document.documentElement.setAttribute('color-mode', mode.value);
    localStorage.setItem('color-mode', mode.value);
  }

  return {
    colors,
    mode,
    options,
    toggleMode
  }
}
