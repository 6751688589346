<template>
  <FaLayer v-tooltip="tooltip">
    <FaIcon icon="rotate-right" transform="shrink-6 right-10 up-8" />
    <FaIcon v-bind="attrs" icon="circle" :mask="icon" transform="shrink-4 right-9 up-7" />
  </FaLayer>
</template>

<script setup lang="ts">
import { FontAwesomeLayers as FaLayer } from '@fortawesome/vue-fontawesome'
import { guardComponentMap } from '@/models/rule/utils/rule-guards';
import { FlowGuardOperator } from 'qx-flow-orm';
import { useAttrs, computed } from 'vue';

const props = defineProps<{
  type: FlowGuardOperator
}>()

const attrs = useAttrs();
const hasGuardConfig = computed(() => guardComponentMap?.[props.type]);
const icon = computed(() => hasGuardConfig.value?.icon || ['fas', 'circle-question']);

const tooltip = computed(() => {
  if (!hasGuardConfig.value) return false;
  return {
    html: true,
    content: `<small style="font-size: 10px; font-weight: semibold;">Release Only</small><br /><strong>${hasGuardConfig.value.title}</strong><br /><small>${hasGuardConfig.value.desc}</small>`
  }
});
</script>
