<template>
  <FaIcon
    v-tooltip="'test'"
    :class="{ ['IconColor IconColor--'+iconColor]: iconColor }"
    :icon="isIcon"
  />
</template>

<script setup lang="ts">
import { LOCK_DEFAULT_ICON, UNLOCK_DEFAULT_ICON } from '@/constants/door';
import { computed } from 'vue';

const props = defineProps<{ status: boolean; }>()
const isIcon = computed(() => props.status ? LOCK_DEFAULT_ICON : UNLOCK_DEFAULT_ICON);
const iconColor = computed(() => props.status ? 'danger' : 'success')
const tooltip = computed(() => props.status ? 'Locked Down' : 'Normal')
</script>
