<template>
  <FaLayer v-tooltip="tooltip">
    <FaIcon
      :class="{
        ['IconColor IconColor--'+selectedIcon.color]: selectedIcon.color,
        [selectedIcon.class]: selectedIcon.class
      }"
      :mask="selectedIcon.mask || null"
      :icon="selectedIcon.icon"
      :transform="selectedIcon.transform || null"
      :opacity="selectedIcon.opacity || null"
      :style="statusConfig?.style || null"
    />
    <FaIcon
      :class="{
        ['IconColor IconColor--'+selectedIcon.subColor]: selectedIcon.subColor,
        ['IconColor IconColor--'+selectedIcon.color]: !selectedIcon.subColor,
        [selectedIcon.subClass]: selectedIcon.subClass
      }"
      :icon="statusConfig?.subIcon"
      :mask="statusConfig?.subMask && ['fas', statusConfig?.subMask] || null"
      :transform="statusConfig?.subTransform || null"
      :opacity="statusConfig?.subOpacity || null"
      :style="statusConfig?.subStyle || null"
      v-if="statusConfig?.subIcon"
    />
  </FaLayer>
</template>

<script lang="ts">
import { getLockStatus, LOCK_DEFAULT_ICON } from '@/constants/door';
import { mixinStatusProps, mixinFaIcons } from '@/icons/mixins';
import { qx_eDoorMode_enum } from '@/graphql/types';
import { isBoolean } from '@/functions/boolean';
import { isString } from '@/functions/string';
import { isArray } from '@/functions/array';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [mixinFaIcons],
  props: {
    ...mixinStatusProps,
    parentDisconnected: {
      default: false,
      type: Boolean,
      required: false,
    },
    status: {
      required: false,
      default: undefined,
      type: [Boolean, String],
      validator: function(val) {
        if (isBoolean(val) && val) return false
        if (isBoolean(val) && !val) return true
        if (!isString(val)) return false
        if (!Object.values(qx_eDoorMode_enum).includes(val)) return false
        return true
      }
    },
  },
  computed: {
    // get information about the icon status
    statusConfig() {
      const { disabled, parentDisconnected, status } = this
      if (!status) {
        return false
      }
      return getLockStatus(status, parentDisconnected, !disabled)
    },
    // build icon details
    icon() {
      const { disabled, statusConfig } = this
      let icon = ['fas', LOCK_DEFAULT_ICON]
      if (disabled || !statusConfig) {
        return icon
      }

      icon = isArray(statusConfig?.icon) && statusConfig?.icon || ['fas', statusConfig?.icon]

      return icon
    },
    mask() {
      const { disabled, statusConfig } = this
      let mask = null
      if (disabled) {
        return mask
      }
      if (statusConfig?.mask) {
        mask = isArray(statusConfig.mask) && statusConfig.mask || ['fas', statusConfig.mask]
      }
      return mask
    },

    // define icon and tooltip
    selectedIcon() {
      const { icon, mask, statusConfig, disabled, status} = this
      const reversedIcon = statusConfig?.mask

      return {
        ...statusConfig,
        color: disabled && 'grey' || (statusConfig?.color || null),
        icon: !reversedIcon ? icon : mask,
        mask: !reversedIcon ? mask : icon,
        value: status,
      }
    },
    tooltip() {
      if (!this.selectedIcon?.tooltip) return false
      return {
        triggers: ['click', 'hover', 'focus'],
        content: `Lock: ${this.selectedIcon.tooltip}`,
      }
    }
  }
});
</script>
