import {
  VIEWS_ROUTE,
  VIEW_CREATE_ROUTE,
  VIEW_EDIT_ROUTE,
  VIEW_VIEW_ROUTE,
  VIEW_VIEW_INFO_ROUTE
} from '@/config/routes/views';

export default [
  /**
   * CONTAINER - All View Routes will pass through this container
   *  This container is responsible for handling any global View-related fetching.
   */
  {
    path: VIEWS_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "container-views" */ './ViewsContainer.vue')
    },
    meta: {
      privileges: VIEWS_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * Create View
       */
      {
        path: VIEW_CREATE_ROUTE.ROUTE_PATH,
        name: VIEW_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: VIEW_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "page-views-create" */ './ViewsCreatePage.vue')
        }
      },

      /**
       * List of Views
       */
      {
        path: '',
        name: VIEWS_ROUTE.ROUTE_NAME,
        // meta: {},
        components: {
          default: () => import(/* webpackChunkName: "page-views-list" */ './ViewsListPage.vue')
        }
      },

      /**
       * CONTAINER - View View Routes will pass through this container
       *  Parent Route is a container to fetch the View object
       *  Child routes (includes view page) will reference the View object pulled in the container
       *  This container will hold some generic/global elements for all View View Pages
       */
      {
        path: VIEW_VIEW_ROUTE.ROUTE_PATH,
        // name: VIEW_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: VIEW_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "container-views-view" */ './ViewsContainerView.vue')
        },
        children: [
          /**
           * View View - Info
           */
          {
            path: VIEW_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: VIEW_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: VIEW_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-views-view-info" */ './ViewsViewInfoPage.vue')
            }
          },

          // /**
          //  * View View - Configuration
          //  */
          // {
          //   path: VIEW_VIEW_CONFIG_ROUTE.ROUTE_PATH,
          //   name: VIEW_VIEW_CONFIG_ROUTE.ROUTE_NAME,
          //   meta: {
          //     privileges: VIEW_VIEW_CONFIG_ROUTE.PRIVILEGES
          //   },
          //   components: {
          //     default: () => import(/* webpackChunkName: "page-views-view-config" */ './ViewsViewConfigPage.vue')
          //   }
          // },

          /**
           * Edit View
           */
          {
            path: VIEW_EDIT_ROUTE.ROUTE_PATH,
            name: VIEW_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: VIEW_EDIT_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-views-edit" */ './ViewsEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];
