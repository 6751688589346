import { createApolloClient } from './create-apollo-client';
import { GqlErrorBus } from '@/events/gql-error-bus';
import { CreateApolloClientOptions } from './types';
import { EnvProvider } from '@/utils/EnvProvider';
import { AuthClient } from '../AuthClient';

/**
 * This ApolloClient can be imported into JavaScript
 * files, or it can be used within the Vue app via
 * `inject(ApolloClientDefaultKey)`.
 *
 * Provided to Vue instance via `AppContainer.vue`.
 */
async function addHeaders() {
  try {
    const token = AuthClient.getToken()
    if (!token) return {};
    return {
      'Authorization': token ? `Bearer ${token}` : '',
    }
  } catch (error) {
    throw error
  }
}

function getApolloClientConfig(): CreateApolloClientOptions {
  const config = {
    ...EnvProvider.endpoints,
    cacheKey: EnvProvider.api.url,
    addHeaders,
    addRemoteHeaders: async () => {
      try {
        const obj = await addHeaders()
        return { ...obj }

      } catch (error) {
        throw error
      }
    },
    errorHandler: (errors: any) => GqlErrorBus.emit('error', errors)
  }

  // @ts-ignore
  return config
}

/**
 * DEV NOTE :: Future upgrade.
 * When the ApolloClient utility is a separate package, the below
 * lines and the EnvProvider should be removed. These will live
 * in the client app, consuming the new package.
 */
// TODO :: making this a top-level await screws with types when using ApolloClient
export const ApolloClient = await createApolloClient(getApolloClientConfig())

export { DefaultApolloClient as ApolloClientDefaultKey } from '@vue/apollo-composable'
