import { qx_Role, qx_ePrivilege_enum, qx_ePrivilege_enum_desc } from '@/graphql/types';
import { toSentenceCase } from '@/functions/string';
import { APP_NAME } from './app';
import { CAMERA_DEFAULT_ICON } from '@/constants/camera';
import { DEVICE_DEFAULT_ICON } from '@/constants/device';
import { DOOR_DEFAULT_ICON } from '@/constants/door';
import { GATEWAY_DEFAULT_ICON } from '@/constants/gateway';
import { PERSON_DEFAULT_ICON } from '@/constants/person';
import { PLACE_DEFAULT_ICON } from '@/constants/place';
import { REPORT_DEFAULT_ICON } from './reports';
import { RULE_DEFAULT_ICON } from './rule';
import { SCHEDULE_DEFAULT_ICON } from '@/constants/schedule';
import { VIEW_DEFAULT_ICON } from './view';

export const ROLE_DEFAULT_ICON = 'role';

export const ROLE_LIST_KEY = 'role-list-key';

export type RoleFormType = Pick<qx_Role, 'name' | 'placeID' | 'description' | 'toPrivileges'> & { id: string | null };

// DEV NOTE :: Set the default Privileges for a Role here
export const RoleFormDefault: RoleFormType = {
  id: null,
  name: '',
  placeID: '',
  description: '',
  toPrivileges: [
    // @ts-ignore
    { privilege: qx_ePrivilege_enum.View }
  ],
};

export const ROLE_DENY_ROOT_ROLE_EDIT = 'Editing of Roles at the Root Place is not allowed.';
export const ROLE_INVALID_FORM = 'Please fill in all required fields.';
export const ROLE_CREATE_SUCCESS = 'Successfully created role.';
export const ROLE_CREATE_ERROR = 'An error occurred when trying to create the role.';
export const ROLE_UPDATE_SUCCESS = 'Successfully updated role.';
export const ROLE_UPDATE_ERROR = 'An error occurred when trying to update the role.';
export const ROLE_CONFIRM_DELETE = 'Are you sure you want to delete this role?';
export const ROLE_CONFIRM_DELETE_ROOT_PLACE = 'This Role is assigned to the Root Place. Are you sure you want to delete this role?';
export const ROLE_CONFIRM_DELETE_USER_COUNT_FN = (count: number) =>
  `There ${count === 1 ? 'is' : 'are'} ${count} ${count === 1 ? 'Person' : 'People'} assigned to this Role. Deleting this role without re-assigning a new Role to the ${count === 1 ? 'Person' : 'People'} will prevent them from accessing the ${APP_NAME}. Do you want to DELETE AND PREVENT ACCESS or DELETE AND ASSIGN A DIFFERENT ROLE?`;
export const ROLE_DELETE_PREVENT = 'Delete and Prevent Access';
export const ROLE_DELETE_ASSIGN = 'Delete and Assign a Different Role';
export const ROLE_APP_USERS_DELETED_FN = (count: number) =>
  `${count} Application User${count > 1 ? 's were' : ' was'} deleted.`
export const ROLE_DELETED_ERROR = 'An error occurred when trying to delete the role.';
export const ROLE_DELETED_SUCCESS_FN = (roleName: string = '') =>
  `Role ${roleName ? `(${roleName}) ` : ''}successfully deleted.`;
export const ROLE_CONFIRM_APP_USER_REASSIGN_FN = (count: number) =>
  `Please select a Role to re-assign ${count} Application User${count > 1 ? 's' : ''}.`
export const ROLE_APP_USERS_REASSIGNED_FN = (count: number, name: string) =>
  `${count} Application User${count > 1 ? 's were' : ' was'} re-assigned to the Role named "${name}".`
export const ROLE_APP_PRIVILEGE_LABEL = 'App View';
export const ROLE_APP_PRIVILEGE_DESC = `Provides access to ${APP_NAME}`;

/**
 * Yes, Privileges live in the database... but the UI is a bit more complex
 * than a simple list and so we have to have some additional data and
 * unique language that the database doesn't have.
 */
// TODO :: custom language for view, config, operator under each group
export const Privileges = Object.keys(qx_ePrivilege_enum).map(
  (key: qx_ePrivilege_enum) => {

    // Name of the Privilege; displayed to User
    let label = toSentenceCase(key);

    // Shorten generic labels like View, Config, and Operator
    let shortLabel = getShortLabel(label);

    // Explains what the Privilege does; displayed to User
    let desc: string = qx_ePrivilege_enum_desc[key as keyof typeof qx_ePrivilege_enum_desc];

    // Group related Privileges; think of this as a tag to group Privileges in the UI
    let group = getPrivilegeGroup(key);

    // Whether or not to disable the selection of the option
    let disabled = false;

    // Whether or not to show the option
    let visible = true;

    // Recommend to the User other Privileges they should enable
    const recommended = getRecommendedPrivileges(key);

    // Overrides
    switch(key) {
      case qx_ePrivilege_enum.View:
        label = ROLE_APP_PRIVILEGE_LABEL;
        desc = ROLE_APP_PRIVILEGE_DESC;
        disabled = true;
        break;
      case qx_ePrivilege_enum.LiveEvents:
        visible = false;
        disabled = true;
        break;
      case qx_ePrivilege_enum.BandwidthConfig:
        shortLabel = '';
        desc = 'Manage Bandwidth Schedules';
        break;
      case qx_ePrivilege_enum.ClipManagement:
        desc = 'View, Create, Update, and Delete Clips';
        break;
      case qx_ePrivilege_enum.Search:
        desc = 'View and Search Events';
        break;
      case qx_ePrivilege_enum.LockDown:
        label = 'Lockdown';
        desc = 'Operate Lockdown of Places and Doors';
        break;
      case qx_ePrivilege_enum.LiveVideo:
        desc = 'Watch Cameras in Real-time';
        break;
      case qx_ePrivilege_enum.RecordedVideo:
        desc = 'Access Timeline and Watch Stored Video';
        break;
      case qx_ePrivilege_enum.Snapshots:
        desc = 'View Snapshots';
        break;
      default:
        break;
    }

    // Keywords that the User can search for to find the Privilege
    const keywords = [...new Set([label, shortLabel, desc, group])].join(',').toLowerCase();

    return {
      keywords,
      visible,
      value: key,
      label,
      shortLabel: shortLabel || label,
      desc,
      shortDesc: desc,
      group,
      disabled,
      recommended
    }
  }
)
  .filter(privilege => privilege.visible)
  .filter(privilege => privilege.value !== 'Unknown')
  .sort((a, b) => a.label.localeCompare(b.label));

// simple array of all visible privileges that the UI allows
export const PrivilegesArrayFlat = Privileges.map(privilege => privilege.value);

enum PrivilegeGroup {
  Application = 'Application',
  Cameras = 'Cameras',
  Devices = 'Devices',
  Doors = 'Doors',
  Emergency = 'Emergency',
  Events = 'Events',
  Gateways = 'Gateways',
  People = 'People',
  Places = 'Places',
  Reporting = 'Reporting',
  Roles = 'Roles',
  Rules = 'Rules',
  Schedules = 'Schedules',
  Video = 'Video',
  Views = 'Views',
};

/**
 * Rendered in the PrivilegeForm,
 * visible on the Role Edit Page.
 */
export const PrivilegeGroups = {
  [PrivilegeGroup.Application]: {
    icon: 'qx',
    desc: 'QxControl',
  },
  [PrivilegeGroup.Cameras]: {
    icon: CAMERA_DEFAULT_ICON,
    desc: 'View, Manage, and/or Access Camera Actions. Device View Recommended',
  },
  [PrivilegeGroup.Devices]: {
    icon: DEVICE_DEFAULT_ICON,
    desc: 'View, Manage, and/or Access Device Actions. Gateway View Recommended',
  },
  [PrivilegeGroup.Doors]: {
    icon: DOOR_DEFAULT_ICON,
    desc: 'View, Manage, and/or Access Door Actions. Device View Recommended',
  },
  [PrivilegeGroup.Emergency]: {
    icon: 'light-emergency-on',
    desc: 'Emergency, Alerts, and Notifications',
  },
  [PrivilegeGroup.Events]: {
    icon: 'bell-exclamation',
    desc: 'Access Device and Integration Events',
  },
  [PrivilegeGroup.Gateways]: {
    icon: GATEWAY_DEFAULT_ICON,
    desc: 'View, Manage, and/or Access Gateway Actions',
  },
  [PrivilegeGroup.People]: {
    icon: PERSON_DEFAULT_ICON,
    desc: 'View, Manage, and/or Access People Actions',
  },
  [PrivilegeGroup.Places]: {
    icon: PLACE_DEFAULT_ICON,
    desc: 'Manage and/or Access Place Actions',
  },
  [PrivilegeGroup.Reporting]: {
    icon: REPORT_DEFAULT_ICON,
    desc: 'Review Your Data',
  },
  [PrivilegeGroup.Roles]: {
    icon: ROLE_DEFAULT_ICON,
    desc: 'Configure Roles and Privileges',
  },
  [PrivilegeGroup.Rules]: {
    icon: RULE_DEFAULT_ICON,
    desc: 'View, Manage, and/or Access Rule Actions',
  },
  [PrivilegeGroup.Schedules]: {
    icon: SCHEDULE_DEFAULT_ICON,
    desc: 'Configure Schedules and Holiday Sets',
  },
  [PrivilegeGroup.Views]: {
    icon: VIEW_DEFAULT_ICON,
    desc: 'Layouts for Live',
  },
}

function getShortLabel(label: string): string {
  if (label.includes(' Config')) {
    return 'Config';
  }
  else if (label.includes(' View')) {
    return 'View';
  }
  else if (label.includes(' Operator')) {
    return 'Operator';
  }
  return ''
}

function getPrivilegeGroup(key: qx_ePrivilege_enum): string {
  switch(key) {
    case qx_ePrivilege_enum.AccessReports:
    case qx_ePrivilege_enum.Audit:
      return PrivilegeGroup.Reporting;

    case qx_ePrivilege_enum.CameraConfig:
    case qx_ePrivilege_enum.CameraOperator:
    case qx_ePrivilege_enum.CameraView:
    case qx_ePrivilege_enum.ClipManagement:
    case qx_ePrivilege_enum.RecordedVideo:
    case qx_ePrivilege_enum.LiveVideo:
    case qx_ePrivilege_enum.Snapshots:
      return PrivilegeGroup.Cameras;

    case qx_ePrivilege_enum.DeviceConfig:
    case qx_ePrivilege_enum.DeviceOperator:
    case qx_ePrivilege_enum.DeviceView:
      return PrivilegeGroup.Devices;

    case qx_ePrivilege_enum.DoorConfig:
    case qx_ePrivilege_enum.DoorOperator:
    case qx_ePrivilege_enum.DoorView:
      return PrivilegeGroup.Doors;

    case qx_ePrivilege_enum.GatewayConfig:
    case qx_ePrivilege_enum.GatewayView:
    case qx_ePrivilege_enum.BandwidthConfig:
      return PrivilegeGroup.Gateways;

    case qx_ePrivilege_enum.PeopleView:
    case qx_ePrivilege_enum.PeopleConfig:
    case qx_ePrivilege_enum.UserCreation:
      return PrivilegeGroup.People;

    case qx_ePrivilege_enum.PlaceConfig:
      return PrivilegeGroup.Places;

    case qx_ePrivilege_enum.RoleConfig:
      return PrivilegeGroup.Roles;

    case qx_ePrivilege_enum.RulesView:
    case qx_ePrivilege_enum.RulesConfig:
      return PrivilegeGroup.Rules;

    case qx_ePrivilege_enum.ViewConfig:
      return PrivilegeGroup.Views;

    case qx_ePrivilege_enum.ScheduleConfig:
      return PrivilegeGroup.Schedules;

    case qx_ePrivilege_enum.Search:
    case qx_ePrivilege_enum.LiveEvents:
      return PrivilegeGroup.Events;

    case qx_ePrivilege_enum.View:
      return PrivilegeGroup.Application;

    case qx_ePrivilege_enum.LockDown:
      return PrivilegeGroup.Emergency;
  }
}

function getRecommendedPrivileges(privilege: qx_ePrivilege_enum) {
  switch(privilege) {
    case qx_ePrivilege_enum.CameraOperator:
      return [
        qx_ePrivilege_enum.CameraView
      ];

    case qx_ePrivilege_enum.DeviceOperator:
      return [
        qx_ePrivilege_enum.DeviceView
      ];

    case qx_ePrivilege_enum.DoorOperator:
      return [
        qx_ePrivilege_enum.DoorView
      ];

    case qx_ePrivilege_enum.DoorConfig:
      return [
        qx_ePrivilege_enum.DeviceView,
        qx_ePrivilege_enum.DoorView
      ];

    case qx_ePrivilege_enum.CameraConfig:
      return [
        qx_ePrivilege_enum.CameraView,
        qx_ePrivilege_enum.DeviceView
      ];

    case qx_ePrivilege_enum.DeviceConfig:
      return [
        qx_ePrivilege_enum.GatewayView,
        qx_ePrivilege_enum.CameraView,
        qx_ePrivilege_enum.DoorView,
        qx_ePrivilege_enum.DeviceView,
        qx_ePrivilege_enum.CameraConfig,
        qx_ePrivilege_enum.DoorConfig,
      ];

    case qx_ePrivilege_enum.LiveVideo:
    case qx_ePrivilege_enum.Snapshots:
      return [
        qx_ePrivilege_enum.CameraView
      ];

    case qx_ePrivilege_enum.GatewayConfig:
      return [
        qx_ePrivilege_enum.GatewayView
      ];

    case qx_ePrivilege_enum.BandwidthConfig:
      return [
        qx_ePrivilege_enum.GatewayConfig,
        qx_ePrivilege_enum.GatewayView
      ];

    case qx_ePrivilege_enum.PeopleConfig:
      return [
        qx_ePrivilege_enum.PeopleView
      ];

    case qx_ePrivilege_enum.UserCreation:
      return [
        qx_ePrivilege_enum.PeopleConfig,
        qx_ePrivilege_enum.PeopleView
      ];

    case qx_ePrivilege_enum.RulesConfig:
      return [
        qx_ePrivilege_enum.RulesView,
        qx_ePrivilege_enum.PeopleView,
        qx_ePrivilege_enum.DoorView,
        qx_ePrivilege_enum.DeviceView,
      ];

    case qx_ePrivilege_enum.RecordedVideo:
      return [
        qx_ePrivilege_enum.CameraView
      ];

    case qx_ePrivilege_enum.ClipManagement:
      return [
        qx_ePrivilege_enum.CameraView,
        qx_ePrivilege_enum.Search,
        qx_ePrivilege_enum.RecordedVideo,
      ];

    case qx_ePrivilege_enum.AccessReports:
      return [
        qx_ePrivilege_enum.PeopleView,
        qx_ePrivilege_enum.DoorView,
      ];

    default:
      return [];
  }
}
