import { qx_ePrivilege_enum } from "@/graphql/types";
import { ConfigRouteObject } from '@/types';

export const SEARCH_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'magnifying-glass'],
  UI_LABEL: 'Search',
  ROUTE_PATH: 'clip-search/:id?',
  ROUTE_NAME: 'search.index',
  ROUTE_PARAM: ':id',
  PRIVILEGES: [qx_ePrivilege_enum.Search],
};
