import { LIVE_ROUTE, PEOPLE_ROUTE, PLACES_ROUTE, SEARCH_ROUTE } from "@/config/routes";
import { qx_ePrivilege_enum } from "@/graphql/types";
import { hasPrivileges } from "@/utils/Privileges";

/**
 * Why does this exist? With the addition of Granular Privileges, it is possible
 * someone could enter the app with a highly restricted set of privileges...
 * no Live, Search, Timeline, Devices, or People access. We need to
 * route these Users to an appropriate place in the app for them.
 */
export const getAppHomeRouteConfig = (userPrivileges: qx_ePrivilege_enum[]) => {

  // check for live
  if (hasPrivileges(LIVE_ROUTE.PRIVILEGES, userPrivileges)) {
    return { name: LIVE_ROUTE.ROUTE_NAME }
  }

  // check for search
  if (hasPrivileges(SEARCH_ROUTE.PRIVILEGES, userPrivileges)) {
    return { name: SEARCH_ROUTE.ROUTE_NAME }
  }

  // people
  if (hasPrivileges(PEOPLE_ROUTE.PRIVILEGES, userPrivileges)) {
    return { name: PEOPLE_ROUTE.ROUTE_NAME }
  }

  // default to places, everyone has access
  return { name: PLACES_ROUTE.ROUTE_NAME }
}
