<template>
  <DoorIcon v-if="showIcon"
    :disabled="disabled"
    :parentDisconnected="gatewayOrDeviceNotConnected"
    :state="state"
    :alarm="alarm"
    :hasMonitor="hasDoorMonitor"
  />
  <span v-else-if="showLabel">
    {{ label }}
  </span>
</template>

<script lang="ts">
import { qx_eDeviceConnectionStatus_enum, qx_eDoorMonitor_enum } from '@/graphql/types';
import { smartIconValidator } from './helpers/smart-icon-validator';
import { getDoorStatusIcon } from '@/constants/door';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    door: {
      type: Object,
      required: true
    },
    type: {
      required: false,
      default: "icon",
      validator(value) {
        return smartIconValidator(value);
      }
    }
  },
  computed: {
    disabled() {
      return !this.door?.enabled || this.door.disabledByLicense;
    },
    state() {
      return this.door?.state || undefined;
    },
    alarm() {
      return this.door?.alarm || undefined;
    },
    hasDoorMonitor() {
      return this.door?.doorMonitor != qx_eDoorMonitor_enum.None || !!this.door?.doorMonitorAddress;
    },
    label() {
      const { alarm, state, gatewayOrDeviceNotConnected } = this;
      const status = getDoorStatusIcon(alarm, state, gatewayOrDeviceNotConnected);
      return status?.tooltip || '';
    },
    gatewayOrDeviceNotConnected() {
      const fullyConnected = this.door?.gatewayConnectionStatus && this.door.deviceConnectionStatus === qx_eDeviceConnectionStatus_enum.Connected;
      return !(fullyConnected || false);
    },
    showIcon() {
      return this.type === "icon";
    },
    showLabel() {
      return this.type === "label";
    }
  }
});
</script>

