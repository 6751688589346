 <template>
  <FaLayer v-tooltip="tooltip">
    <FaIcon
      :class="{
        ['IconColor IconColor--'+selectedIcon.color]: selectedIcon.color,
        [selectedIcon.class]: selectedIcon.class
      }"
      :mask="selectedIcon.mask || null"
      :icon="selectedIcon.icon"
      :transform="selectedIcon.transform || null"
      :opacity="selectedIcon.opacity || null"
      :style="statusConfig.style || null"
    />
    <FaIcon
      :class="{
        ['IconColor IconColor--'+selectedIcon.subColor]: selectedIcon.subColor,
        ['IconColor IconColor--'+selectedIcon.color]: !selectedIcon.subColor,
        [selectedIcon.subClass]: selectedIcon.subClass
      }"
      :icon="statusConfig.subIcon"
      :mask="statusConfig.subMask && ['fas', statusConfig.subMask] || null"
      :transform="statusConfig.subTransform || null"
      :opacity="statusConfig.subOpacity || null"
      :style="statusConfig.subStyle || null"
      v-if="statusConfig.subIcon"
    />
  </FaLayer>
</template>

<script lang="ts">
import { CAMERA_DEFAULT_ICON, CAMERA_DEVICE_CONNECTION_STATUS_ICON } from '@/constants/camera'
import { qx_eDeviceConnectionStatus_enum } from '@/graphql/types';
import { mixinFaIcons, mixinStatusProps } from '@/icons/mixins'
import { isBoolean } from '@/functions/boolean';
import { isString } from '@/functions/string';
import { isArray } from '@/functions/array';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [mixinFaIcons, mixinStatusProps],
  props: {
    ...mixinStatusProps,
    status: {
      required: false,
      default: undefined,
      type: [Boolean, String],
      validator: function(val) {
        if (isBoolean(val) && val) return false
        if (isBoolean(val) && !val) return true
        if (!isString(val)) return false
        if (!Object.keys(CAMERA_DEVICE_CONNECTION_STATUS_ICON).includes(val)) return false
        return true
      }
    },
  },
  computed: {
    // get information about the icon status
    statusConfig() {
      const { status } = this
      if (status === undefined) return false
      return {
        mask: null,
        style: null,
        icon: CAMERA_DEFAULT_ICON,
        ...CAMERA_DEVICE_CONNECTION_STATUS_ICON[status]
      }
    },
    isWarning() {
      return [
        qx_eDeviceConnectionStatus_enum.InvalidCredentials,
        qx_eDeviceConnectionStatus_enum.NotSupported,
        qx_eDeviceConnectionStatus_enum.Unknown,
      ].includes(this.status)

    },
    isLoading() {
      return [
        qx_eDeviceConnectionStatus_enum.Connecting,
        qx_eDeviceConnectionStatus_enum.Rebooting,
      ].includes(this.status)
    },

    // build icon details
    icon() {
      const { disabled, isWarning, statusConfig } = this
      let icon = ['fas', CAMERA_DEFAULT_ICON]
      if (disabled || !statusConfig) return icon

      // if (isWarning) {
      //   icon = ['fas', statusConfig.icon]
      //   return icon
      // }

      icon = isArray(statusConfig.icon) && statusConfig.icon || ['fas', statusConfig.icon]

      return icon
    },
    mask() {
      const { disabled, statusConfig } = this
      let mask = null
      if (disabled) return mask
      if (statusConfig?.mask) mask = isArray(statusConfig.mask) && statusConfig.mask || ['fas', statusConfig.mask]
      return mask
    },

    // define icon and tooltip
    selectedIcon() {
      const { icon, mask, statusConfig, disabled, status, isWarning, isLoading } = this
      const reversedIcon = statusConfig?.mask && (isWarning || isLoading)

      return {
        ...statusConfig,
        color: disabled && 'grey' || (statusConfig?.color || null),
        icon: !reversedIcon ? icon : mask,
        mask: !reversedIcon ? mask : icon,
        value: statusConfig?.value || status,
      }
    },
    tooltip() {
      const { selectedIcon, disabled } = this
      if (disabled || !selectedIcon.value) return false
      return {
        triggers: ['click', 'hover', 'focus'],
        content: `Camera: ${selectedIcon.tooltip || selectedIcon.value}`,
      }
    },
  }
})
</script>
