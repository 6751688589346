import { AuthStrategy, AuthStrategies, AccountData } from './types';
import { EnvProvider } from './../EnvProvider';

/**
 * The AuthClient is responsible for authenticating the user,
 * handling token refresh, and browser storage interaction
 * of auth data.
 */
export async function createAuthClient() {

  // get available auth strategies on create
  let strategies: AuthStrategies[] = [];

  async function getStrategies() {
    try {
      const response = await fetch(`${EnvProvider.endpoints.baseUrl}/services`);
      strategies = await response.json()
      return strategies;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Defaults to Firebase if Strategy
   * is not defined or determined.
   */
  const getClient = async (data: Partial<AccountData>, strategy?: AuthStrategies): Promise<AuthStrategy> => {
    let useStrategy;

    const hasEmail = (data?.email?.trim?.()?.length || 0) > 0;
    const hasPassword = (data?.password?.trim?.()?.length || 0) > 0;

    if (strategy) {
      useStrategy = strategy;
    } else if (strategies.includes(AuthStrategies.MagicEmail) && hasEmail && !hasPassword) {
      useStrategy = AuthStrategies.MagicEmail;
    } else if (strategies.includes(AuthStrategies.LDAP) && hasEmail && hasPassword) {
      useStrategy = AuthStrategies.LDAP;
    } else if (strategies.includes(AuthStrategies.User) && hasEmail && hasPassword) {
      useStrategy = AuthStrategies.User;
    } else if (
      strategies.includes(AuthStrategies.Firebase)
      && ((hasEmail && hasPassword) || data?.provider)
    ) {
      useStrategy = AuthStrategies.Firebase;
    } else if (strategies.includes(AuthStrategies.TOTP) && !hasEmail && hasPassword) {
      useStrategy = AuthStrategies.TOTP;
    }

    let client;
    switch(useStrategy) {
      case AuthStrategies.LDAP:
        if (EnvProvider.strategies.ldap) {
          client = (await import('@/utils/AuthClient/strategies/ldap-strategy')).createLdapStrategyClient(EnvProvider.strategies.ldap);
        }
        break;
      case AuthStrategies.MagicEmail:
        if (EnvProvider.strategies.magicEmail) {
          client = (await import('@/utils/AuthClient/strategies/magic-email-strategy')).createMagicEmailClient(EnvProvider.strategies.magicEmail);
        }
        break;
      case AuthStrategies.Firebase:
      default:
        if (EnvProvider.strategies.firebase) {
          client = (await import('@/utils/AuthClient/strategies/firebase-auth-strategy')).createFirebaseClient(EnvProvider.strategies.firebase);
        }
        break;
    }

    if (!client) {
      throw `Unable to find strategy: ${useStrategy}`;
    }

    return client;
  }

  function getAction(
    action: keyof AuthStrategy,
    options?: {
      defaultReturnValue?: boolean,
      defaultStrategy?: AuthStrategies
    }
  ) {

    return async (data: AccountData, strategy?: AuthStrategies) => {
      const client = await getClient(data, strategy || options?.defaultStrategy);

      if (!client?.[action]) {
        return options?.defaultReturnValue;
      }

      if (client?.[action]) {
        return await (client[action]?.(data) as Promise<boolean>);
      }
    }
  }

  return {
    getStrategies,
    strategies,
    getToken() {
      if (EnvProvider?.strategies?.firebase?.jwt) {
        const val = localStorage.getItem(EnvProvider.strategies.firebase.jwt);
        return val ? JSON.parse(val)?.token : null;
      }
      return null;
    },
    login: getAction('login'),
    logout: getAction('logout', { defaultReturnValue: true}),
    generateToken: getAction('generateToken'),
    verifyEmail: getAction('verifyEmail', { defaultReturnValue: true}),
    sendEmailVerification: getAction('sendEmailVerification'),
    createAccount: getAction('createAccount'),
    updateProfile: getAction('updateProfile'),
    forgotPassword: getAction('forgotPassword'),
    // TODO :: backend needs to implement reset password
    // resetPassword: getAction('resetPassword'),
  }
}
