import {
  GATEWAYS_ROUTE,
  GATEWAY_CREATE_ROUTE,
  GATEWAY_DEVICE_DISCOVERY_ROUTE,
  GATEWAY_VIEW_ROUTE,
  GATEWAY_VIEW_INFO_ROUTE,
  GATEWAY_VIEW_SYSTEM_ROUTE,
  GATEWAY_NETWORKING_ROUTE,
  GATEWAY_EDIT_ROUTE
} from '@/config/routes/gateways';

export default [
  /**
   * CONTAINER - All Gateway Routes will pass through this container
   *  This container is responsible for handling any global Gateway-related fetching.
   */
  {
    path: GATEWAYS_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "container-gateway" */ './GatewayContainer.vue')
    },
    meta: {
      privileges: GATEWAYS_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * Create Gateway
       */
      {
        path: GATEWAY_CREATE_ROUTE.ROUTE_PATH,
        name: GATEWAY_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: GATEWAY_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "page-gateway-create" */ './GatewayCreatePage.vue')
        }
      },

      /**
       * List of Gateways
       */
      {
        path: '',
        name: GATEWAYS_ROUTE.ROUTE_NAME,
        // meta: {},
        components: {
          default: () => import(/* webpackChunkName: "page-gateway-list" */ './GatewayListPage.vue')
        }
      },

      /**
       * CONTAINER - View Gateway Routes will pass through this container
       *  Parent Route is a container to fetch the Gateway object
       *  Child routes (includes view page) will reference the Gateway object pulled in the container
       *  This container will hold some generic/global elements for all Gateway View Pages
       */
      {
        path: GATEWAY_VIEW_ROUTE.ROUTE_PATH,
        // name: GATEWAY_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: GATEWAY_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "container-gateway-view" */ './GatewayContainerView.vue')
        },
        children: [
          /**
           * View Gateway - Info
           */
          {
            path: GATEWAY_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: GATEWAY_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: GATEWAY_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-gateway-view-info" */ './GatewayViewInfoPage.vue')
            }
          },
          /**
           * View Gateway - System Info
           */
          {
            path: GATEWAY_VIEW_SYSTEM_ROUTE.ROUTE_PATH,
            name: GATEWAY_VIEW_SYSTEM_ROUTE.ROUTE_NAME,
            meta: {
              privileges: GATEWAY_VIEW_SYSTEM_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-gateway-view-system" */ './GatewayViewSystemPage.vue')
            }
          },
          /**
           * View Gateway - System Info
           */
          {
            path: GATEWAY_NETWORKING_ROUTE.ROUTE_PATH,
            name: GATEWAY_NETWORKING_ROUTE.ROUTE_NAME,
            meta: {
              privileges: GATEWAY_NETWORKING_ROUTE.PRIVILEGES
            },
            components: {
              default: () =>
                import(/* webpackChunkName: "page-gateway-networking" */ './GatewayNetworkingPage.vue')
            }
          },
          /**
           * Discover Devices
           */
          {
            path: GATEWAY_DEVICE_DISCOVERY_ROUTE.ROUTE_PATH,
            name: GATEWAY_DEVICE_DISCOVERY_ROUTE.ROUTE_NAME,
            meta: {
              privileges: GATEWAY_DEVICE_DISCOVERY_ROUTE.PRIVILEGES
            },
            components: {
              default: () =>
                import(/* webpackChunkName: "page-gateway-device-discovery" */ './GatewayDeviceDiscoveryPage.vue')
            }
          },
          /**
           * Edit Gateway
           */
          {
            path: GATEWAY_EDIT_ROUTE.ROUTE_PATH,
            name: GATEWAY_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: GATEWAY_EDIT_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-gateway-edit" */ './GatewayEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];
