import { SAVED_CLIPS_PUBLIC_ROUTE } from '@/config/routes/saved-clips';

export default [
  /**
   * Authorization occurs inside of the
   * `PublicClipPage.vue` component.
   */
  {
    beforeEnter: (to, from, next) => {
      next();
    },
    name: SAVED_CLIPS_PUBLIC_ROUTE.ROUTE_NAME,
    path: SAVED_CLIPS_PUBLIC_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "public-clip-page" */ '@/views/saved-clips/PublicClipPage.vue')
    },
    // Do not add the meta back in until we refactor the routeAuthorization handler
    meta: {
      // privileges: []
    }
  }
];
