import { ApolloClient } from "@/utils/ApolloClient";
import { gql } from "@apollo/client/core";

const GQL_DELETE_USER_SETTING = gql`
  mutation GQL_DELETE_USER_SETTING(
    $id: String!,
  ) {
    deleteUserSetting(id: $id) {
      id
      name
    }
  }
`

// only to be called within `useUserSettings`
export function deleteSetting(
  id: string
): () => Promise<boolean> {

  return async () => {
    try {
      const response = await ApolloClient.mutate({
        mutation: GQL_DELETE_USER_SETTING,
        variables: {
          id
        }
      })

      return true;

    } catch (error) {
      throw error
    }
  }
}
