import { useAuthStore } from '@/store/auth';
import { CONFIG_ROUTES } from '@/config/routes';
// import { AUTH_LOGOUT_ROUTE, AUTH_TWO_FACTOR_ROUTE, AUTH_TWO_FACTOR_SETUP_ROUTE } from '@/config/routes/auth';

const UserTwoFactorNotEnabled: Middleware = async function (ctx) {
  const auth = useAuthStore();
  const user = await auth.account.authenticate();

  if (user) {
    const isEnabled = user.twoFactorEnabled;

    if (isEnabled) {
      return { name: CONFIG_ROUTES.APP_HOME, query: { redirect: ctx.to.fullPath } };
    }
  }
}

export default UserTwoFactorNotEnabled;
