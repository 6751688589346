import { AUTH_TWO_FACTOR_SETUP_ROUTE } from '@/config/routes/auth';
import { useAuthStore } from '@/store/auth';

const ScopeRequiresTwoFactor: Middleware = async function (ctx) {
  const auth = useAuthStore();

  // if 2FA is required, but the User has not enabled, redirect to 2FA Setup
  if (auth.user.twoFactorRequired && !auth.user.twoFactorEnabled) {
    return {
      name: AUTH_TWO_FACTOR_SETUP_ROUTE.ROUTE_NAME,
      query: { redirect: ctx.to.fullPath }
    };
  }
}

export default ScopeRequiresTwoFactor;
