<template>
  <FaLayer v-tooltip="tooltip">
    <FaIcon
      :class="{
        ['IconColor IconColor--'+selectedIcon.color]: selectedIcon.color,
        [selectedIcon.class]: selectedIcon.class
      }"
      :mask="selectedIcon.mask || null"
      :icon="selectedIcon.icon"
      :transform="selectedIcon.transform || null"
      :opacity="selectedIcon.opacity || null"
      :style="statusConfig.style || null"
    />
    <FaIcon
      :class="{
        ['IconColor IconColor--'+selectedIcon.subColor]: selectedIcon.subColor,
        ['IconColor IconColor--'+selectedIcon.color]: !selectedIcon.subColor,
        [selectedIcon.subClass]: selectedIcon.subClass
      }"
      :icon="statusConfig.subIcon"
      :mask="statusConfig.subMask && ['fas', statusConfig.subMask] || null"
      :transform="statusConfig.subTransform || null"
      :opacity="statusConfig.subOpacity || null"
      :style="statusConfig.subStyle || null"
      v-if="statusConfig.subIcon"
    />
  </FaLayer>
</template>

<script lang="ts">
import { qx_eDoorAlarm_enum, qx_eDoorState_enum } from '@/graphql/types';
import { getDoorStatusIcon, DOOR_DEFAULT_ICON } from '@/constants/door';
import { mixinStatusProps, mixinFaIcons } from '@/icons/mixins';
import { isBoolean } from '@/functions/boolean';
import { isString } from '@/functions/string';
import { isArray } from '@/functions/array';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [mixinFaIcons],
  props: {
    ...mixinStatusProps,
    disabledByLicense: {
      default: false,
      type: Boolean,
      required: false,
    },
    parentDisconnected: {
      default: false,
      type: Boolean,
      required: false,
    },
    hasMonitor: {
      default: true,
      type: Boolean,
      required: false
    },
    alarm: {
      type: [String, Boolean],
      required: false,
      default: false,
      validator: val => {
        if (isBoolean(val) && !val) return true
        if (!isString(val)) return false
        return Object.keys(qx_eDoorAlarm_enum).includes(val)
      }
    },
    state: {
      type: [String, Boolean],
      required: false,
      default: false,
      validator: val => {
        if (isBoolean(val) && !val) return true
        if (!isString(val)) return false
        return Object.keys(qx_eDoorState_enum).includes(val)
      }
    }
  },
  computed: {
    // get information about the icon status
    statusConfig() {
      const { alarm, state, parentDisconnected, disabledByLicense, hasMonitor, disabled } = this
      if (!alarm && !state && !disabledByLicense && !disabled) return false
      return getDoorStatusIcon(alarm, state, parentDisconnected, disabledByLicense, hasMonitor, !disabled)
    },
    // build icon details
    icon() {
      const { statusConfig } = this
      let icon = ['fas', DOOR_DEFAULT_ICON]
      if (!statusConfig) return icon

      icon = isArray(statusConfig.icon) && statusConfig.icon || ['fas', statusConfig.icon]

      return icon
    },
    mask() {
      const { statusConfig } = this
      let mask = null
      if (statusConfig.mask) mask = isArray(statusConfig.mask) && statusConfig.mask || ['fas', statusConfig.mask]
      return mask
    },

    // define icon and tooltip
    selectedIcon() {
      const { icon, mask, statusConfig, status } = this
      const reversedIcon = statusConfig.mask

      return {
        ...statusConfig,
        color: statusConfig.color || null,
        icon: !reversedIcon ? icon : mask,
        mask: !reversedIcon ? mask : icon,
        value: status,
      }
    },
    tooltip() {
      if (!this.selectedIcon?.tooltip) return false
      return {
        triggers: ['click', 'hover', 'focus'],
        content: `Door: ${this.selectedIcon.tooltip}`,
      }
    }
  }
});
</script>
