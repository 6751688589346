import {
  HOLIDAY_SETS_ROUTE,
  HOLIDAY_SET_CREATE_ROUTE,
  HOLIDAY_SET_VIEW_ROUTE,
  HOLIDAY_SET_VIEW_INFO_ROUTE,
  HOLIDAY_SET_EDIT_ROUTE,
} from '@/config/routes/holiday-sets';

export default [
  {
    path: HOLIDAY_SETS_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "container-holiday-set" */ './HolidaySetContainer.vue')
    },
    meta: {
      privileges: HOLIDAY_SETS_ROUTE.PRIVILEGES
    },
    children: [
      {
        path: '',
        name: HOLIDAY_SETS_ROUTE.ROUTE_NAME,
        components: {
          default: () => import(/* webpackChunkName: "page-holiday-sets-list" */ './HolidaySetsListPage.vue')
        }
      },
      {
        path: HOLIDAY_SET_CREATE_ROUTE.ROUTE_PATH,
        name: HOLIDAY_SET_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: HOLIDAY_SET_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true,
        },
        components: {
          default: () => import(/* webpackChunkName: "page-holiday-set-create" */ './HolidaySetCreatePage.vue')
        }
      },
      {
        path: HOLIDAY_SET_VIEW_ROUTE.ROUTE_PATH,
        meta: {
          privileges: HOLIDAY_SET_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "container-holiday-set-view" */ './HolidaySetContainerView.vue')
        },
        children: [
          {
            path: HOLIDAY_SET_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: HOLIDAY_SET_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: HOLIDAY_SET_VIEW_INFO_ROUTE.PRIVILEGES,
            },
            components: {
              default: () => import(/* webpackChunkName: "page-holiday-set-view-info" */ './HolidaySetViewInfoPage.vue')
            }
          },
          {
            path: HOLIDAY_SET_EDIT_ROUTE.ROUTE_PATH,
            name: HOLIDAY_SET_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: HOLIDAY_SET_EDIT_ROUTE.PRIVILEGES,
            },
            components: {
              default: () => import(/* webpackChunkName: "page-holiday-set-edit" */ './HolidaySetEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];
