import { RuleActionConfig, FlowActions, ActionArgumentRender, FlowAction, RuleAction } from './../types';
import { getRuleActionArgumentBreakdown } from './get-rule-action-argument-breakdown';
import { conditionValueFieldToComponent } from './../components/helpers';
import { ruleActionOverrides } from '@/models/rule/utils/rule-actions';
import { isString, toSentenceCase } from '@/functions/string';
import { GQL_GET_RULE_ACTIONS } from "@/graphql/hasura";
import { ApolloClient } from "@/utils/ApolloClient";
import { isBoolean } from "@/functions/boolean";
import { defineAsyncComponent } from 'vue';

const displayRelationComponent = defineAsyncComponent(() => import('@/models/rule/components/condition-display/RuleConditionDisplayValueRelation.vue'));
const displayGenericComponent = defineAsyncComponent(() => import('@/models/rule/components/condition-display/RuleConditionDisplayValueGeneric.vue'));
const formRelationComponent = defineAsyncComponent(() => import('@/models/rule/components/form/RuleConditionValueSelectUseSearch.vue'));

const defaultArgumentRenderConfig: Partial<ActionArgumentRender> = {
  argument: '',
  formComponent: undefined,
  displayComponent: undefined,
  isRequired: false,
  isType: 'String',
  isArray: false,
  validate: undefined,
  props: undefined,
}

/**
 * Used by the below function to determine
 * how to render arguments that are not
 * pre-defined by the UI.
 */
const argumentRenderConfig: {
  [key: string]: Partial<ActionArgumentRender>
} = {
  videoSourceID: {
    label: 'Camera Selected',
    argument: 'videoSourceID',
    formComponent: formRelationComponent,
    displayComponent: displayRelationComponent,
    props: {
      relation: 'videoSource'
    }
  },
  doorID: {
    label: 'Door Selected',
    argument: 'doorID',
    formComponent: formRelationComponent,
    displayComponent: displayRelationComponent,
    props: (action: RuleAction) => ({
      relation: 'door'
    }),
  },
  placeID: {
    label: 'Place Selected',
    argument: 'placeID',
    formComponent: conditionValueFieldToComponent('placeID').component || formRelationComponent,
    displayComponent: displayRelationComponent,
    props: (action: RuleAction) => ({
      relation: 'place'
    }),
  },
  outputPointID: {
    label: 'Output Selected',
    argument: 'outputPointID',
    formComponent: formRelationComponent,
    displayComponent: displayRelationComponent,
    props: (action: RuleAction) => ({
      relation: 'deviceDIO'
    }),
  },
  // url: {
  //   label: 'URL Address',
  //   argument: 'url',
  // },
  // PTZ selection, must provide videoSourceID as prop
  preset: {
    label: 'PTZ Preset',
    argument: 'preset',
    formComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/arguments/RuleActionArgumentPtzPresetForm.vue')),
  },
}

// TODO :: add more argumentRenderConfig for other arguments
//   "type": "jsonb"
  //   "name": "body",
  //   "name": "headers",

//   "type": "String!"
  //   "name": "body",
  //   "name": "subject",
  //   "name": "message",

//   "type": "[String!]"
  //   "name": "people",
  //   "name": "emails",
  //   "name": "phoneNumbers",

export async function generateRuleActionConfig(action: RuleAction): Promise<RuleActionConfig> {

  // find the UI-defined preset for the action, if available
  const configPreset = ruleActionOverrides?.[action?.type as FlowActions] || {};

  // determine if preset components are defined, or use fallbacks
  const displayComponent = configPreset?.displayComponent || 'RuleActionFallbackDisplay';
  const formComponent = configPreset?.formComponent || 'RuleActionFallbackForm';

  // fetch the action's arguments from the server
  // DEV NOTE :: technically, not needed if we have a preset for both display and form components
  const response = await ApolloClient.query({
    query: GQL_GET_RULE_ACTIONS,
    // prefer cache over network request if possible
    fetchPolicy: 'cache-first'
  });

  const ruleActions: FlowAction[] = response?.data?.getFlowActions || [];
  const ruleAction = ruleActions.find(a => a.name === action.type);

  if (!ruleAction) {
    throw new Error(`Action "${action.type}" not found`);
  }

  /**
   * for each argument we need 4 things:
   *  - display component
   *  - form input component
   *  - validation rules
   *  - props for component
   */
  const argumentsConfig = ruleAction.arguments?.reduce<ActionArgumentRender[]>((acc, arg) => {

    // these are special arguments that the backend manages
    if (['_data', '_flow', 'runOnGateway',].includes(arg.name)) {
      return acc;
    }

    // shallow copy the default render config
    const config: Partial<ActionArgumentRender> = {...defaultArgumentRenderConfig};

    // check if preset for argument exists
    const argPreset = configPreset?.arguments?.[arg.name];

    // find existing render config
    const hasArgumentRenderConfig = argumentRenderConfig?.[arg.name]
    if (hasArgumentRenderConfig) {
      Object.assign(config, hasArgumentRenderConfig);
    }

    // merge preset with generated... preset overrides
    if (argPreset) {
      Object.assign(config, argPreset);
    }

    // if no preset, use fallbacks
    config.label = config.label ?? toSentenceCase(arg.name);
    config.argument = !!config.argument ? config.argument : arg.name;
    if (!config.displayComponent) {
      config.displayComponent = displayGenericComponent;
    }

    const argBreakdown = getRuleActionArgumentBreakdown(arg.type)
    config.isRequired = argBreakdown.isRequired;
    config.isArray = argBreakdown.isArray;
    config.isType = argBreakdown.isType;

    if (!config.formComponent) {
      switch(argBreakdown.isType) {
        case 'Boolean':
          config.formComponent = defineAsyncComponent(() => import('@/components/form/inputs/base/BaseToggle.vue'));
          break;
        default:
          config.formComponent = defineAsyncComponent(() => import('@/components/form/inputs/base/BaseInput.vue'));
          break;
      }
    }

    // check if a custom validate function was defined
    if (!config.validate) {
      // individual action argument validation
      config.validate = async (value: unknown) => {
        try {
          const prefix = `"${configPreset?.title}: ${config.label}"`;
          if (argBreakdown.isRequired && !value && argBreakdown.isType !== 'Boolean') {
            throw new Error(`${prefix} is required`);
          }
          if (argBreakdown.isArray && !Array.isArray(value)) {
            throw new Error(`${prefix} must be an array`);
          }
          if (!argBreakdown.isArray && Array.isArray(value)) {
            throw new Error(`${prefix} must not be an array`);
          }
          if (argBreakdown.isType === 'Boolean' && !isBoolean(value)) {
            throw new Error(`${prefix} must be a boolean`);
          }
          if (argBreakdown.isType === 'String' && !isString(value)) {
            throw new Error(`${prefix} must be a string`);
          }
          return true;
        } catch (error) {
          throw error;
        }
      }
    }

    acc.push(config as ActionArgumentRender);

    return acc;
  }, []) || [];

  // this is an action validate function
  const validate = configPreset?.validate || (async (value: RuleAction) => {
    try {
      for (const key of Object.keys(value.arguments)) {
        const arg = argumentsConfig.find(a => a.argument === key);
        if (arg) {
          await arg.validate(value?.arguments?.[key]);
        }
      }

      return true;
    } catch (error) {
      throw error;
    }
  })

  return {
    // include known data of the FlowAction
    action: { ...action },

    // include known UI overrides
    // TODO :: what happens if this is not set, and we need titles, descriptions, etc
    ...configPreset,

    // TODO :: how to handle if missing in preset
    title: configPreset?.title || action.type,
    desc: configPreset?.desc,
    icon: configPreset?.icon || ['fas', 'circle-question'],
    validate,

    // determine components for display and form edit
    displayComponent,
    formComponent,

    // if using fallback components, determine how to render the arguments
    arguments: argumentsConfig
  }
}
