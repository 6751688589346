import { Route } from 'vue-router/types';

/**
 * Traverse route tree up, return the first found route under the
 * `matched` key where `matchedRoute.meta` has a property value.
 */
export const getRouteTreeProperty = <T>(route: Route, property: string): T | false => {
  const matched: Route[] = Object.assign([], route?.matched);
  const foundRoute: Route | undefined = matched
    .reverse()
    .find((route: Route): Route => route.meta.hasOwnProperty(property));

  if (foundRoute) return foundRoute.meta[property];

  return false;
};

/**
 * Traverse route tree up, return an array of matched
 * `route.meta` property values. Good to use when
 * you want all matching `meta.key` values
 */
export const getDeepRouteTreeProperty = <T>(route: Route, property: string): T[] | false => {
  const matched: Route[] = Object.assign([], route?.matched);
  const foundRoutes: T[] = matched
    .reverse()
    .filter((route: Route) => route.meta.hasOwnProperty(property))
    .map((route) => route.meta[property]);

  if (foundRoutes?.length) return foundRoutes;

  return false;
};
