// TODO :: ... https://vitejs.dev/guide/env-and-mode.html#env-variables-and-modes
import { FirebaseStrategyConfig, LdapStrategyConfig, MagicEmailStrategyConfig } from '@/utils/AuthClient'
import { parseBoolean, isBoolean } from '@/functions/boolean'
import { APP_WINDOW_KEY } from '@/config/app';

export enum Envs {
  Cloud = 'cloud',
  Gateway = 'gateway',
  Premise = 'premise',
  Dev = 'dev'
}

interface EnvProviderApi {
  secure: boolean;            // API_SECURE_WEB
  url: string;                // API_SERVER_URL
  hasura: string;             // API_HASURA_PATH
  remote: string;             // API_REMOTE_PATH
  signal: string;             // API_SIGNAL_PATH
}

interface EnvProviderEndpoints {
  baseUrl: string;
  httpClientEndPoint: string;
  wsEndpoint: string;
  wsRemoteSchemaEndpoint: string;
  httpEndpoint: string;
  httpRemoteSchemaEndpoint: string;
  signalingServerEndpointWithPath: string;
}

type EnvProvider = {
  env: Envs;                    // API_ENV
  api: EnvProviderApi,
  endpoints: EnvProviderEndpoints,
  jwt: boolean,
  strategies: {
    firebase: FirebaseStrategyConfig,
    ldap?: LdapStrategyConfig,
    magicEmail?: MagicEmailStrategyConfig,
  }
}

const createEnvProvider = (): EnvProvider => {
  const env = window[APP_WINDOW_KEY] || {}

  const api = {
    secure: isSecure(env?.API_SECURE_WEB || false),
    url: env?.API_SERVER_URL,
    hasura: env?.API_HASURA_PATH,
    remote: env?.API_REMOTE_PATH,
    signal: env?.API_SIGNAL_PATH,
  };

  const endpoints = getEndPoints(api);

  const useJwt = import.meta.env.QX_DEV_USE_JWT === 'true' || env?.USE_JWT === 'true'

  return {
    env: env?.API_ENV,
    api,
    endpoints,
    jwt: useJwt,
    strategies: {
      firebase: {
        url: endpoints.baseUrl,
        apiKey: env?.FB_API_KEY,
        authDomain: env?.FB_AUTH_DOMAIN,
        databaseURL: env?.FB_DATABASE_URL,
        projectId: env?.FB_PROJECT_ID,
        storageBucket: env?.FB_STORAGE_BUCKET,
        messagingSenderId: env?.FB_MESSAGING_SENDER_ID,
        appId: env?.FB_WEB_ID,
        measurementId: env?.FB_MEASUREMENT_ID,
        jwt: useJwt ? 'qx-jwt-token' : '',
      },
      ldap: {
        url: endpoints.baseUrl,
      },
      magicEmail: {
        url: endpoints.baseUrl,
      }
    }
  }
}

export const EnvProvider = createEnvProvider()

/**
 * Ensures that if a string representation of a boolean
 * is used in the ENV Variables it will be converted
 * to a proper boolean.
 */
function isSecure(maybeBoolean: string | boolean): boolean {
  if (!maybeBoolean) return false;
  if (isBoolean(maybeBoolean)) return maybeBoolean as boolean;
  return parseBoolean(maybeBoolean as string) as boolean;
}

/**
 * Below functions are primarily used for creating the ApolloClient
 * utility, but could be utilized in other areas of the app.
 */
function getBaseEndpoint(api: EnvProviderApi) {
  if (api.url) {
    return api.url
  }

  // Default to same fqdn with ":4400"
  let defaultEndpoint: string = `${window.location.hostname}:4400`;

  if (window.location.hostname.match(/^qx[0-9A-Fa-f]{6}\./)) {
    // qx######.<domain> means hostname is our NAT address dns name
    // gql endpoint is at same fqdn and port 4400
    // When the backend switches to kubernetes, we probably want to
    // match this no suffix address to "-svr" backend
    defaultEndpoint = `${window.location.hostname}:4400`;

  } else if (window.location.hostname.match(/^qx[0-9A-Fa-f]{6}-pub\./)) {
    // Hostname is our public IP dns name (suffix -pub)
    // Assumes 20824 is the forwarded web server port and "20824" is graphl port
    defaultEndpoint = `${window.location.hostname}:20824`;

  } else if (window.location.hostname.match(/^qx[0-9A-Fa-f]{6}-app\./)) {
    // Hostname is a reverse proxied endpoint with no port number (suffix -app)
    // The matching gql endpoint is qx######-svr.<domain>
    defaultEndpoint = window.location.hostname.substr(0, 8) + '-svr' + window.location.hostname.substr(12);

  } else if (window.location.hostname.includes('-k8s')) {
    // Allows for special routing for GKE testing in other namespaces
    defaultEndpoint = `qxsystem-${window.location.hostname}:4444`;
  }

  return defaultEndpoint
}

function isUsingSecureEndpoint(api: EnvProviderApi) {
  const isHttps = window.location.protocol.includes('https');
  return isHttps || api.secure
}

function getEndPoints(api: EnvProviderApi) {
  const hasuraPath = api.hasura;
  const remotePath = api.remote;
  const signalPath = api.signal;

  let baseEndpoint = getBaseEndpoint(api);
  const secureWeb = isUsingSecureEndpoint(api);

  // k8s with hasura/remote path variables listens on 443 only
  if (hasuraPath) {
    baseEndpoint = baseEndpoint.replace(':4400', ':443');
    baseEndpoint = baseEndpoint.replace(':4444', ':443');
    // Note router will need to port forward 20825 to 443 NOT 4400
  }

  const httpClientEndPoint = `http${secureWeb ? 's' : ''}://${baseEndpoint}`;
  const pathArray = httpClientEndPoint.split( '/' );
  const protocol = pathArray[0];
  const host = pathArray[2];
  const baseUrl = protocol + '//' + host;

  const endpoints = {
    httpClientEndPoint,
    wsEndpoint: hasuraPath
      ? `ws${secureWeb ? 's' : ''}://${baseEndpoint}${hasuraPath}`
      : `ws${secureWeb ? 's' : ''}://${baseEndpoint}/v1/graphql`,
    wsRemoteSchemaEndpoint: remotePath
      ? `ws${secureWeb ? 's' : ''}://${baseEndpoint}${remotePath}`
      : `ws${secureWeb ? 's' : ''}://${baseEndpoint}/graphql`,
    httpEndpoint: hasuraPath
      ? `http${secureWeb ? 's' : ''}://${baseEndpoint}${hasuraPath}`
      : `http${secureWeb ? 's' : ''}://${baseEndpoint}/v1/graphql`,
    httpRemoteSchemaEndpoint: remotePath
      ? `http${secureWeb ? 's' : ''}://${baseEndpoint}${remotePath}`
      : `http${secureWeb ? 's' : ''}://${baseEndpoint}/graphql`,
    signalingServerEndpointWithPath: signalPath
      ? `ws${secureWeb ? 's' : ''}://${baseEndpoint}${signalPath}`
      : `ws${secureWeb ? 's' : ''}://${baseEndpoint}/signaling-server/socket.io`,

    // used for auth strategies
    baseUrl,
  };

  return endpoints;
}
