import { DOOR_DEFAULT_ICON } from '@/constants/door';
import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const DOORS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', DOOR_DEFAULT_ICON],
  UI_LABEL: 'Doors',
  ROUTE_PATH: 'doors',
  ROUTE_NAME: 'door.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.DoorView]
};

export const DOOR_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', DOOR_DEFAULT_ICON],
  UI_LABEL: 'Create Door',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'door.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.DoorConfig]
};

export const DOOR_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Door',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'door.edit',
  ROUTE_PARAM: 'doorId',
  PRIVILEGES: [qx_ePrivilege_enum.DoorConfig]
};

// name used between two route names
const DOOR_VIEW_ROUTE_NAME_SHARED = 'door.view';

// this route does not register it's ROUTE_NAME
// that is because it would duplicate the info route
export const DOOR_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', DOOR_DEFAULT_ICON],
  UI_LABEL: 'View Door',
  ROUTE_PATH: ':doorId',
  ROUTE_NAME: DOOR_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'doorId',
  PRIVILEGES: [qx_ePrivilege_enum.DoorView]
};

export const DOOR_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: DOOR_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'doorId',
  PRIVILEGES: [qx_ePrivilege_enum.DoorView]
};

export const DOOR_VIEW_SETTINGS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'sitemap'],
  UI_LABEL: 'Settings',
  ROUTE_PATH: 'settings',
  ROUTE_NAME: 'door.view.settings',
  ROUTE_PARAM: 'doorId',
  PRIVILEGES: [qx_ePrivilege_enum.DoorConfig]
};
