import { RULE_DEFAULT_ICON, RULE_TRACE_ICON } from '@/config/rule';
import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const RULES_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', RULE_DEFAULT_ICON],
  UI_LABEL: 'Rules',
  ROUTE_PATH: 'rules',
  ROUTE_NAME: 'rule.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.RulesConfig]
};

export const RULE_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', RULE_DEFAULT_ICON],
  UI_LABEL: 'Create Rule',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'rule.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.RulesConfig]
};

export const RULE_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Rule',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'rule.edit',
  ROUTE_PARAM: 'ruleId',
  PRIVILEGES: [qx_ePrivilege_enum.RulesConfig]
};

// name used between two route names
const RULE_VIEW_ROUTE_NAME_SHARED = 'rule.view';

// this route does not register it's ROUTE_NAME
// that is because it would duplicate the info route
export const RULE_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', RULE_DEFAULT_ICON],
  UI_LABEL: 'View Rule',
  ROUTE_PATH: ':ruleId',
  ROUTE_NAME: RULE_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'ruleId',
  PRIVILEGES: [qx_ePrivilege_enum.RulesView]
};

export const RULE_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: RULE_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'ruleId',
  PRIVILEGES: [qx_ePrivilege_enum.RulesView]
};

export const RULE_TRACE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', RULE_TRACE_ICON],
  UI_LABEL: 'Trace',
  ROUTE_PATH: 'trace',
  ROUTE_NAME: 'rule.trace',
  ROUTE_PARAM: 'ruleId',
  PRIVILEGES: [qx_ePrivilege_enum.RulesView]
};
