import { max, min, required, email, min_value, max_value, numeric, one_of, is_not } from '@vee-validate/rules';
import { validatePhoneNumberByCountryCode } from '@/utils/PhoneNumber';
import { isObject } from '@/functions/object';
import { isValidUrl } from '@/functions/url';
import { defineRule } from 'vee-validate';
import type { App } from 'vue';

export default async (app: App) => {

  /**
   * Pre-made rules
   */
  defineRule('one_of', one_of);
  defineRule('is_not', is_not);
  defineRule('numeric', numeric);
  defineRule('min', min);
  defineRule('max', max);
  defineRule('min_value', min_value);
  defineRule('max_value', max_value);
  defineRule('email', email);
  defineRule('required', required);

  /**
   * String entry must be at least `min` and at most `max` characters.
   */
  defineRule('minmax', (
    value: string,
    [min, max]: [min: number, max: number],
  ) => {
    if (value.length >= min && value.length <= max) {
      return true;
    } else {
      return `Must be at least ${min} characters, and ${max} at most`
    }
  });

  /**
   * The value cannot be zero.
   */
  defineRule('notZero', (value: number) => {
    return value !== 0;
  });

  /**
   * Ensure that the value is not the
   * same as the target value.
   *
   *  rules="unique:{name or vid}"
   */
  defineRule('unique', (
    value: Record<string, unknown>,
    [target]: [Record<string, unknown>],
  ) => {
    let pass = false;
    if (isObject(target)) {
      // if an id exists on the object, it is safe to assume the value has an id as well
      if (target?.id && value?.id) return target.id !== value.id;

      // otherwise check if the objects are the same
      pass = JSON.stringify(target) !== JSON.stringify(value);
    } else {
      pass = value !== target;
    }

    return pass || `Cannot be the same value as "${target}"`;
  });

  /**
   *
   */
  defineRule('phoneNumber', (value: string, [countryCode]: [countryCode: string]) => {
    return validatePhoneNumberByCountryCode(value, countryCode) ||  'Please enter a valid number';
  });

  /**
   *
   */
  defineRule('url', (value: string) => {
    return isValidUrl(value) || 'Enter a valid URL'
  });

  return;
};
