import { EnvProvider } from '@/utils/EnvProvider';
import { useHead } from '@unhead/vue';

export function useAppMeta() {
  const isProduction = EnvProvider.endpoints.baseUrl.includes('qumulex.io');
  const isTry = EnvProvider.endpoints.baseUrl.includes('try.qumulex.io') ? 'orange' : false;
  const isUnstable = EnvProvider.endpoints.baseUrl.includes('unstable') ? 'red' : false;
  const isDevelop = EnvProvider.endpoints.baseUrl.includes('develop') ? 'green' : false;
  const isStaging = EnvProvider.endpoints.baseUrl.includes('staging') ? 'blue' : false;

  let color = isTry || isUnstable || isDevelop || isStaging || '';
  color = color ? '-'+color : '';

  const icon = `/favicon${color}.png`;
  const pwa192 = `/pwa-192x192${color}.png`;
  const pwa512 = `/pwa-512x512${color}.png`;

  const title = isUnstable ? 'Unstable' : isDevelop ? 'Develop' : isStaging ? 'Staging' : 'Qumulex';

  useHead({
    title: `QxControl | ${title}`,
    link: [
      { rel: 'shortcut icon', href: icon },
      { rel: 'favicon', href: icon },
      { rel: 'apple-touch-icon', href: pwa192, sizes: '180x180' },
      { rel: 'mask-icon', href: pwa512 },
    ]
  })

  return {}
}
