import { ConfigRouteObject } from '@/types';

export const HELP_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'circle-question'],
  UI_LABEL: 'Help',
  ROUTE_PATH: 'help',
  ROUTE_NAME: 'help.index',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};
