import { REPORTS_ROUTE, REPORTS_VIEW_ROUTE, REPORTS_VIEW_TEMPLATE_ROUTE } from '@/config/routes/reports';

export default [
  /**
   * CONTAINER - All Role Routes will pass through this container
   *  This container is responsible for handling any global Role-related fetching.
   */
  {
    path: REPORTS_ROUTE.ROUTE_PATH,
    name: REPORTS_ROUTE.ROUTE_NAME,
    components: {
      default: () => import(/* webpackChunkName: "container-reports" */ './ReportsContainer.vue')
    },
    meta: {
      privileges: REPORTS_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * CONTAINER - View Role Routes will pass through this container
       *  Parent Route is a container to fetch the Role object
       *  Child routes (includes view page) will reference the Role object pulled in the container
       *  This container will hold some generic/global elements for all Role View Pages
       */
      {
        path: REPORTS_VIEW_ROUTE.ROUTE_PATH,
        // name: ROLE_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: REPORTS_VIEW_ROUTE.PRIVILEGES
        },
        components: {
          default: () => import(/* webpackChunkName: "container-reports-view" */ './ReportsContainerView.vue')
        },
        children: [
          /**
           * View Role - Info
           */
          {
            path: REPORTS_VIEW_TEMPLATE_ROUTE.ROUTE_PATH,
            name: REPORTS_VIEW_TEMPLATE_ROUTE.ROUTE_NAME,
            meta: {
              privileges: REPORTS_VIEW_TEMPLATE_ROUTE.PRIVILEGES
            },
            components: {
              default: () =>
                import(/* webpackChunkName: "page-reports-view-template" */ './ReportsViewTemplatePage.vue')
            }
          }
        ]
      }
    ]
  }
];
