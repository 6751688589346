import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const ROLES_ROUTE = Object.freeze<ConfigRouteObject>({
  UI_ICON: ['fal', 'role'],
  UI_LABEL: 'Roles',
  ROUTE_PATH: 'roles',
  ROUTE_NAME: 'roles.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.RoleConfig]
});

export const ROLES_CREATE_ROUTE = Object.freeze<ConfigRouteObject>({
  UI_ICON: ['fal', 'role'],
  UI_LABEL: 'Create Role',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'role.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.RoleConfig]
});

export const ROLES_EDIT_ROUTE = Object.freeze<ConfigRouteObject>({
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Role',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'role.edit',
  ROUTE_PARAM: 'roleId',
  PRIVILEGES: [qx_ePrivilege_enum.RoleConfig]
});

// name used between two route names
const ROLES_VIEW_ROUTE_NAME_SHARED = 'role.view';

// this route does not register it's ROUTE_NAME
// that is because it would duplicate the info route
export const ROLES_VIEW_ROUTE = Object.freeze<ConfigRouteObject>({
  UI_ICON: ['fas', 'circle-user'],
  UI_LABEL: 'View Role',
  ROUTE_PATH: ':roleId',
  ROUTE_NAME: ROLES_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'roleId',
  PRIVILEGES: [qx_ePrivilege_enum.RoleConfig]
});

export const ROLES_VIEW_INFO_ROUTE = Object.freeze<ConfigRouteObject>({
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: ROLES_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'roleId',
  PRIVILEGES: [qx_ePrivilege_enum.RoleConfig]
});
