import { AUTH_LOGOUT_ROUTE, AUTH_TERMS_AGREEMENT_ROUTE } from '@/config/routes/auth';
import { CONFIG_ROUTES } from '@/config/routes';
import { useAuthStore } from '@/store/auth';

/**
 * The User must accept the Terms Agreement
 * before being allowed into the app.
 */
const UserAcceptedTermsAgreement: Middleware = async function (to) {
  const auth = useAuthStore();

  // if local state is false or not populated, check the server
  if (!auth.user.acceptedTerms) {
    // true, forces a query call, returns some user data if authenticated
    const user = await auth.account.authenticate(true);
    if (!user) {
      return { name: AUTH_LOGOUT_ROUTE.ROUTE_NAME };
    }
    // check recent termsAgreement
    if (!user.acceptedTerms) {
      if (to.name !== AUTH_TERMS_AGREEMENT_ROUTE.ROUTE_NAME) {
        return { name: AUTH_TERMS_AGREEMENT_ROUTE.ROUTE_NAME, query: { redirect: to.fullPath } };
      }
      return;
    }

    if (to.name === AUTH_TERMS_AGREEMENT_ROUTE.ROUTE_NAME) {
      return { name: CONFIG_ROUTES.APP_HOME, query: { redirect: to.fullPath } };
    }
  }
}

export default UserAcceptedTermsAgreement;
