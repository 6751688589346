import { BADGE_PRINT_ROUTE } from '@/config/routes';

export default [
  {
    path: BADGE_PRINT_ROUTE.ROUTE_PATH,
    name: BADGE_PRINT_ROUTE.ROUTE_NAME,
    components: {
      default: () => import('@/views/badge/PrintBadgePage.vue')
    },
    meta: {
      fillHeight: true,
      privileges: BADGE_PRINT_ROUTE.PRIVILEGES
    }
  }
];
