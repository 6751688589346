export const faQx = {
  prefix: 'fas',
  iconName: 'qx',
  icon: [
    '512',
    '512',
    [],
    'qx000',
    'M151.7,105 C234.6,105 300.8,166.5 300.8,252.1 C300.8,289.7 288,322.2 267.16,347.3 L267.16,347.3 L295.1,375.2 L263.7,406.6 L169.8,312.7 L201,281.1 L233.9,314 C245.440137,295.428693 251.473809,273.96408 251.3,252.1 C251.3,194.4 210.7,146 151.8,146 C93.3,146 52.3,194.4 52.3,252.1 C52.3,310.2 93.3,358.2 151.8,358.2 C161.648196,358.262098 171.452343,356.880757 180.9,354.1 L180.9,354.1 L213.6,386.8 C194.014162,395.065869 172.958471,399.283811 151.7,399.2 C69.16,399.2 3,337.6 3,252.1 C3,166.6 69.16,105 151.7,105 Z M444.4,311.9 L508.9,400.1 L456.6,400.1 L419.6,347 L444.4,311.9 Z M367.2,204.6 L435.8,301.9 L366.7,400.1 L314.9,400.1 L386.6,301.9 L314.9,204.6 L367.2,204.6 Z M508.9,204.6 L444.5,292.2 L419.8,257.1 L456.6,204.6 L508.9,204.6 Z'
  ]
};

export const faMercurySecurity = {
  prefix: 'fas',
  iconName: 'mercury-security',
  icon: [
    '640',
    '512',
    [],
    'qxmercurysecurity000',
    'M381,296 L381,458 L258,458 L258,296 L381,296 Z M612,54 L612,457 L493,457 L493,174 L148,174 L148,457 L28,457 L28,54 L612,54 Z'
  ]
};

export const faMercurySecurityDownload = {
  prefix: 'fas',
  iconName: 'mercury-security-download',
  icon: [
    '640',
    '512',
    [],
    'qxmercurysecurity001',
    'M340.857494,326.22973 L340.857494,463.949631 L236.292383,463.949631 L236.292383,326.22973 L340.857494,326.22973 Z M537.237883,339.254166 L537.235872,463.099509 L436.071128,463.0995 L436.07013,347.383956 C446.887824,349.562831 458.049958,350.790331 469.465492,350.975416 L472.5,351 C495.288119,351 517.106041,346.846094 537.237883,339.254166 Z M295.074415,120.500089 C291.111273,135.500677 289,151.253781 289,167.5 C289,186.666979 291.938645,205.147601 297.389964,222.515897 L142.77887,222.514742 L142.778128,463.0995 L40.7641278,463.099509 L40.7641278,120.5 Z M493.407315,28.0375 C506.477672,28.0375 517.064069,38.6238974 517.064069,51.6942539 L517.064069,160.515322 L573.840278,160.515322 C581.469582,160.515322 588.389182,165.128389 591.346276,172.225415 C594.303371,179.322441 592.647398,187.424879 587.265486,192.865933 L492.638471,287.492948 L492.579329,287.492948 C485.186593,294.885684 473.180791,294.885684 465.788055,287.492948 L371.16104,192.865933 C365.719986,187.424879 364.123155,179.322441 367.080249,172.225415 C370.037344,165.128389 376.897802,160.515322 384.586247,160.515322 L441.362457,160.515322 L441.362457,51.6942539 C441.362457,38.6238974 451.948854,28.0375 465.019211,28.0375 L493.407315,28.0375 Z'
  ]
};
