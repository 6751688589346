export const fasTimelineIcon = {
  prefix: 'fas',
  iconName: 'qx-timeline',
  icon: [
    '640',
    '640',
    [],
    'qxtimeline01',
    'M592,72 L48,72 C21.5,72 0,93.5 0,120 L0,520 C0,546.5 21.5,568 48,568 L592,568 C618.5,568 640,546.5 640,520 L640,120 C640,93.5 618.5,72 592,72 Z M576,504 L64,504 L64,136 L576,136 L576,504 Z M168,410 L168,474 L127.1,474 C110.468,474 96.28625,458.22039 96.0042782,441.603775 L96,441.1 C96,424.3 110.3,410 127.1,410 L127.1,410 L168,410 Z M246,410 L246,474 L207,474 L207,410 L246,410 Z M388,410 L388,474 L285,474 L285,410 L388,410 Z M512,410 C529.7,410 544,424.3 544,442 C544,459.7 529.7,474 511.1,474 L511.1,474 L427,474 L427,410 Z M286.534134,204.943436 L367.539138,254.440399 C371.561681,256.91581 374.008962,261.275909 374.008962,265.973563 C374.008962,270.671218 371.561681,275.031316 367.539138,277.25356 L286.534134,326.761775 C282.365318,329.546612 277.147264,329.659131 272.888433,327.268109 C268.628757,324.877087 265.991038,320.376341 265.991038,315.481778 L265.991038,216.465348 C265.991038,211.582038 268.628757,207.078478 272.888433,204.687456 C277.147264,202.299247 282.365318,202.397701 286.534134,204.943436 Z'
  ]
};

export const falTimelineIcon = {
  prefix: 'fal',
  iconName: 'qx-timeline',
  icon: [
    '640',
    '640',
    [],
    'qxtimeline02',
    'M576,72 L64,72 C28.65,72 0,100.65 0,136 L0,504 C0,539.35 28.65,568 64,568 L576,568 C611.35,568 640,539.35 640,504 L640,136 C640,100.65 611.3,72 576,72 Z M608,494 C608,511.64 593.64,526 576,526 L64,526 C46.36,526 32,511.64 32,494 L32,136 C32,118.36 46.36,104 64,104 L576,104 C593.64,104 608,118.36 608,136 L608,494 Z M156,430 L156,462 L112,462 C103.304762,462 96.1716553,454.970408 96.0030518,446.313596 L96,446 C96,437.2 103.2,430 112,430 L112,430 L156,430 Z M221,430 L221,462 L188,462 L188,430 L221,430 Z M409,430 L409,462 L253,462 L253,430 L409,430 Z M528,430 C536.8,430 544,437.2 544,446 C544,454.836 536.838,462 528,462 L528,462 L441,462 L441,430 Z M273.648343,180.76656 L387.054005,249.905176 C392.685498,253.362892 396.11165,259.453189 396.11165,266.014992 C396.11165,272.576795 392.685498,278.667091 387.054005,281.771178 L273.648343,350.92551 C267.812069,354.815441 260.506881,354.97261 254.544587,351.63277 C248.581112,348.29293 244.88835,342.006173 244.88835,335.169324 L244.88835,196.860659 C244.88835,190.039528 248.581112,183.748841 254.544587,180.409001 C260.506881,177.07309 267.812069,177.210613 273.648343,180.76656 Z M270.380427,203.447308 C268.716706,204.382686 267.683483,206.138168 267.683483,208.045154 L267.683483,323.979381 C267.683483,325.889661 268.716706,327.635262 270.380427,328.293979 C272.047448,329.512606 274.090787,329.47967 275.718196,328.491594 L370.774722,270.52448 C372.359217,269.536405 373.316517,267.856676 373.316517,266.012268 C373.316517,264.167859 372.359217,262.488131 370.774722,261.500055 L275.718196,203.549409 C274.087486,202.554746 272.047448,202.515223 270.380427,203.447308 Z'
  ]
};
