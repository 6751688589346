import { useNetwork } from '@vueuse/core';
import { reactive, toRefs } from 'vue';

/**
 * DEV NOTE :: additional "device network logic" lives here
 * this level of abstraction is currently excessive if
 * nothing else is added.
 */
export function useDeviceNetwork() {
  const network = reactive(useNetwork())
  return {
    ...toRefs(network)
  }
}
