import {
  PERMISSIONS_ROUTE,
  PERMISSION_CREATE_ROUTE,
  PERMISSION_EDIT_ROUTE,
  PERMISSION_VIEW_ROUTE,
  PERMISSION_VIEW_INFO_ROUTE
} from '@/config/routes/permissions';

export default [
  /**
   * CONTAINER - All Permission Routes will pass through this container
   *  This container is responsible for handling any global Permission-related fetching.
   */
  {
    path: PERMISSIONS_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "container-permission" */ './PermissionContainer.vue')
    },
    meta: {
      privileges: PERMISSIONS_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * Create Permission
       */
      {
        path: PERMISSION_CREATE_ROUTE.ROUTE_PATH,
        name: PERMISSION_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: PERMISSION_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "page-permission-create" */ './PermissionCreatePage.vue')
        }
      },

      /**
       * List of Permissions
       */
      {
        path: '',
        name: PERMISSIONS_ROUTE.ROUTE_NAME,
        // meta: {},
        components: {
          default: () => import(/* webpackChunkName: "page-permission-list" */ './PermissionListPage.vue')
        }
      },

      /**
       * CONTAINER - View Permission Routes will pass through this container
       *  Parent Route is a container to fetch the Permission object
       *  Child routes (includes view page) will reference the Permission object pulled in the container
       *  This container will hold some generic/global elements for all Permission View Pages
       */
      {
        path: PERMISSION_VIEW_ROUTE.ROUTE_PATH,
        // name: PERMISSION_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: PERMISSION_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "container-permission-view" */ './PermissionContainerView.vue')
        },
        children: [
          /**
           * View Permission - Info
           */
          {
            path: PERMISSION_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: PERMISSION_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: PERMISSION_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-permission-view-info" */ './PermissionViewInfoPage.vue')
            }
          },

          // /**
          //  * View Permission - Configuration
          //  */
          // {
          //   path: PERMISSION_VIEW_CONFIG_ROUTE.ROUTE_PATH,
          //   name: PERMISSION_VIEW_CONFIG_ROUTE.ROUTE_NAME,
          //   meta: {
          //     privileges: PERMISSION_VIEW_CONFIG_ROUTE.PRIVILEGES
          //   },
          //   components: {
          //     default: () => import(/* webpackChunkName: "page-permission-view-config" */ './PermissionViewConfigPage.vue')
          //   }
          // },

          /**
           * Edit Permission
           */
          {
            path: PERMISSION_EDIT_ROUTE.ROUTE_PATH,
            name: PERMISSION_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: PERMISSION_EDIT_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-permission-edit" */ './PermissionEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];
