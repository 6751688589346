import { EnvProvider, Envs } from "@/utils/EnvProvider";
import { acceptHMRUpdate, defineStore } from "pinia";
import { useDeviceStore } from '@/store/device';
import { ConnectionStatus } from "@/types";
import { computed, ref } from "vue";

/**
 * 1. App Restart if not Valid Backend Connection
 *   * Has Valid Backend Connection
 *     - heartbeat ($backendOnline|$hasValidGraphQLClient)
 *     - network detected
 *     - systemInformation query succeeded (handled in App.tsx)
 *   * if invalid connection, restart app
 *
 * 1. Create link to handle "network error" and "server error"
 *
 * 1. Brad had a "cacheFirstHandler" wrapped in a "NetworkErrorLink"
 *    * seems like the goal was to attempt to retrieve cache
 *      data using the operation query and variables. if the
 *      cache didn't exist, he would bubble up the error.
 *
 * 1. Handle restarting of Web Sockets
 *    - can this be in the ApolloClient util?
 *    - looks like Brad only did this when the User
 *      logged out or the auth token changed. do
 *      not believe this is necessary.
 */
export const useConnectionStore = defineStore('connection', () => {

  // Device can reach server
  const reach = ref(true);

  // Server responses are normal
  const server = ref(true);

  // Heartbeat connection and updates are handled in the "ConnectionManager"
  const heartbeat = ref(true);

  // Premise environment accessed (post-replication feature) is "read-only" only
  const readOnlyMode = ref(EnvProvider.env === Envs.Gateway);

  /**
   * If the Device lost network connectivity
   * and regains it, restart the app.
   */
  const device = useDeviceStore();
  const isOnline = computed(() => device.network.isOnline);

  const isValid = computed(() => {
    return heartbeat.value
      && device.network.isOnline
      && reach.value
  });

  const status = computed<ConnectionStatus>(() => {

    if (!server.value) {
      return ConnectionStatus.ServiceOffline;
    }

    if (!reach.value || !device.network.isOnline) {
      return ConnectionStatus.NetworkOffline;
    }

    if (readOnlyMode.value) {
      return ConnectionStatus.ReadOnlyMode;
    }

    if (!heartbeat.value) {
      return ConnectionStatus.AgentOffline;
    }

    if (isValid.value) {
      return ConnectionStatus.Connected
    }

    return ConnectionStatus.Unknown;
  })

  return {
    readOnlyMode,
    isOnline,
    heartbeat,
    isValid,
    reach,
    server,
    status
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConnectionStore, import.meta.hot))
}
