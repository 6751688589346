import { ApolloLink, Operation } from "@apollo/client/core";
import { EnvProvider, Envs } from "@/utils/EnvProvider";
import { APP_PRODUCTION_URL } from "@/config/app";

/**
 * Why does this link exist?
 *  * There are 3 modes the client can operate in... Cloud, Premise, and Gateway.
 *  * Cloud and Premise work the same way, but Gateway is different.
 *
 * In Gateway mode, it is assumed that there has been some sort of connection-loss
 * to the primary source of information; maybe the internet was cut. In this case,
 * if the User is on the same network as the Gateway, we attempt to point all
 * UI connections to the Gateway. The Gateway should have a replicated set
 * of data from Cloud/Premise, but we cannot allow the User to modify
 * the data. Data replicated to the Gateway does not sync up to
 * Cloud/Premise.
 *
 * So the solution seems simple:
 *  1. If in Gateway mode...
 *  2. ...prevent ANY mutation from occurring.
 */

const ALLOW_MUTATIONS = [
  // auth
  'signout',
  'signoutEverywhere',

  // user settings - used by live
  'createUserSetting',
  'updateUserSetting',

  // minion
  'reconnectMinion',
  'rebootMinion',

  // gateways
  'sync',

  // rules
  'clearFlowStatus',

  // reports
  'accessReport',
  'accessReportInfo',
  'getDownloadAccessReportUrl',

  // dio
  'activateOutput',
  'deactivateOutput',

  // lockdown
  'lockDownPlace',
  'lockDownReleasePlace',

  // doors
  'updateDoorConfiguration',
  'updateDoorConfigurations',
  'revertDoor',
  'lockDoor',
  'unlockDoor',
  'accessDoor',
  'lockDownDoor',
  'lockDownReleaseDoor',
];

export const readOnlyModeLink = new ApolloLink((operation: Operation, forward) => {
  if (EnvProvider.env === Envs.Gateway && operation?.query?.definitions?.some?.(d => d.operation === 'mutation')) {
    const isGatewayOperation = operation.query.definitions.some(d => d.selectionSet.selections.some(s => ALLOW_MUTATIONS.includes(s.name.value)));
    if (!isGatewayOperation) {
      throw new Error(`Configuration changes may only be made from ${APP_PRODUCTION_URL}`);
    }
  }
  return forward(operation);
});
