import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { getDeepRouteTreeProperty } from './../utilities';

/**
 * Used in the `routeAuthorization` function, will run through the
 * middleware stack found in the current route and search up the
 * router tree for additional middleware for validation.
 */
export const validateMiddleware = async (context: {
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
}): Promise<void> => {

  // grab all middleware found up the route tree
  const foundMiddleware = getDeepRouteTreeProperty<Middleware[]>(context.to, 'middleware');

  // if nothing found, continue with `routeAuthorization`
  if (!foundMiddleware || !foundMiddleware?.length) {
    context.next();
    return;
  }

  // reverse the order of arrays, flatten to create a single array of all middleware found in the tree
  const middleware = foundMiddleware.reverse().flat();

  let redirect;
  for (const mw of middleware) {

    redirect = await mw({
      to: context.to,
      from: context.from
    });

    if (redirect) {
      break;
    }
  }

  if (redirect) {
    context.next(redirect);
    return;
  } else {
    context.next();
    return;
  }
};
