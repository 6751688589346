import { AUTH_LOGIN_ROUTE } from '@/config/routes/auth';
import { useAuthStore } from '@/store/auth';

/**
 * The User must have a truthy token,
 * it does not have to be valid.
 */
const ForceLogout: Middleware = async function(ctx) {
  await useAuthStore().account.logout();
  return { name: AUTH_LOGIN_ROUTE.ROUTE_NAME };
}

export default ForceLogout;
