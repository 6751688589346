import { useDevicePermissions } from './composables/use-device-permissions';
import { useSuperSaiyanMode } from './composables/use-super-saiyan-mode';
import { useDeviceNetwork } from './composables/use-device-network';
import { useDeviceAgent } from './composables/use-device-agent';
import { onBeforeUnmount, onMounted, reactive } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { getTimeZone } from '@/utils/DateTime';
import { StunTurnOverride } from '@/types';
import { useStorage } from '@vueuse/core';

export interface DeviceSettings {
  enableAdvancedSettings: boolean;
  enableDefaultViews: boolean;
  enablePlayerHud: boolean;
  enableAutoPlay: boolean;
  enableLiveBorder: boolean;
  enableIosPlayer: boolean;
  enableTrickleIce: boolean;
  enableMaxPacketLifeTime: boolean;
  enableDeveloperMode: boolean;
  forceCloudPlayback: boolean;
  stunTurnOverride: StunTurnOverride;
  timeZone: string;
  maxPlayerWorkers: number;
  // `contain` shows entire snapshot, `cover` is visually appealing
  snapshotFit: 'contain' | 'cover';
}

const defaultDeviceSettings: DeviceSettings = {
  enableAdvancedSettings: false,
  enableDefaultViews: true,
  enablePlayerHud: false,
  enableAutoPlay: true,
  enableLiveBorder: true,
  enableIosPlayer: false,
  enableTrickleIce: true,
  enableMaxPacketLifeTime: true,
  enableDeveloperMode: false,
  forceCloudPlayback: false,
  stunTurnOverride: StunTurnOverride.StunAndTurn,
  timeZone: getTimeZone(),
  maxPlayerWorkers: 1,
  snapshotFit: 'contain',
};

export const useDeviceStore = defineStore('device', () => {

  const settings = useStorage<DeviceSettings>(
    'device-settings',
    defaultDeviceSettings,
    localStorage,
    {
      mergeDefaults: true,
      listenToStorageChanges: false
    }
  );

  // logic needs to be handled globally, show only once
  onMounted(() => {});
  onBeforeUnmount(() => {});

  const network = reactive(useDeviceNetwork())
  const agent = reactive(useDeviceAgent());
  const permissions = reactive(useDevicePermissions());
  const isSuperSaiyan = reactive(useSuperSaiyanMode());

  return {
    // localStorage, global settings, managed under Settings route
    settings,
    // network information about the device
    network,
    // user agent, more device info, etc
    agent,
    //
    // location: useGeolocation
    permissions,
    // screen: useScreenOrientation | useDeviceOrientation | useDevicePixelRatio
    // vibrate: useVibrate,
    isSuperSaiyan
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceStore, import.meta.hot))
}
