import { ref } from "vue";

export function useFullScreen() {
  const isFullscreen = ref(false);
  const playerID = ref('');

  const toggle = (val: boolean, id?: string) => {
    const html = document.getElementsByTagName('html')[0];

    if (val && !html.classList.contains('fullscreen')) {
      isFullscreen.value = true;
      html.classList.add('fullscreen');
      if (!!id && playerID.value != id) {
        playerID.value = id;
      }
    } else {
      isFullscreen.value = false;
      html.classList.remove('fullscreen');
      playerID.value = '';
    }
  };

  return {
    isFullscreen,
    playerID,
    toggle
  }
}
