export interface UserSettingValue {
  schemaVersion: number;
  [key: string]: unknown;
}

export interface ScopeSettingMigrationMeta {
  personID: string, // person.id
  placeID: string,  // person.placeID
}

export interface UserSetting {
  id: string;
  name: string;
  value: UserSettingValue;
  update: <T = UserSettingValue>(value: T) => Promise<boolean>;
  delete: () => Promise<boolean>;
}

export interface CreateUserSetting {
  name: string,
  value: UserSettingValue
}

export type UserSettings = Record<string, UserSetting> & {
  create: (name: string, value: UserSettingValue) => Promise<boolean>
}

// Create types for each Setting... need this for migrations if we ever change the structure of a setting
export enum UserSettingsName {
  CurrentScope = 'currentScopeSettings',
  EventSearch = 'eventSearches',
  Layout = 'layoutSettings',
  PrivateViews = 'privateViews',
}
