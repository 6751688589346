<template>
  <AuthManager v-slot="{ appKey }">
    <ConnectionManager :key="`connection.${appKey}`">
      <App :key="`app.${appKey}`" />
      <DialogManager :key="`dialog.${appKey}`" />
    </ConnectionManager>
  </AuthManager>
</template>

<script setup lang="ts">
import { ConnectionManager } from '@/store/connection/manager';
import DialogManager from '@/store/dialog/DialogManager.vue';
import { useAppMeta } from '@/composables/use-app-meta';
import { AuthManager } from '@/store/auth/manager';
import App from '@/views/App.vue';
useAppMeta();
</script>
