import { isNotRootPlace } from '@/models/place/utilities/is-place';
import { qx_ePrivilege_enum } from '@/graphql/types';
import { CONFIG_ROUTES } from '@/config/routes';
import { useAuthStore } from '@/store/auth';

export const canManageRules = async (): Promise<boolean> => {
  const auth = useAuthStore();
  await auth.waitForReady();
  const notRoot = isNotRootPlace(auth.scope.current.id)
  const isAllowed = auth.user.hasPrivilege(qx_ePrivilege_enum.RulesConfig)
  return notRoot && isAllowed
};

export const CanManageRulesMiddleware: Middleware = async function() {
  if (!(await canManageRules())) {
    return {
      name: CONFIG_ROUTES.APP_HOME
    }
  }
};
