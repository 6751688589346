import { qx_ePrivilege_enum } from '@/graphql/types';
import { LIVE_ROUTE } from './live';
import { AUTH_LOGIN_ROUTE } from './auth';

export * from './live';
export * from './search';
export * from './doors';
export * from './places';
export * from './search';
export * from './people';
export * from './badge';
export * from './groups';
export * from './cameras';
export * from './permissions';
export * from './roles';
export * from './views';
export * from './saved-clips';
export * from './gateways';
export * from './schedules';
export * from './settings';
export * from './help';
export * from './integrations';
export * from './holiday-sets';

export interface QxRouteObject {
  UI_ICON: string[];
  UI_LABEL: string;
  ROUTE_PATH: string;
  ROUTE_NAME: string;
  PRIVILEGES: qx_ePrivilege_enum[];
}

export const CONFIG_ROUTES = Object.freeze({
  // PUBLIC_PATH_PREFIX: '',
  AUTH_PATH_PREFIX: '/auth',
  APP_PATH_PREFIX: '/qx',
  APP_HOME: 'app.home',
  APP_HOME_REDIRECT: LIVE_ROUTE.ROUTE_NAME,
  AUTH_LOGIN: AUTH_LOGIN_ROUTE.ROUTE_NAME,
  WILDCARD: '*',
  APP_404: 'app.404',
  PUBLIC_404: 'public.404',
  QUERY_REDIRECT_KEY: 'redirect',
  QUERY_NOTIFICATION_KEY: 'notify'
});
