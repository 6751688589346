import { toSentenceCase } from "@/functions/string";

export enum PwaState {
  Checking = 'checking', // UI-created
  Installing = 'installing',
  Installed = 'installed', // same as saying "Needs Refreshed"
  Activated = 'activated', // when it is loaded/ready/on-latest
  UpdateFound = 'update-found', //

  Active = 'active', // hopefully it never hits this state
  Waiting = 'waiting', // hopefully it never hits this state
}

export const PWA_DEFAULT_ICON = 'browser';

export function getPwaStatusIcon(status: PwaState) {

  // Do not show if on the latest version.
  if (PwaState.Activated === status) {
    return false;
  }

  let color = 'grey';
  let tooltip = toSentenceCase(status || '');
  let disabled = false;

  switch (status) {
    case PwaState.Checking:
      return {
        value: PwaState.Checking,
        color: 'white',
        iconStyle: 'fas',
        icon: PWA_DEFAULT_ICON,
        tooltip: 'Checking for Updates',
        disabled: true,
        mask: 'circle',
        transform: 'shrink-6 right-8 up-6',
        subColor: 'white',
        subIcon: 'spinner',
        subTransform: 'shrink-8 right-8 up-6',
        subClass: 'fa-pulse'
      }

    case PwaState.Installed:
      return {
        value: PwaState.Installed,
        color: 'white',
        iconStyle: 'fas',
        icon: PWA_DEFAULT_ICON,
        tooltip: 'Click to Update App',
        disabled,
        mask: 'circle',
        transform: 'shrink-6 right-8 up-6',
        subColor: 'success',
        subIcon: 'check-circle',
        subTransform: 'shrink-8 right-8 up-6',
        subClass: ''
      }

    case PwaState.Installing:
      return {
        value: PwaState.Installing,
        color: 'yellow',
        iconStyle: 'fas',
        icon: PWA_DEFAULT_ICON,
        tooltip: 'Downloading Updates',
        disabled: true,
        mask: 'circle',
        transform: 'shrink-6 right-8 up-6',
        subColor: 'white',
        subIcon: 'spinner',
        subTransform: 'shrink-8 right-8 up-6',
        subClass: 'fa-pulse'
      }

    case PwaState.UpdateFound:
      return {
        value: PwaState.UpdateFound,
        color: 'white',
        iconStyle: 'fas',
        icon: PWA_DEFAULT_ICON,
        tooltip: 'Update Available',
        disabled: true,
        mask: 'circle',
        transform: 'shrink-6 right-8 up-6',
        subColor: 'white',
        subIcon: 'spinner',
        subTransform: 'shrink-8 right-8 up-6',
        subClass: 'fa-pulse'
      }

    default:
      return {
        color,
        iconStyle: 'fas',
        icon: PWA_DEFAULT_ICON,
        tooltip,
        disabled,
      }
  }
}
