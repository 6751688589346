import { hasAtLeastOneValidCondition } from './rule-conditions';
import { FlowGuardOperator, FlowGuard, FlowTimeCustomOperators } from 'qx-flow-orm';
import { defineAsyncComponent, Component } from 'vue';
import { RULE_FORM_ERRORS } from '@/config/rule';
import { asyncSome } from '@/functions/array';
import { RuleCondition } from '../types';
import { FAIcon } from '@/types';

export type GuardComponentType = {
  name: FlowGuardOperator, // only used by RuleGuardsAddGuard.vue
  icon: FAIcon;
  title: string;
  desc: string;
  form: FlowGuard;
  validate: (args: unknown) => Promise<boolean>;
  displayComponent: Component;
  formComponent: Component;
};

export type GuardComponentMapType = {
  [key in FlowGuardOperator]: GuardComponentType
};

// TODO :: fix this type
type GuardDelayForm = FlowGuard & {
  options: {
    conditions: RuleCondition[]
  }
}

export const guardComponentMap: GuardComponentMapType = {
  [FlowGuardOperator.Occurrence]: {
    name: FlowGuardOperator.Occurrence,
    icon: ['fas', 'repeat-1-alt'],
    title: 'Repeat Guard',
    desc: 'Identify repetitious behavior',
    form: {
      type: FlowGuardOperator.Occurrence,
      options: {
        expires: {
          type: FlowTimeCustomOperators.SecondsAgo,
          value: 15
        },
        value: 3
      }
    },
    validate: async (form: GuardDelayForm) => {
      try {
        return true
      } catch (error) {
        throw error
      }
    },
    // TODO - Don't have components for occurrence guard
    //displayComponent: defineAsyncComponent(() => import('@/models/rule/components/guards/RuleGuardOccurrence.vue')),
    //formComponent: defineAsyncComponent(() => import('@/models/rule/components/guards/RuleGuardOccurrenceForm.vue'))
  },
  // '_release': {
  //   name: '_release',
  //   icon: ['fas', 'recycle'],
  //   title: 'Release Guard',
  //   desc: 'Reset the rule',
  //   form: {
  //     type: '_release',
  //     options: {
  //       conditions: []
  //     }
  //   },
  // },
  [FlowGuardOperator.Suppress]: {
    name: FlowGuardOperator.Suppress,
    icon: ['fas', 'volume-xmark'],
    title: 'Suppression Guard',
    desc: 'Fire actions only once',
    form: {
      type: FlowGuardOperator.Suppress,
      options: {
        delay: 10,
        conditions: [{ _and: [] }]
      }
    },
    // TODO :: fix this type
    validate: async (form: GuardDelayForm) => {
      try {
        if (!hasAtLeastOneValidCondition(form?.options?.conditions || [])) {
          throw RULE_FORM_ERRORS.RULE_GUARD_DELAY_MISSING_CONDITIONS
        }
        return true
      } catch (error) {
        throw error
      }
    },
    displayComponent: defineAsyncComponent(() => import('@/models/rule/components/guards/RuleGuardDelay.vue')),
    formComponent: defineAsyncComponent(() => import('@/models/rule/components/guards/RuleGuardDelayForm.vue'))
  }
};

type RuleGuardGeneric = {
  type: keyof GuardComponentMapType;
  options: Record<string, any>;
};

export const getRuleGuardIcon = (guard: RuleGuardGeneric): FAIcon => {
  switch (true) {
    case guard.type in guardComponentMap:
      return guardComponentMap[guard.type].icon;

    default:
      return ['fas', 'circle-question'];
  }
};

export const validateGuards = async (guards: RuleGuardGeneric[]): Promise<boolean> => {
  try {
    const hasInvalidGuards = await asyncSome<RuleGuardGeneric>(
      guards,
      async (guard): Promise<boolean> => {
        try {
          // if guard does not exist, assume user is in advance mode
          if (!guardComponentMap?.[guard.type]) {
            return true;
          }
          const { validate } = guardComponentMap[guard.type];
          const isValid = await validate(guard);
          return !isValid
        } catch (error) {
          throw error
        }
      }
    )
    return !hasInvalidGuards

  } catch (error) {
    throw error
  }
}

export const hasUniqueGuards = (guards: RuleGuardGeneric[]): boolean => {
  try {
    /**
     * Returns an object with each key representing a
     * type of guard and its value representing the
     * number of times it is seen in the array.
     */
    const guardTypeCount = guards.reduce((acc, guard) => {
      if (!(guard.type in acc)) {
        acc[guard.type] = 0
      }
      acc[guard.type] += 1
      return acc
    }, {} as Record<string, number>)

    /**
     * Grabs all the values and filters it
     * down to see if there are any with
     * a value of more than 1.
     */
    const hasDuplicateGuards = Object.values(guardTypeCount)
      .filter((val: number) => val > 1)
      .length > 0;

    // Return true if there are no duplicates
    return !hasDuplicateGuards

  } catch (error) {
    throw error
  }
}
