import { isReadOnlySchedule } from '@/models/schedule/utilities/is-read-only-schedule';
import {
  SCHEDULE_VIEW_INFO_ROUTE,
  SCHEDULE_CREATE_ROUTE,
  SCHEDULE_VIEW_ROUTE,
  SCHEDULE_EDIT_ROUTE,
  SCHEDULES_ROUTE
} from '@/config/routes';

// middleware to prevent users from attempting to visit the edit page of Read Only Schedules
const ScheduleIsNotReadOnly: Middleware = async function(ctx) {
  if (isReadOnlySchedule(ctx.to.params?.[SCHEDULE_VIEW_ROUTE.ROUTE_PARAM as string])) {
    return { name: SCHEDULES_ROUTE.ROUTE_NAME };
  }
}

export default [
  {
    path: SCHEDULES_ROUTE.ROUTE_PATH,
    components: {
      default: () => import('./SchedulesContainer.vue')
    },
    meta: {
      privileges: SCHEDULES_ROUTE.PRIVILEGES
    },
    children: [
      {
        path: '',
        name: SCHEDULES_ROUTE.ROUTE_NAME,
        components: {
          default: () => import('./SchedulesListPage.vue')
        }
      },
      {
        path: SCHEDULE_CREATE_ROUTE.ROUTE_PATH,
        name: SCHEDULE_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: SCHEDULE_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import('./ScheduleCreatePage.vue')
        }
      },
      {
        path: SCHEDULE_VIEW_ROUTE.ROUTE_PATH,
        meta: {
          privileges: SCHEDULE_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import('./ScheduleContainerView.vue')
        },
        children: [
          {
            path: SCHEDULE_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: SCHEDULE_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: SCHEDULE_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import('./ScheduleViewInfoPage.vue')
            }
          },
          {
            path: SCHEDULE_EDIT_ROUTE.ROUTE_PATH,
            name: SCHEDULE_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: SCHEDULE_EDIT_ROUTE.PRIVILEGES,
              middleware: [ScheduleIsNotReadOnly],
            },
            components: {
              default: () => import('./ScheduleEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];


