import { qx_eReaderProtocol_enum } from '@/graphql/types';

export const hasDeviceProp = {
  props: {
    device: {
      required: false,
      type: Object,
      default: undefined
    }
  }
};

export const hasDeviceProtocol = {
  props: {
    deviceProtocol: {
      required: false,
      default: qx_eReaderProtocol_enum.Unknown,
      type: String
    }
  }
};
