<template>
  <DeviceIcon v-if="showIcon"
    :gatewayConnected="!gatewayIsDisconnected"
    :connection="connection"
    :pacs="pacs"
    :pacs-hardware="pacsHardware"
  />
  <span v-else-if="showLabel">
    {{ label }}
  </span>
</template>

<script lang="ts">
import { smartIconValidator } from './helpers/smart-icon-validator';
import { getDeviceStatusIcon } from '@/constants/device';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    door: {
      type: Object,
      required: true
    },
    type: {
      required: false,
      default: "icon",
      validator(value) {
        return smartIconValidator(value);
      }
    }
  },
  computed: {
    gatewayIsDisconnected() {
      return !(this.door?.gatewayConnectionStatus || false)
    },
    connection() {
      return this.door?.deviceConnectionStatus || undefined
    },
    pacs() {
      return this.door?.devicePacsStatus || undefined
    },
    pacsHardware() {
      return this.door?.devicePacsHWUpdate || undefined
    },
    label() {
      const { gatewayIsDisconnected, connection, pacs, pacsHardware } = this
      const status = getDeviceStatusIcon(!gatewayIsDisconnected, connection, pacs, pacsHardware)
      return status?.tooltip || ''
    },
    showIcon() {
      return this.type === "icon";
    },
    showLabel() {
      return this.type === "label";
    }
  }
});
</script>
