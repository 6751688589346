/**
 * @function daysUntil calculate the number of days between two dates
 * @param {string} toDateISO end date to calculate for, in ISO format
 * @param {string | undefined} fromDateISO start date to calculate for, in ISO format. defaults to current day
 * @returns {number} number of days between dates
 */

export const daysUntil = (toDateISO: string, fromDateISO: string | undefined = undefined): number => {
  try {
    const toDate: Date = new Date(toDateISO);
    const fromDate: Date = fromDateISO ? new Date(fromDateISO) : new Date();
    const diff: number = (toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24);
    return Math.round(diff);
  } catch (error) {
    console.error('Error calculating `daysUntil`:', error);
    throw error;
  }
};
