import { VENDOR_STAFF } from "./app";

export const AUTH_LOCAL_STORAGE_TOKEN_KEY = 'qx.control.auth';

// No User Role or Firebase Account associated with our app.
export const AUTH_MESSAGE_NOT_AUTHORIZED =
  `Not authorized. Confirm with your ${VENDOR_STAFF} that you are authorized to use QxControl.`;

// User attempted Email/Password sign in, but is registered under Provider sign in.
export const AUTH_MESSAGE_USE_PROVIDER = 'Please use the Sign in with "Google" option.';

// Invalid Username and/or Password.
export const AUTH_MESSAGE_INVALID_CREDENTIALS = 'Invalid credentials.';
export const AUTH_MESSAGE_MISSING_PLACE = `Contact your ${VENDOR_STAFF} and have them confirm that you were provided a "Place" within QxControl.`;
export const AUTH_MESSAGE_PASSWORD_RESET_EMAIL_SENT = 'If the email address exists in our system a password reset email will be sent.';
export const AUTH_MESSAGE_PASSWORD_RESET_EMAIL_SENT_FAIL = 'Failed to send password reset email.';

// User attempted to login but first needs to verify their email.
export const AUTH_MESSAGE_EMAIL_VERIFICATION_SENT =
  'An email has been sent. Login again after responding to the email verification message.';

export const AUTH_LOGIN_LABEL_SIGN_IN = 'Sign in';
export const AUTH_LOGIN_DESCRIPTION = 'Enter your credentials to access QxControl.';
export const AUTH_LOGIN_PROVIDER_GOOGLE = 'Sign in with Google';
export const AUTH_LOGIN_PROVIDER_PASSKEY = 'Sign in with Passkey';
export const AUTH_SIGN_OUT_EVERYWHERE = 'This will log you out of your current session. Are you sure you want to sign out everywhere?';
export const AUTH_SIGN_OUT_SESSION = 'This will log out the selected session. Are you sure you want to sign this session out?';
export const AUTH_2FA_ENABLED_WARNING = "2FA enabled. You will be redirected and required to verify your account using 2FA.";
export const AUTH_2FA_REMOVED = "2FA successfully removed.";
export const AUTH_DELETE_PASSKEY = 'The passkey will be deleted, but any sessions utilizing the passkey will remain logged in. Do you want to continue?';
