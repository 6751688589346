import type { App } from 'vue';

export default async (app: App) => {

  // will need access to the html element for code below
  const root = document.querySelector('html');

  // Append className `pwa` to the HTML element when the app is in PWA mode
  if (window.matchMedia('(display-mode: standalone)').matches) {
    if (!root?.classList.contains('pwa')) {
      root?.classList.add('pwa');
    }
  }

  return;
};
