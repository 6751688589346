import { AUTH_MESSAGE_NOT_AUTHORIZED } from '@/config/auth';
import { AUTH_LOGOUT_ROUTE } from '@/config/routes/auth';
import { useNotifyStore } from '@/store/notify';
import { useAuthStore } from '@/store/auth';

/**
 * The User must have a role assigned to
 * them, otherwise force them to logout.
 */
const UserHasRole: Middleware = async function(ctx) {
  const auth = useAuthStore();
  if (!auth.user.role) {
    const user = await auth.account.authenticate(true);
    if (!user || !user?.role) {
      useNotifyStore().error(AUTH_MESSAGE_NOT_AUTHORIZED)
      console.error('User does not have an assigned role. Redirecting to logout.');
      return { name: AUTH_LOGOUT_ROUTE.ROUTE_NAME, query: { redirect: ctx.to.fullPath } };
    }
  }
}

export default UserHasRole;
