import { APP_WINDOW_KEY } from '@/config/app';
import { createApp, App, h } from 'vue';

// Import and register the routes
import router from '@/router';

// Import the application's wrapper component
import RootComponent from './RootComponent.vue';

// Import client plugins to register
import { registerPlugins } from '@/plugins';

// Provide the Apollo GraphQL client to the app
import { ApolloClient, ApolloClientDefaultKey } from '@/utils/ApolloClient';

// Create and Mount the App
export async function createAndMountApp(): Promise<App> {

  try {
    const app = createApp({
      render: () => h(RootComponent),
      devtools: {
        hide: import.meta.env.PROD,
      }
    })

    // Provide Apollo Client Access to the `@vue/apollo-composable` package
    app.provide(ApolloClientDefaultKey, ApolloClient);

    // Connect Routes
    app.use(router);

    // Register Plugins
    await registerPlugins(app);

    // Mount the app to the DOM
    app.mount('#app');

    // Bind to the window
    Object.assign(window, { [APP_WINDOW_KEY]: app });

    return app;

  } catch (error) {
    throw error
  }
}
