export const faLiveSearchNoPeople = {
  prefix: 'fas',
  iconName: 'live-search-no-people',
  icon: [
    '512',
    '512',
    [],
    'qxr001',
    'M349.185697,463.920223 L280.247452,394.799043 C277.135896,391.679231 272.918009,389.946001 268.492686,389.946001 L257.221939,389.946001 C276.306148,365.472805 287.64604,334.690655 287.64604,301.204668 C287.64604,221.545455 223.271409,157 143.82302,157 C64.3746305,157 0,221.545455 0,301.204668 C0,380.86388 64.3746305,445.409335 143.82302,445.409335 C177.220385,445.409335 207.921068,434.039352 232.329494,414.904502 L232.329494,426.205156 C232.329494,430.642222 234.058136,434.871302 237.169691,437.991114 L306.107937,507.112294 C312.607631,513.629235 323.117775,513.629235 329.548323,507.112294 L349.116552,487.492139 C355.616246,480.975198 355.616246,470.437164 349.185697,463.920223 Z M143.82302,212.463334 C192.709017,212.463334 232.329494,252.119617 232.329494,301.204668 C232.329494,350.220389 192.778163,389.946001 143.82302,389.946001 C94.9370222,389.946001 55.3165461,350.289718 55.3165461,301.204668 C55.3165461,252.188946 94.8678765,212.463334 143.82302,212.463334 Z M358.003624,125 L455.034371,200 L305.73827,200 C295.386761,200 286.988367,191.601562 286.988367,181.25 L286.988367,181.25 L286.988367,177.5 C286.988367,148.515625 310.50387,125 339.488095,125 L339.488095,125 L358.003624,125 Z M386.987849,0 C418.042375,0 443.237557,25.1953125 443.237557,56.25 C443.237557,77.3828125 431.440743,95.5859375 414.175208,105.195313 L414.175208,105.195313 L509.565338,178.945312 C512.299699,181.054688 512.807509,185 510.698145,187.65625 L510.698145,187.65625 L503.041935,197.539062 C500.893508,200.273438 496.987279,200.742188 494.252918,198.632812 L494.252918,198.632812 L264.410359,21.015625 C261.675998,18.90625 261.207251,15 263.316615,12.265625 L263.316615,12.265625 L270.972825,2.421875 C273.121251,-0.3125 277.027481,-0.78125 279.761842,1.328125 L279.761842,1.328125 L332.691255,42.265625 C338.941223,18.0078125 360.777047,0 386.987849,0 Z'
  ]
};

export const faRolePrivilege = {
  prefix: 'fas',
  iconName: 'role-privilege',
  icon: [
    '577',
    '577',
    [],
    'qxr002',
    'M352,353 C369.649707,353 383.966907,367.290324 384,384.94 C384.007087,386.769968 383.85316,388.597012 383.54,390.4 C381,406.1 366.19,417 350.29,417 L350.29,417 L272,417 C263.163444,417 256,424.163444 256,433 C256,441.836556 263.163444,449 272,449 L272,449 L390.27,449 C404.811215,449.040234 418.927682,444.099471 430.27,435 L430.27,435 L522.67,361.1 C535.07,351.1 553.47,350.4 565.27,361.1 L565.27,361.1 L565.28,361.1 C572.325303,367.36667 576.248209,376.42155 576.00148,385.847397 C575.75475,395.273243 571.363502,404.110493 564,410 L564,410 L412.77,531 C401.41349,540.073854 387.306334,545.011358 372.77,545.00002 L372.77,545.00002 L16,545.00002 C7.163444,545.00002 0,537.836556 0,529 L0,529 L0,433 C0,424.163444 7.163444,417 16,417 L16,417 L71.4,417 L117.9,379.29 C138.8546,362.288963 165.016107,353.007101 192,353 L192,353 Z M288.75,33.000489 C330.75,32.8509819 365,67.000851 365,109.00069 L365,109.00069 L365,117.000659 C365,123.650634 359.65,129.000613 353,129.000613 L353,129.000613 L337,129.000613 C330.35,129.000613 325,123.650634 325,117.000659 L325,117.000659 L325,109.00069 C325,89.0507664 308.65,72.8008287 288.65,73.000828 C268.85,73.2008272 253,89.6507641 253,109.450688 L253,109.450688 L253,161.000489 L377,161.000489 C390.25,161.000489 401,171.750449 401,185.000399 L401,185.000399 L401,265.000092 C401,278.250041 390.25,289 377,289 L377,289 L201,289 C187.75,289 177,278.250041 177,265.000092 L177,265.000092 L177,185.000399 C177,171.750449 187.75,161.000489 201,161.000489 L201,161.000489 L213,161.000489 L213,109.750687 C213,67.7508481 246.75,33.1509807 288.75,33.000489 Z M289,193.000368 C277.95,193.000368 269,201.950334 269,213.000291 L269,213.000291 L269,237.000199 C269,248.050157 277.95,257.000123 289,257.000123 C300.05,257.000123 309,248.050157 309,237.000199 L309,237.000199 L309,213.000291 C309,201.950334 300.05,193.000368 289,193.000368 Z'
  ]
};
