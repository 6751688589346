import { ConfigRouteObject } from '@/types';
import { qx_ePrivilege_enum } from '@/graphql/types';

// name used between two route names
const CAMERA_VIEW_ROUTE_NAME_SHARED = 'camera.view';

export const CAMERAS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'video'],
  UI_LABEL: 'Cameras',
  ROUTE_PATH: 'cameras',
  ROUTE_NAME: 'cameras.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.CameraView]
};

export const CAMERA_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'video'],
  UI_LABEL: 'Create Camera',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'camera.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.CameraConfig]
};

export const CAMERA_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Camera',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'camera.edit',
  ROUTE_PARAM: 'cameraId',
  PRIVILEGES: [qx_ePrivilege_enum.CameraConfig]
};

export const CAMERA_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'video'],
  UI_LABEL: 'View Camera',
  ROUTE_PATH: ':cameraId',
  ROUTE_NAME: CAMERA_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'cameraId',
  PRIVILEGES: [qx_ePrivilege_enum.CameraView]
};

export const CAMERA_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: CAMERA_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'cameraId',
  PRIVILEGES: [qx_ePrivilege_enum.CameraView]
};

export const CAMERA_VIEW_CONFIG_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'sitemap'],
  UI_LABEL: 'Settings', // 'Configuration',
  ROUTE_PATH: 'configuration',
  ROUTE_NAME: 'camera.view.config',
  ROUTE_PARAM: 'cameraId',
  PRIVILEGES: [qx_ePrivilege_enum.CameraConfig]
};
