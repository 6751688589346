import { FAIcon, GenericObject } from '@/types';
import { Envs } from '@/utils/EnvProvider';
import { Component } from 'vue';
import {
  FlowConditionOperator,
  FlowTriggerCondition,
  FlowGuardOperator,
  FlowGroupOperator
} from 'qx-flow-orm';

export enum RuleGuardRepeatExpiresType {
  Seconds = '_secondsAgo'
}

export enum FlowTriggerType {
  Event = 'flow_event',
  Scheduled = 'scheduled_at'
}

export type RuleGuardRepeat = {
  match: string[];
  value: number;
  expires: {
    type: RuleGuardRepeatExpiresType;
    value: number;
  };
};

export type RuleCondition = FlowTriggerCondition & {
  uuid?: string;
};

export type RuleConditionObject = {
  model: string;
  modelID?: string;
  field: string;
  type: string;
  operator: string;
  operators: string[];
  options: unknown[];
  value: unknown | unknown[]; // the raw value in the DB
  displayValue?: unknown | unknown[]; // the formatted value, if a `displayValue` method is available on the `model.field`
};

export type FlowConditionEnumValue = { type: '_enum'; value: string };

export type RuleConditionGroupOperatorValue = FlowGroupOperator | FlowConditionOperator | FlowGuardOperator;

// DEV NOTE :: use this over the one generated in @/graphql/types
export type FlowAction = {
  arguments?: { name: string, type: string }[];
  environments: string;
  name: string;
}

/**
 * Currently used by the UI to determine
 * which component to load based off
 * of the flow action's `type`.
 */
export enum FlowActions {
  NotificationEmail = 'flow_email_notification',
  NotificationSms = 'flow_sms_notification',
  TriggerOutput = 'flow_triggerOutput',
  ResetOutput = 'flow_resetOutput',
  LockDownPlace = 'flow_lockDownPlace',
  ReleaseLockDownPlace = 'flow_releaseLockDownPlace',
  Webhook = 'flow_webhook',
  RevertDoor = 'flow_revertDoor',
  PtzMoveToPreset = 'flow_ptzMoveToPreset',
  LockDoor = 'flow_lockDoor',
  LockDownDoor = 'flow_lockDownDoor',
  ReleaseLockDownDoor = 'flow_releaseLockDownDoor',
  UnlockDoor = 'flow_unlockDoor',
  AccessDoor = 'flow_accessDoor',
  Unknown = 'unknown',
}

/**
 * The form state for a Rule Action.
 */
export type RuleAction = {
  // used by actions that are only in UI-state, not yet saved to the DB
  create?: boolean;
  // helps with identifying unique actions in the UI
  uuid?: string;
  type: FlowActions;
  arguments: Record<string, unknown>;
  rateLimit: number | undefined;
};

/**
 * Structure of RuleAction Argument Overrides
 */
export type ActionArgumentRender = {
  argument: string;
  label: string;
  description: string;
  isRequired: boolean;
  isType: string;
  isArray: boolean;
  displayComponent: Component;
  formComponent: Component;
  validate: (arg: unknown) => Promise<boolean>;
  props: ((action: RuleAction) => GenericObject) | GenericObject | null;
}

/**
 * The necessary structure for a RuleAction
 * to be properly utilized in the UI.
 */
export type RuleActionOverrideType = {
  // ...merge getFlowAction data via useRuleActions
  name: string;
  environments: Envs[];
  // ...merge UI overrides via useRuleActions
  icon: FAIcon;
  title: string;
  desc: string;
  // ...generated via useRuleActions
  form: RuleAction;
  // ...generated via config function
  arguments: {
    [key: string]: Partial<ActionArgumentRender>;
  };
  validate: (args: unknown) => Promise<boolean>;
  displayComponent: Component;
  formComponent: Component;
};

/**
 * Generated config for a given RuleAction
 * that helps build the UI around it.
 */
export type RuleActionConfig = {
  title: string;
  desc: string | undefined;
  icon: FAIcon;
  validate: (args: unknown) => Promise<boolean>;
  displayComponent: string | Component;
  formComponent: string | Component;
  arguments: ActionArgumentRender[];
  environments?: Envs[] | undefined;
  form?: RuleAction | undefined;
  action: RuleAction;
};
