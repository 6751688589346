export const faSensorOff = {
  prefix: 'fas',
  iconName: 'sensor-off',
  icon: [
    '512',
    '512',
    [],
    'qxdevice01',
    'M32,179.006 L96,228.517 L96,272 C96,280.731359 102.993901,287.829205 111.685129,287.996964 L112,288 C120.836556,288 128,280.836556 128,272 L128,272 L128,253.272 L161.836072,279.448931 C163.968175,283.494702 167.782925,286.515937 172.349321,287.581634 L420.843162,479.819496 C419.244429,479.939115 417.629325,480 416,480 L416,480 L96,480 C60.653776,480 32,451.346224 32,416 L32,416 L32,179.006 Z M416,32 C451.346224,32 480,60.653776 480,96 L480,96 L480,396.476 L507.059093,417.409778 C512.635162,421.749832 513.643174,429.79735 509.307121,435.386571 L493.594927,455.621473 C489.258874,461.210694 481.210775,462.211629 475.626706,457.871575 L4.94090697,93.7474305 C-0.635161726,89.4073763 -1.64317414,81.3598587 2.69287928,75.7706376 L18.4050728,55.5357353 C22.7411263,49.9465141 30.7892254,48.9375716 36.3732942,53.2856333 L43.7684652,59.0063787 C55.3639974,42.6642334 74.4366161,32 96,32 L96,32 Z M240,96 C231.163444,96 224,103.163444 224,112 L224,112 L224,198.434 L256,223.189 L256,112 C256,103.268641 249.006099,96.1707953 240.314871,96.0030364 Z M176,96 C167.163444,96 160,103.163444 160,112 L160,112 L160,148.923 L192,173.678 L192,112 C192,103.268641 185.006099,96.1707953 176.314871,96.0030364 Z M112,96 C106.910747,96 102.376462,98.376095 99.4459881,102.07944 L128,124.168 L128,112 C128,103.268641 121.006099,96.1707953 112.314871,96.0030364 Z'
  ]
};
