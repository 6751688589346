import { GATEWAY_CONNECTION_STATUS_LABEL } from '@/config/gateway';
import { qx_eGatewaySyncStatus_enum } from '@/graphql/types';
import { FaIconComplex } from '@/types';
import { EnvProvider, Envs } from '@/utils/EnvProvider';

// font-awesome icon
export const GATEWAY_DEFAULT_ICON = 'hdd';

// status icon
export const GATEWAY_STATUS_ICON = {
  online: {
    color: 'success',
    iconStyle: 'fas',
    icon: GATEWAY_DEFAULT_ICON,
    value: GATEWAY_CONNECTION_STATUS_LABEL.CONNECTED,
    tooltip: GATEWAY_CONNECTION_STATUS_LABEL.CONNECTED
  },
  offline: {
    color: 'danger',
    iconStyle: 'fas',
    icon: GATEWAY_DEFAULT_ICON,
    value: GATEWAY_CONNECTION_STATUS_LABEL.DISCONNECTED,
    tooltip: GATEWAY_CONNECTION_STATUS_LABEL.DISCONNECTED
  }
};

interface GatewayIconOptions {
  syncStatus?: qx_eGatewaySyncStatus_enum,
  disabled?: boolean
}

export const getGatewayIconWithSyncStatus = (
  connectionStatus: boolean,
  opts: GatewayIconOptions
): FaIconComplex => {

  // do not show sync status information on Premise
  const isPremiseMode = EnvProvider.env === Envs.Premise;

  if (opts.disabled) {
    return {
      color: 'grey',
      iconStyle: 'fas',
      icon: 'hdd-slash',
      tooltip: false
    }
  }

  // if no status defined, this is a generic use icon
  if (connectionStatus === undefined) {
    return {
      color: 'grey',
      iconStyle: 'fas',
      icon: GATEWAY_DEFAULT_ICON,
      tooltip: false
    }
  }

  const baseIcon = GATEWAY_STATUS_ICON[connectionStatus ? 'online' : 'offline'];
  let expandedIcon = {};

  if (!connectionStatus) {
    return {
      ...baseIcon,
      ...((isPremiseMode ? false : opts.syncStatus) ? {
        // TODO :: replace syncStatus value with comment that contains spaces
        tooltip: `${baseIcon.tooltip} - Last Known Status: ${opts.syncStatus}`,
      } : {})
    };
  }

  if (!isPremiseMode) {
    switch(opts.syncStatus) {
      case qx_eGatewaySyncStatus_enum.Synced:
        expandedIcon = {
          tooltip: 'Synced',
        }
        break;
      case qx_eGatewaySyncStatus_enum.Queued:
        expandedIcon = {
          tooltip: 'Queued',
          mask: 'circle',
          transform: 'shrink-6 right-8 up-6',
          subColor: 'white',
          subIcon: 'clock',
          subTransform: 'shrink-8 right-8 up-6',
        }
        break;
      case qx_eGatewaySyncStatus_enum.Syncing:
        expandedIcon = {
          tooltip: 'Syncing',
          mask: 'circle',
          transform: 'shrink-6 right-8 up-6',
          subColor: 'white',
          subIcon: 'spinner',
          subTransform: 'shrink-8 right-8 up-6',
          subClass: 'fa-pulse'
        }
        break;
      case qx_eGatewaySyncStatus_enum.OutOfSync:
        expandedIcon = {
          tooltip: 'Out Of Sync',
          mask: 'circle',
          transform: 'shrink-6 right-8 up-6',
          subColor: 'orange',
          subIcon: 'circle-exclamation',
          subTransform: 'shrink-8 right-8 up-6',
        }
        break;
      case qx_eGatewaySyncStatus_enum.SyncFailed:
        expandedIcon = {
          tooltip: 'Sync Failed',
          mask: 'circle',
          transform: 'shrink-6 right-8 up-6',
          subColor: 'danger',
          subIcon: 'circle-xmark',
          subTransform: 'shrink-8 right-8 up-6',
        }
        break;
      case qx_eGatewaySyncStatus_enum.PartialSyncFailed:
        expandedIcon = {
          tooltip: 'Partial Sync Failed',
          mask: 'circle',
          transform: 'shrink-6 right-8 up-6',
          subColor: 'danger',
          subIcon: 'circle-minus',
          subTransform: 'shrink-8 right-8 up-6',
        }
        break;
      case qx_eGatewaySyncStatus_enum.RequiresUpgrade:
        expandedIcon = {
          tooltip: 'Requires Upgrade',
          mask: 'circle',
          transform: 'shrink-6 right-8 up-6',
          subColor: 'primary',
          subIcon: 'circle-plus',
          subTransform: 'shrink-8 right-8 up-6',
        }
        break;
      case qx_eGatewaySyncStatus_enum.NotCompatible:
        expandedIcon = {
          tooltip: 'Pending Version Update',
          mask: 'circle',
          transform: 'shrink-6 right-8 up-6',
          subColor: 'orange',
          subIcon: 'circle-exclamation',
          subTransform: 'shrink-8 right-8 up-6',
        }
        break;
      default:
        break;
    }
  }

  return {
    ...baseIcon,
    ...expandedIcon
  }
}
