// DEV NOTE :: possible to swap with https://github.com/jakearchibald/idb-keyval
import { InMemoryCache, TypePolicies } from '@apollo/client/core';
import { defaultDataIdFromObject } from '@apollo/client/core';
import { isString } from '@/functions/string';
import { BandwidthMetric } from '@/types';

/**
 * TypePolicies tells Apollo how to handle
 * the merging of cache data.
 */
const defaultTypePolicies: TypePolicies = {
  Query: {
    fields: {
      streamStorageStats: {
        keyArgs: ['path'],
        merge(_, incoming: unknown[]) {
          return incoming;
        }
      },
      getNetworkInterfaces: {
        keyArgs: ['cidr'],
        merge(_, incoming: unknown[]) {
          return incoming;
        },
      },
      systemInformation: {
        merge(existing: unknown[], incoming: unknown[]) {
          return (incoming || existing) || null;
        },
      },
      discover: {
        keyArgs: ['gatewayID', 'driver'],
        merge(existing: unknown[], incoming: unknown[]) {
          return incoming;
        },
      },
      // gateway networking
      getUploadSegmentMetrics: {
        keyArgs: ['gatewayID', 'unitOfTime'],
        merge(existing: Record<string, BandwidthMetric[]>, incoming: Record<string, BandwidthMetric[]>) {
          if (!existing) return incoming;
          return buildMetricData([existing], [incoming], 'unitOfTime', [
            'bitsSent',
            'bitsQueued',
            'totalBitsQueued',
            'secondsSent',
            'secondsQueued',
            'totalSecondsQueued'
          ])?.[0];
        },
      },
      mediaProfileAggregate: {
        read(existing: unknown, { args }) {
          return existing
        },
        merge(existing: unknown, incoming: unknown) {
          return incoming || existing;
        }
      },
      // gateway networking
      getBandwidthMetrics: {
        keyArgs: ['gatewayID', 'unitOfTime'],
        merge(existing: Record<string, BandwidthMetric[]>[], incoming: Record<string, BandwidthMetric[]>[]) {
          if (!existing) return incoming;
          return buildMetricData(existing, incoming, 'nic', ['received', 'transmitted']);
        },
      },
      ...basicTypePolicyMerge('getFlowActions'),
      ...basicTypePolicyMerge('authSessions'),
      ...basicTypePolicyMerge('userSettings'),
      ...basicTypePolicyMerge('bandwidthSchedules'),
      ...basicTypePolicyMerge('gatewayBandwidthSchedules'),
      ...basicTypePolicyMerge('getMediaProfileRecording'),
      ...basicTypePolicyMerge('merge'),

      // useBillingPeriods
      ...basicTypePolicyMerge('billingPeriods'),

      // keyArgs, read, merge
      ...basicTypePolicy('qx_Role', 'role', 'roles'),
      ...basicTypePolicy('qx_MercuryInventory', 'mercuryInventory', 'mercuryInventories'),
      ...basicTypePolicy('qx_MercuryCatalog', 'mercuryCatalog', 'mercuryCatalogs'),
      ...basicTypePolicy('qx_HolidaySet', 'holidaySet', 'holidaySets'),
      ...basicTypePolicy('qx_Permission', 'permission', 'permissions'),
      ...basicTypePolicy('qx_Schedule', 'schedule', 'schedules'),
      ...basicTypePolicy('qx_ScheduleBlock', 'scheduleBlock', 'scheduleBlocks'),
      ...basicTypePolicy('qx_Device', 'device', 'devices'),
      ...basicTypePolicy('qx_Gateway', 'gateway', 'gateways'),
      ...basicTypePolicy('qx_Door', 'door', 'doors'),
      ...basicTypePolicy('qx_Flow', 'flow', 'flows'),
      ...basicTypePolicy('qx_FlowTrace', 'flowTrace', 'flowTraces'),
      ...basicTypePolicy('qx_VideoSource', 'videoSource', 'videoSources'),
      ...basicTypePolicy('qx_MediaProfile', 'mediaProfile', 'mediaProfiles'),
      ...basicTypePolicy('qx_Group', 'group', 'groups'),
      ...basicTypePolicy('qx_Person', 'person', 'people'),
      ...basicTypePolicy('qx_Place', 'place', 'places'),
      ...basicTypePolicy('qx_Clip', 'clip', 'clips'),
      ...basicTypePolicy('qx_View', 'view', 'views'),
      ...basicTypePolicy('qx_ApplicationUser', 'applicationUser', 'applicationUsers'),
      ...basicTypePolicy('qx_UserSetting', 'userSetting', 'userSettings'),
      ...basicTypePolicy('applicationUserDevices', 'applicationUserDevice_by_pk', 'applicationUserDevices'),
      ...basicTypePolicy('PassKeyProvider', 'PassKeyProvider_by_pk', 'PassKeyProvider'),
    }
  },
  Subscription: {
    fields: {
      ...basicSubscriptionTypePolicy('doors'),
      ...basicSubscriptionTypePolicy('videoSource'),
      ...basicSubscriptionTypePolicy('videoSources'),
      ...basicSubscriptionTypePolicy('mediaProfileAggregate'),
      ...basicSubscriptionTypePolicy('mediaProfiles'),
      ...basicSubscriptionTypePolicy('views'),
      ...basicSubscriptionTypePolicy('flows'),
      ...basicSubscriptionTypePolicy('flowTraces'),
      ...basicSubscriptionTypePolicy('holidaySet'),
      ...basicSubscriptionTypePolicy('holidaySets'),
      ...basicSubscriptionTypePolicy('devices'),
      ...basicSubscriptionTypePolicy('roles'),
      ...basicSubscriptionTypePolicy('people'),
      ...basicSubscriptionTypePolicy('place'),
      ...basicSubscriptionTypePolicy('places'),
      ...basicSubscriptionTypePolicy('clips'),
      ...basicSubscriptionTypePolicy('groups'),
      ...basicSubscriptionTypePolicy('gateways'),
      ...basicSubscriptionTypePolicy('schedule'),
      ...basicSubscriptionTypePolicy('schedules'),
      ...basicSubscriptionTypePolicy('mercuryInventories'),
      ...basicSubscriptionTypePolicy('userSettings'),
      ...basicSubscriptionTypePolicy('person'),
      ...basicSubscriptionTypePolicy('user'),
      ...basicSubscriptionTypePolicy('users'),
      ...basicSubscriptionTypePolicy('applicationUser'),
      ...basicSubscriptionTypePolicy('applicationUsers'),
      ...basicSubscriptionTypePolicy('applicationUserDevices'),
      ...basicSubscriptionTypePolicy('PassKeyProvider'),
    }
  },
  /**
   * JsonOutput was needed for the Firing Data within the
   * Rules UI. Speak to Chris Johnson about UI integration,
   * speak to Luke Policinski about the data schema.
   */
  JsonOutput: {
    fields: {
      output: {
        keyArgs: ['flowID'],
        read(existing, { args, toReference }) {
          return existing;
        },
        merge(existing: unknown[], incoming: unknown[]) {
          return incoming;
        }
      }
    }
  },
  // AccessReportInfo: {
  //   keyFields: ['type']
  // },
  MediaProfileRecordingItem: {
    fields: {
      output: {
        keyArgs: ['mediaProfileID', 'type', 'baseDate'],
        read(existing) {
          return existing;
        },
        merge(existing: unknown[], incoming: unknown[]) {
          return incoming;
        }
      }
    }
  },
  DiscoveredDevice: {
    fields: {
      ...[
        'addresses',
        'discoveryDriver',
        'firmwareVersion',
        'hardwareID',
        'location',
        'mac',
        'model',
        'name',
        'networkInterfaceAddress',
        'networkInterfaceName',
        'profiles',
        'scopes',
        'serialNumber',
        'type',
        'utcDateTime',
        'vendor',
        'webServices',
      ].reduce((acc, field) => {
        Object.assign(acc, {
          [field]: {
            read(existing: unknown) {
              return existing;
            },
            merge(existing: unknown, incoming: unknown) {
              return (incoming || existing) || '';
            }
          }
        })
        return acc;
      }, {})
    }
  },
  /**
   * The returned array does not
   * return keys consistently.
   */
  SysInfo: {
    keyFields: ['gatewayID', 'name'],
    fields: {
      ...[
        'gatewayID',
        'name',
        'env',
        'version',
        'prodLevel',
        'gitBranch',
        'gitMessage',
        'gitRevision',
        'buildID',
        'buildTime',
        'bootTime',
        'time',
        'error'
      ].reduce((acc, field) => {
        Object.assign(acc, {
          [field]: {
            read(existing: unknown) {
              return existing;
            },
            merge(existing: unknown, incoming: unknown) {
              return (incoming || existing) || '';
            }
          }
        })
        return acc;
      }, {})
    }
  },
  qx_Person: {
    fields: {
      /**
       * When the app was first created we stored a single string in
       * this column. Now it is a jsonb column where we store an
       * object. Check if the data is a string, and if so,
       * return it as an object for the client to consume.
       */
      phoneNumbers: {
        read(existing: unknown) {
          if (isString(existing)) {
            return {
              countryCode: '1',
              number: existing,
            }
          }
          return existing;
        },
      },
      ...basicModelFieldPolicy('toGroups'),
      ...basicModelFieldPolicy('credentials'),
      ...basicModelFieldPolicy('user'),
      ...basicModelFieldPolicy('avatar'),
      ...basicModelFieldPolicy('avatar_sm'),
      ...basicModelFieldPolicy('avatar_md'),
      ...basicModelFieldPolicy('avatar_lg'),
      ...basicModelFieldPolicy('avatar_xl'),
    }
  },
  qx_Device: {
    fields: {
      ...basicModelFieldPolicy('doors'),
      ...basicModelFieldPolicy('videoSources'),
    }
  },
  qx_Role: {
    fields: {
      ...basicModelFieldPolicy('toPrivileges'),
    }
  },
  qx_Place: {
    fields: {
      ...basicModelFieldPolicy('children'),
      ...basicModelFieldPolicy('hierarchy'),
      /**
       * Why is this here? The Auth Store Scope Subscription queries fields
       * on toHomePlaces, but there are other areas in the app that may
       * subscribe to the same Place ID but does not contain the same
       * fields in its' query. We need to ensure we maintain the
       * existing fields, otherwise it may cause the Auth
       * Manager to think there are changes in the User
       * Scope data and refresh the app.
       */
      toHomePlaces: {
        merge(existing: unknown, incoming: unknown) {
          return {
            ...(existing || {}),
            ...(incoming || {})
          }
        }
      }
    }
  },
  qx_Group: {
    fields: {
      ...basicModelFieldPolicy('toPeople'),
    }
  },
  qx_vPlaceHierarchy: {
    keyFields: false,
  },
  qx_Gateway: {
    fields: {
      ...basicModelFieldPolicy('bandwidthSchedules'),
      features: {
        read(existing) {
          if (isString(existing)) {
            return JSON.parse(existing);
          }
          return existing;
        },
        merge(existing: unknown[], incoming: unknown[]) {
          return incoming;
        }
      },
      ...basicModelFieldPolicy('services'),
      ...basicModelFieldPolicy('devices'),
      ...basicModelFieldPolicy('disks'),
    }
  },
  PassKeyProvider: {
    keyFields: ['aaguid'],
  },
  qx_Clip: {
    fields: {
      progress: {
        // take a floating point number and return a whole number from 0 to 100
        read(existing = "0.00") {
          /**
           * DB returns a string, but there appears to be a bug where the reading of
           * this value more than once on the same page causes the returned number
           * to be ran again as if it were the `existing` value, meaning you
           * would get another *100 added to the original cache value
           * multiple times. We work around this by checking if we
           * are dealing with a String or not. If it is not a
           * string, assume we have already formatted.
           */
          if (isString(existing)) {
            // double parseFloat because we need precision to prevent trailing zeros
            const floatingPoint = parseFloat(`${existing}`).toPrecision(2);
            const wholeNumber = Math.ceil(parseFloat(floatingPoint) * 100);
            // remove decimal... just in case it still comes through
            if (wholeNumber.toString().includes('.')) {
              const val = wholeNumber.toString().split('.')[0];
              return parseInt(val);
            }
            return wholeNumber;
          }
          return existing
        }
      },
      cameraInfo: {
        read(existing, { args, toReference }) {
          if (isString(existing)) {
            return JSON.parse(existing);
          }
          return existing;
        },
        merge(existing: unknown[], incoming: unknown[]) {
          return incoming || existing;
        }
      }
    }
  },
  qx_Schedule: {
    fields: {
      ...basicModelFieldPolicy('blocks'),
      ...basicModelFieldPolicy('permissions'),
      // We do not subscribe to the thumbnail because it is a Signed URL changing every few seconds.
      ...basicModelFieldPolicy('thumbnail'),
    }
  },
  qx_VideoSource: {
    fields: {
      // We do not subscribe to the thumbnail because it is a Signed URL changing every few seconds.
      ...basicModelFieldPolicy('snapshots'),
      ...basicModelFieldPolicy('place'),
    }
  },
  qx_ApplicationUser: {
    keyFields: ['personID'],
    ...basicModelFieldPolicy('termsAgreement'),
    ...basicModelFieldPolicy('person'),
    ...basicModelFieldPolicy('role'),
  },
  qx_MediaProfile_aggregate: {},
}

function basicModelFieldPolicy(field: string) {

  return {
    [field]: {
      read(existing: unknown) {
        return existing;
      },
      merge(existing: unknown, incoming: unknown) {
        return (incoming || existing) || '';
      }
    }
  }
}

function basicTypePolicy(typename: string, single: string, plural: string) {

  return {
    [single]: {
      keyArgs: ['id'],
      read(existing, { args, toReference }) {
        return existing || toReference({ __typename: typename, id: args.id });
      },
      merge(existing: unknown[], incoming: unknown[]) {
        return incoming || existing;
      }
    },
    ...basicTypePolicyMerge(plural),
  }
}

function basicTypePolicyMerge(field: string) {

  return {
    [field]: {
      merge(existing: unknown[], incoming: unknown[]) {
        return incoming || existing;
      }
    }
  }
}

function basicSubscriptionTypePolicy(field: string) {

  return {
    ...basicTypePolicyMerge(field)
  }
}

function buildMetricData(
  existing: Record<string, BandwidthMetric[]>[],
  incoming: Record<string, BandwidthMetric[]>[],
  idField: string,
  fields: string[]
) {
  try {

    return incoming.reduce((acc, inc) => {

      const existingRecord = existing.find((ex: any) => ex[idField] === inc[idField as keyof typeof inc]);

      if (!existingRecord) {
        acc.push(inc);
        return acc
      }

      const mergedFields = fields.reduce((acc, field) => {

        const existingField = existingRecord?.[field] || [];
        const lastExistingField = existingField[existingField.length - 1];

        const newRecords = [];

        for (const incFieldRecord of inc?.[field].slice(0).reverse()) {
          if (incFieldRecord.timestamp === lastExistingField.timestamp) {
            break;
          }
          newRecords.push(incFieldRecord);
          continue;
        }

        // add on the incoming records
        const updatedFieldRecords = [...existingField, ...newRecords.reverse()];

        if (updatedFieldRecords.length > 1000) {
          updatedFieldRecords.splice(0, 800);
        }

        Object.assign(acc, {
          [field]: updatedFieldRecords
        })

        return acc;
      }, { ...fields.reduce((a, f) => ({...a, [f]: [] }), {}) })

      acc.push({
        ...inc,
        ...mergedFields,
      });
      return acc

    }, [] as Record<string, BandwidthMetric[]>[])

  } catch (error) {
    console.warn('Error building metric data for graph.', { error })
    return incoming;
  }
}

// TODO :: disable normalization for Types that do not have an ID
// https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
export const createApolloClientCache = async (key: string, cache?: InMemoryCache) => {
  if (cache) return { cache }

  const defaultCache = new InMemoryCache({
    typePolicies: defaultTypePolicies,
    dataIdFromObject: (responseObject) => {
      // DEV NOTE :: audit tables have an `id` column, but it is not unique, defer to the `auditID` column
      if (responseObject.__typename?.endsWith('Audit')) {
        return `${responseObject.__typename}:${responseObject.auditID}`
      }
      return defaultDataIdFromObject(responseObject)
    }
  })

  return {
    cache: defaultCache,
  };
}
