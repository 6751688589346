<template>
  <FaIcon :icon="['far', DEVICE_DEFAULT_INPUT_ICON]" />
</template>

<script lang="ts">
import { DEVICE_DEFAULT_INPUT_ICON } from '@/constants/device'
export default {
  data: () => ({
    DEVICE_DEFAULT_INPUT_ICON
  })
}
</script>
