<template>
  <div class="LoadingOverlay delayLoader">
    <LoadingSpinner class="LoadingOverlay__spinner" />
    <small>{{ message }}</small>
    <button @click="reload"><PwaIcon status="update-found" v-tooltip="false" style="margin-right: var(--size-2);" />Reload App</button>
  </div>
</template>

<script setup lang="ts">
import LoadingSpinner from '@/components/LoadingSpinner.vue';
defineProps<{ message?: string }>()
function reload() {
  window.location.reload();
}
</script>

<style lang="scss" scoped>
.LoadingOverlay {
  position: fixed;
  z-index: 99999;
  inset: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background-800);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__spinner {
    font-size: $text-xl;
  }

  small {
    margin-bottom: var(--size-8);
    display: block;
    color: var(--color-text-light);
    opacity: 0.8;
    font-size: $text-2xs;
    opacity: 0;
    transform: translateY(120px);
    animation: textDelayLoading 3s var(--animate-cb--snap2);
    animation-delay: 4s;
    animation-fill-mode: forwards;
    will-change: opacity, transform;

    @include keyframes(textDelayLoading) {
      0% {
        opacity: 0;
        transform: translateY(120px);
      }
      100% {
        opacity: 1;
        transform: translateY(20px);
      }
    }
  }

  button {
    display: block;
    color: var(--color-text);
    background: var(--color-background-600);
    border: var(--size-px) solid var(--color-background-100);
    padding: var(--size-2) var(--size-3)var(--size-2) var(--size-2);
    border-radius: var(--size-1);
    opacity: 0;
    transform: translateY(140px);
    animation: reloadDelayLoading 3s var(--animate-cb--snap2);
    animation-delay: 60s;
    animation-fill-mode: forwards;
    will-change: opacity, transform;

    &:hover {
      border-color: var(--color-primary-400);
      background-color: var(--color-primary-600);
    }

    @include keyframes(reloadDelayLoading) {
      0% {
        opacity: 0;
        transform: translateY(140px);
      }
      100% {
        opacity: 1;
        transform: translateY(40px);
      }
    }
  }
}
</style>
