import {
  PEOPLE_ROUTE,
  PERSON_CREATE_ROUTE,
  PERSON_EDIT_ROUTE,
  PERSON_VIEW_ROUTE,
  PERSON_VIEW_INFO_ROUTE,
} from '@/config/routes/people';

export default [
  /**
   * CONTAINER - All Person Routes will pass through this container
   *  This container is responsible for handling any global person-related fetching.
   */
  {
    path: PEOPLE_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "container-person" */ './PersonContainer.vue')
    },
    meta: {
      privileges: PEOPLE_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * Create Person
       */
      {
        path: PERSON_CREATE_ROUTE.ROUTE_PATH,
        name: PERSON_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: PERSON_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "page-person-create" */ './PersonCreatePage.vue')
        }
      },

      /**
       * List of People
       */
      {
        path: '',
        name: PEOPLE_ROUTE.ROUTE_NAME,
        // meta: {},
        components: {
          default: () => import(/* webpackChunkName: "page-person-list" */ './PersonListPage.vue')
        }
      },

      /**
       * CONTAINER - View Person Routes will pass through this container
       *  Parent Route is a container to fetch the person object
       *  Child routes (includes view page) will reference the person object pulled in the container
       *  This container will hold some generic/global elements for all Person View Pages
       */
      {
        path: PERSON_VIEW_ROUTE.ROUTE_PATH,
        // name: PERSON_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: PERSON_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "container-person-view" */ './PersonContainerView.vue')
        },
        children: [
          /**
           * View Person - Info
           */
          {
            path: PERSON_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: PERSON_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: PERSON_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-person-view-info" */ './PersonViewInfoPage.vue')
            }
          },

          /**
           * Edit Person
           */
          {
            path: PERSON_EDIT_ROUTE.ROUTE_PATH,
            name: PERSON_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: PERSON_EDIT_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-person-edit" */ './PersonEditPage.vue')
            }
          },
        ]
      }
    ]
  }
];
