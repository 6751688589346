export const faPlaceArea = {
  prefix: 'fas',
  iconName: 'place-area',
  icon: [
    '512',
    '512',
    [],
    'qxp001',
    'M456,200 L285,200 L285,341 L255,341 L255,170 L456,170 L456,57 L200,57 L200,199 L170,199 L170,57 L57,57 L57,284 L170,284 L170,255 L200,255 L200,397 L170,397 L170,314 L57,314 L57,456 L255,456 L255,397 L285,397 L285,456 L456,456 L456,397.5 L512,397.5 L512,512 L0,512 L0,0 L512,0 L512,341.5 L456,341.5 L456,200 Z'
  ]
};
