import { ref, readonly, Ref, DeepReadonly } from 'vue';
import { isBoolean } from '@/functions/boolean';

export type UseVisibilityToggle = {
  isVisible: DeepReadonly<Ref<boolean>>;
  toggleVisibility: (bool?: boolean) => void;
};

export const useVisibilityToggle = (defaultState: boolean = false): UseVisibilityToggle => {
  const isVisible = ref<boolean>(defaultState);

  const toggleVisibility = (bool?: boolean): void => {
    isVisible.value = !isBoolean(bool) ? !isVisible.value : bool as boolean;
  };

  return {
    isVisible: readonly(isVisible),
    toggleVisibility
  };
};
