
const STRIP_FORMAT_REGEX = /[()-._\s]/g;

//----------------------------------------------------------------------  formatting

export function stripPhoneFormatting(phone: string | number | undefined) {
  if (!phone) {
    return '';
  }
  const strippedPhone = phone.toString().replace(STRIP_FORMAT_REGEX, '');
  return strippedPhone;
}

export function formatUs(phone: string) {
  if (phone.length === 10) {
    const areaCode = phone.substring(0, 3);
    const prefix = phone.substring(3, 6);
    const postfix = phone.substring(6);
    return `(${areaCode}) ${prefix}-${postfix}`;
  } else {
    return phone; // can't format
  }
}

export function formatAus(phone: string) {
  if (phone.startsWith('0')) {
    phone = phone.substring(1);
  }
  if (phone.length === 9) {
    const areaCode = phone.substring(0, 3);
    const prefix = phone.substring(3, 6);
    const postfix = phone.substring(6);
    return `${areaCode} ${prefix} ${postfix}`;
  } else {
    return phone; // can't format
  }
}

export function formatUk(phone: string) {
  if (phone.length === 10) {
    const areaCode = phone.substring(0, 4);
    const subscriber = phone.substring(4);
    return `${areaCode} ${subscriber}`;
  } else {
    return phone; // can't format
  }
}

export function formatE164(phone: string | number, countryCode: string | number) {
  if (!phone) {
    return;
  }
  countryCode = countryCode || 1;
  const strippedPhone = phone.toString().replace(STRIP_FORMAT_REGEX, '');
  return `+${countryCode}${strippedPhone}`;
}

//----------------------------------------------------------------------  bool checks

// is input string a valid Phone Number - (includes international)
export const isPhoneNumber = (str: string) => {
  const regex = /^[\d\s]{1,14}$/;
  return regex.test(str);
};

// is input string a valid US Phone Number
export const isUsPhoneNumber = (str: string) => {
  const regex = /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return regex.test(str);
};

//https://regex101.com/r/kSuyrB/1
// is input string a valid E.164 Phone Number
export const isE164PhoneNumber = (str: string) => {
  const regex = /^\+[1-9][\d\s]{1,14}$/;
  return regex.test(str);
};
