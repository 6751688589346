<template>
  <LockIcon v-if="showIcon"
    :disabled="disabled"
    :parentDisconnected="gatewayOrDeviceNotConnected"
    :status="status"
  />
  <span v-else-if="showLabel">
    {{ label }}
  </span>
</template>

<script lang="ts">
import { qx_eDeviceConnectionStatus_enum } from '@/graphql/types';
import { smartIconValidator } from './helpers/smart-icon-validator';
import { getLockStatus  } from '@/constants/door';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    door: {
      type: Object,
      required: true
    },
    type: {
      required: false,
      default: "icon",
      validator(value) {
        return smartIconValidator(value);
      }
    }
  },
  computed: {
    disabled() {
      return !this.door?.enabled || this.door.disabledByLicense;
    },
    status() {
      return this.door?.mode || undefined;
    },
    label() {
      return getLockStatus(this.status, this.gatewayOrDeviceNotConnected, !this.disabled)?.tooltip || '';
    },
    gatewayOrDeviceNotConnected() {
      const fullyConnected = this.door?.gatewayConnectionStatus && this.door?.deviceConnectionStatus === qx_eDeviceConnectionStatus_enum.Connected;
      return !(fullyConnected || false);
    },
    showIcon() {
      return this.type === "icon";
    },
    showLabel() {
      return this.type === "label";
    }
  }
});
</script>

