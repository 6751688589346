<template>
  <FaLayer v-tooltip="renderedTooltip">
    <FaIcon :icon="GATEWAY_DEFAULT_ICON" transform="shrink-6 right-10 up-6" />
    <FaIcon v-bind="attrs" icon="square" :mask="icon" transform="shrink-4 right-8 up-4" />
  </FaLayer>
</template>

<script setup lang="ts">
import { FontAwesomeLayers as FaLayer } from '@fortawesome/vue-fontawesome'
import { ruleActionOverrides } from '@/models/rule/utils/rule-actions';
import { GATEWAY_DEFAULT_ICON } from '@/constants/gateway';
import { FlowActions } from '@/models/rule/types';
import { useAttrs, computed } from 'vue';

const props = defineProps<{
  type: FlowActions,
  tooltip?: string,
}>();

const attrs = useAttrs();

const hasActionConfig = computed(() => ruleActionOverrides?.[props.type]);
const icon = computed(() => hasActionConfig.value?.icon || ['fas', 'circle-question']);

const renderedTooltip = computed(() => {
  if (!hasActionConfig.value) {
    return props.tooltip ?? false;
  }
  return {
    html: true,
    content: `<small style="font-size: 10px; font-weight: semibold;">Ran on Gateway</small><br /><strong>${hasActionConfig.value.title}</strong><br /><small>${hasActionConfig.value.desc}</small>${props.tooltip ? `<br />${props.tooltip}` : ''}`
  }
});
</script>
