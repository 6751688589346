import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { config } from "@fortawesome/fontawesome-svg-core";
import type { App, Component } from 'vue';

// 1. Register the selected FontAwesome Icons (bootstrap)
import '@/icons/bootstrap';

type IconFiles = Record<string, {
  default: Component
}>

export default async (app: App) => {

  // configure defaults
  config.styleDefault = "solid";

  // 2. Globally register the FontAwesome Icon component
  app.component('FaIcon', FontAwesomeIcon)

  // 3. Globally register all icons in the `./components/icons/` folder
  const files: IconFiles = import.meta.glob("./../icons/components/*Icon.vue", { eager: true });

  for (const path in files) {
    if (!path) continue;

    const fileName = path.split('/').pop() || '';
    const name = fileName.split('.')?.[0] || null;
    if (!name) continue;

    const component = files[path].default;
    app.component(name, component);
  }

  return;
}
