import type { App } from 'vue';

export default async (app: App) => {
  return;
};

/**
 * This file is used as a "catch-all" for errors and is only listening
 * in production. In here we can decide if we want to send specific
 * errors to a 3rd party logging service like Sentry.
 */

// import Vue from 'vue'
// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'

// if (process.env.APP_CONFIG_SENTRY_DSN && process.env.NODE_ENV === 'production') {

//   Sentry.init({
//     dsn: process.env.APP_CONFIG_SENTRY_DSN,
//     integrations: [new Integrations.Vue({ Vue, attachProps: true })],
//   });

//   window.onerror = function (message, source, line, column, error) {
//     Sentry.captureException(message);
//   }

//   Vue.config.errorHandler = function (err, vm, info) {
//     Sentry.captureException(err);
//   }
// }

// // Not rendered in production
// if (process.env.NODE_ENV !== 'production') {

//   window.onerror = function (message, source, line, column, error) {
//     console.info('I heard there was a Window error!', { message, source, line, column, error })
//   }

//   Vue.config.errorHandler = function (err, vm, info) {
//     console.info('I heard there was a Vue error!', { err, vm, info })
//   }
// }

// Vue.config.warnHandler = function (msg, vm, trace) {
//   console.info(`Vue Warning: ${msg}\nTrace: ${trace}`, { vm });
// }
