import { defineAsyncComponent } from 'vue';

export const hasTypeProp = {
  type: {
    type: String, // flow_event, etc
    required: false
  }
};

export const hasConditionProps = {
  ...hasTypeProp,
  condition: {
    type: Object,
    required: true
  }
};

export const hasGuardsProps = {
  ...hasTypeProp,
  guards: {
    required: true,
    type: Array,
    default: () => ([]),
  }
};

export const hasGuardProps = {
  ...hasTypeProp,
  guard: {
    type: Object,
    required: true
  }
};

export const hasConditionsProps = {
  ...hasTypeProp,
  conditions: {
    type: Array,
    required: true
  }
};

export const hasEditableProp = {
  editable: {
    type: Boolean,
    required: false,
    default: false
  }
};

export const hasActionsProp = {
  actions: {
    type: Array,
    required: false,
    default: () => ([])
  }
}

export const hasTriggersProp = {
  triggers: {
    required: false,
    type: Array,
    default: () => []
  }
};

export const hasConditionGroupProps = {
  // used to determine if it should display the down-stem
  inGroup: {
    type: Boolean,
    default: false
  },
  // used to determine the length of the down-stem, short if last in group
  isLast: {
    type: Boolean,
    default: false
  }
};

export const hasDepthProp = {
  depth: {
    type: Number,
    default: 3
  }
};

/**
 * Used to determine if the ConditionObject.field string is a relational key.
 */
export function isRelationField(field: string): string | false {
  if (field.includes('ID')) {
    const fieldWithoutID = field.slice(0, -2).trim();
    return fieldWithoutID;
  }
  return false;
}

// TODO :: can this be combined with the generate-rule-action-config arguments handling?
export function conditionValueFieldToComponent(field: string) {
  switch (field) {
    case 'placeID':
      return {
        icon: 'PlaceIcon',
        component: defineAsyncComponent(() => import('@/models/rule/components/form/RuleConditionValueSelectPlace.vue'))
      };
    default:
      return {
        icon: null,
        component: null
      };;
  }
}
