import { FlowGroupOperator } from 'qx-flow-orm';
import { RuleCondition, RuleConditionGroupOperatorValue } from '@/models/rule/types';
import { GraphqlOperators, determineModel } from 'qx-flow-orm';

/**
 * The RuleConditionsGroupOperator is used to help determine what conditions need
 * to use the RuleConditionGroup component. A type of `condition` is used for
 * the basic RuleCondition component. We may have more types in the future,
 * such as ...
 */
export enum RuleConditionGroupOperatorType {
  Group = 'group',
  Guard = 'guard',
  Condition = 'condition'
}

// also used in helper functions
type RuleConditionsGroupOperator = {
  value: RuleConditionGroupOperatorValue;
  label: string;
  type: RuleConditionGroupOperatorType;
};

export const ruleConditionsGroupOperators: RuleConditionsGroupOperator[] = [
  {
    value: FlowGroupOperator.And,
    label: 'And',
    type: RuleConditionGroupOperatorType.Group
  },
  {
    value: FlowGroupOperator.Or,
    label: 'Or',
    type: RuleConditionGroupOperatorType.Group
  },
  /**
   * Dev Note: FlowConditionOperator.GraphQL is not to
   * be considered a RuleConditionsGroupOperator as
   * it is instead used as a signal to transform
   * the underlying data.
   */
];

export const hasGroupOperator = (condition: RuleCondition): RuleConditionsGroupOperator | false => {
  if (!condition) return false;

  const conditionKeys = Object.keys(condition);

  const foundGroupOperator = ruleConditionsGroupOperators.find((groupOperator) => {
    return conditionKeys.includes(groupOperator.value);
  });

  return foundGroupOperator || false;
};

export const getGroupOperatorOptions = (type: RuleConditionGroupOperatorType) => {
  return ruleConditionsGroupOperators.filter((operator) => operator.type === type);
};

/**
 *
 */
export const determineOperators = (field: string, modelName: string): unknown[] => {
  // confirm model
  const model = determineModel(modelName);
  if (!model) return [];

  // confirm operators
  const column = model.field(field);
  if (!column?.operators) return [];

  /**
   * TODO :: https://app.shortcut.com/qumulex/story/26160/place-multi-select
   *  remove this if/return when the story above is completed
   */
  if (column.field === 'placeID') {
    return [GraphqlOperators.Equal, GraphqlOperators.NotEqual];
  }

  // return
  return column.operators;
};

export function getOperatorDisplay(operator: GraphqlOperators) {
  switch (operator) {
    case GraphqlOperators.Equal:
      return {
        icon: 'equals',
        color: 'success',
        label: 'is equal to',
        value: operator
      };

    case GraphqlOperators.NotEqual:
      return {
        icon: 'not-equal',
        color: 'danger',
        label: 'is not equal to',
        value: operator
      };

    case GraphqlOperators.In:
      return {
        icon: 'equals',
        color: 'success',
        label: 'is one of',
        value: operator
      };

    case GraphqlOperators.NotIn:
      return {
        icon: 'not-equal',
        color: 'danger',
        label: 'is not one of',
        value: operator
      };

    // case GraphqlOperators.Like:
    //   return {
    //     icon: 'tilde',
    //     color: 'primary',
    //     label: 'is like',
    //     value: operator
    //   };

    case GraphqlOperators.GreaterThan:
      return {
        icon: 'greater-than',
        color: 'primary',
        label: 'is greater than',
        value: operator
      };

    case GraphqlOperators.GreaterThanOrEqualTo:
      return {
        icon: 'greater-than-equal',
        color: 'primary',
        label: 'is greater than or equal to',
        value: operator
      };

    case GraphqlOperators.LessThan:
      return {
        icon: 'less-than',
        color: 'primary',
        label: 'is less than',
        value: operator
      };

    case GraphqlOperators.LessThanOrEqualTo:
      return {
        icon: 'less-than-equal',
        color: 'primary',
        label: 'less than or equal to',
        value: operator
      };

    default:
      return {
        label: 'Please select an operator',
        icon: 'circle-question',
        color: 'grey',
        value: operator
      };
  }
}

/**
 * Informs if the value selection should
 * be single select or multiselect.
 */
export function isMultiValueOperator(operator: GraphqlOperators): boolean {
  const multiValueOperators: string[] = [GraphqlOperators.In, GraphqlOperators.NotIn];
  return multiValueOperators.includes(operator);
}
