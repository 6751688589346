import { HELP_ROUTE } from '@/config/routes/help';

export default [
  {
    path: HELP_ROUTE.ROUTE_PATH,
    name: HELP_ROUTE.ROUTE_NAME,
    components: {
      default: () => import('./HelpPage.vue'),
    },
    meta: {
      privileges: HELP_ROUTE.PRIVILEGES
    }
  }
];
