import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

const locationDot = 'location-dot';

export const PLACES_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', locationDot],
  UI_LABEL: 'Places',
  ROUTE_PATH: 'places',
  ROUTE_NAME: 'places.index',
  ROUTE_PARAM: '',
  PRIVILEGES: []
};

export const PLACE_CREATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', locationDot],
  UI_LABEL: 'Create Place',
  ROUTE_PATH: 'create',
  ROUTE_NAME: 'place.create',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.PlaceConfig]
};

export const PLACE_EDIT_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'pen-to-square'],
  UI_LABEL: 'Edit Place',
  ROUTE_PATH: 'edit',
  ROUTE_NAME: 'place.edit',
  ROUTE_PARAM: 'placeId',
  PRIVILEGES: [qx_ePrivilege_enum.PlaceConfig]
};

const PLACE_VIEW_ROUTE_NAME_SHARED = 'place.view';

export const PLACE_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', locationDot],
  UI_LABEL: 'View Place',
  ROUTE_PATH: ':placeId',
  ROUTE_NAME: PLACE_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'placeId',
  PRIVILEGES: []
};

export const PLACE_VIEW_INFO_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fas', 'circle-info'],
  UI_LABEL: 'Info',
  ROUTE_PATH: '',
  ROUTE_NAME: PLACE_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'placeId',
  PRIVILEGES: []
};
