import { ApolloClient } from "@/utils/ApolloClient";
import type { UserSettingValue } from "./types";
import { gql } from "@apollo/client/core";

const GQL_CREATE_USER_SETTING = gql`
  mutation GQL_CREATE_USER_SETTING(
    $personID: String!,
    $name: String!,
    $value: jsonb!
  ) {
    createUserSetting(
      object: {
        personID: $personID,
        name: $name,
        value: $value
      }
    ) {
      id
      name
      value
      updatedAt
    }
  }
`

// only to be called within `useUserSettings`
export function createSetting(
  personID: string,
): (
  name: string,
  value: UserSettingValue
) => Promise<boolean> {

  return async (name, value) => {
    try {
      const response = await ApolloClient.mutate({
        mutation: GQL_CREATE_USER_SETTING,
        variables: {
          personID,
          name,
          value
        },
        update: (cache, { data }) => {
          /**
           * TODO :: not a permanent fix, use an optimistic mutation or `ApolloClient.writeQuery`
           * This is okay for now because the only existing userSetting records we have
           * are necessary for boot. So blowing the cache out and remaking is not
           * going to cause any "flashes" in the UI since the User is in an
           * existing loading state until the initial records are created.
           *
           * This will become an issue when we have a UserSetting record that is not
           * required for booting the app. If we use this to create that record it
           * will cause the auth-related UserSettings to be removed, the app will
           * update and may show a black screen (at best).
           */
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'userSettings' })
        },
      })

      return true;

    } catch (error) {
      throw error
    }
  }
}
