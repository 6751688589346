export const faRexIcon = {
  prefix: 'fas',
  iconName: 'rex',
  icon: [
    '640',
    '512',
    [],
    'qxreader01',
    'M312.24,1.01 L120.24,50.75 C105.99,54.44 96,67.7 96,82.92 L96,448 L16,448 C7.16,448 0,455.16 0,464 L0,496 C0,504.84 7.16,512 16,512 L352,512 L352,33.18 C352,11.6 332.44,-4.23 312.24,1.01 Z M532.435136,120.16601 C543.519916,109.152215 562.532882,116.935297 562.532882,132.648312 L562.532882,132.648312 L562.532882,203.136604 L622.369307,203.136604 C632.132722,203.136604 639.9875,210.993111 639.9875,220.758677 L639.9875,220.758677 L639.9875,291.246969 C639.9875,301.012534 632.132722,308.869042 622.369307,308.869042 L622.369307,308.869042 L562.532882,308.869042 L562.532882,379.357334 C562.532882,395.143774 543.446507,402.853431 532.435136,391.839635 L532.435136,391.839635 L409.107787,268.485125 C402.280738,261.583146 402.280738,250.4225 409.107787,243.520521 L409.107787,243.520521 Z M264,224 C277.25,224 288,238.33 288,256 C288,273.67 277.25,288 264,288 C250.75,288 240,273.67 240,256 C240,238.33 250.75,224 264,224 Z'
  ]
};
