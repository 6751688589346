<template>
  <FaLayer v-tooltip="tooltip">
    <FaIcon
      v-if="selectedIcon.icon"
      :class="{
        ['IconColor IconColor--'+selectedIcon.color]: selectedIcon.color,
        [selectedIcon.class]: selectedIcon.class
      }"
      :mask="selectedIcon.mask || null"
      :icon="selectedIcon.icon"
      :transform="selectedIcon.transform || null"
      :opacity="selectedIcon.opacity || null"
      :style="statusConfig.style || {transform: 'scale(1.2)'}"
    />
    <FaIcon
      v-if="statusConfig.subIcon"
      :class="{
        ['IconColor IconColor--'+selectedIcon.subColor]: selectedIcon.subColor,
        ['IconColor IconColor--'+selectedIcon.color]: !selectedIcon.subColor,
        [selectedIcon.subClass]: selectedIcon.subClass
      }"
      :icon="statusConfig.subIcon"
      :mask="statusConfig.subMask && ['fas', statusConfig.subMask] || null"
      :transform="statusConfig.subTransform || null"
      :opacity="statusConfig.subOpacity || null"
      :style="statusConfig.subStyle || null"
    />
  </FaLayer>
</template>

<script lang="ts">
import { getCameraMediaProfilesDefaultIcon, CAMERA_MEDIA_PROFILES_DEFAULT_ICON, CAMERA_MEDIA_PROFILES_STATUS_ICON } from '@/constants/camera'
import { mixinFaIcons, mixinStatusProps, } from '@/icons/mixins'
import { isArray } from '@/functions/array';

export default {
  mixins: [mixinFaIcons],
  props: {
    ...mixinStatusProps,
    status: {
      required: false,
      default: undefined,
      type: [Boolean, Array, String],
    },
  },
  computed: {
    // get information about the icon status
    statusConfig() {
      return getCameraMediaProfilesDefaultIcon(this.status)
    },

    // build icon details
    icon() {
      const { disabled, statusConfig } = this
      let icon = ['fas', CAMERA_MEDIA_PROFILES_DEFAULT_ICON]
      if (disabled || !statusConfig) return icon
      icon = isArray(statusConfig.icon) && statusConfig.icon || ['fas', statusConfig.icon]
      return icon
    },
    mask() {
      const { disabled, statusConfig } = this
      let mask = null
      if (disabled) return mask
      if (statusConfig.mask) mask = isArray(statusConfig.mask) && statusConfig.mask || ['fas', statusConfig.mask]
      return mask
    },

    // define icon and tooltip
    selectedIcon() {
      const { icon, mask, statusConfig, disabled, status } = this
      const reversedIcon = statusConfig.mask

      return {
        ...statusConfig,
        color: disabled && 'grey' || (statusConfig.color || null),
        icon: !reversedIcon ? icon : mask,
        mask: !reversedIcon ? mask : icon,
      }
    },
    tooltip() {
      const { selectedIcon, disabled } = this
      if (disabled || !selectedIcon.value) return false
      return {
        triggers: ['click', 'hover', 'focus'],
        content: `Media Profiles: ${selectedIcon.value}`,
      }
    },
  }
}
</script>
