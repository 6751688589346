<template>
  <FaIcon
    class="EnabledIcon"
    :class="{
      'EnabledIcon--disabled': !enabled
    }"
    :icon="['fas', 'circle']"
  />
</template>

<script lang="ts">
  export default {
    props: {
      enabled: {
        type: Boolean,
        default: true,
      },
    }
  }
</script>

<style lang="scss" scoped>
  .EnabledIcon {
    border-radius: 100%;
    color: var(--color-success-400) !important;
    filter: drop-shadow(0px 0px 2px var(--color-success-600));

    &--disabled {
      color: var(--color-danger-400) !important;
      filter: drop-shadow(0px 0px 2px var(--color-danger-600));
    }
  }
</style>
