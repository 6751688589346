import { createAndMountApp } from './create-and-mount-app';

async function mainStartup() {
  try {
    const app = await createAndMountApp();
    return app;
  } catch (error) {
    throw error
  }
}

// TODO :: convert this to an async function, like `entry` w/ Nuxt... use awaitable hooks to build pre/post configuration
mainStartup()
  .then((app: unknown) => {
    console.log('QxControl booted. 🚀')
  })
  .catch((error) => {
    console.log('QxControl failed to start.')
    console.error(error)
  });
