<template>
  <FaLayer v-tooltip="tooltip">
    <FaIcon
      :class="{
        ['IconColor IconColor--'+selectedIcon?.color]: selectedIcon?.color,
        [selectedIcon?.class]: selectedIcon?.class
      }"
      :mask="selectedIcon?.mask || null"
      :icon="selectedIcon?.icon"
      :transform="selectedIcon?.transform || null"
      :opacity="selectedIcon?.opacity || null"
      :style="selectedIcon?.style || {}"
      />
    <FaIcon
      v-if="selectedIcon?.subIcon"
      :class="{
        ['IconColor IconColor--'+selectedIcon?.subColor]: selectedIcon?.subColor,
        ['IconColor IconColor--'+selectedIcon?.color]: !selectedIcon?.subColor,
        [selectedIcon?.subClass]: selectedIcon?.subClass
      }"
      :icon="selectedIcon?.subIcon"
      :mask="selectedIcon?.subMask && ['fas', selectedIcon?.subMask] || null"
      :transform="selectedIcon?.subTransform || null"
      :opacity="selectedIcon?.subOpacity || null"
      :style="selectedIcon?.subStyle || null"
    />
  </FaLayer>
</template>

<script lang="ts">
import { getGatewayIconWithSyncStatus } from '@/constants/gateway';
import { mixinFaIcons, mixinStatusProps } from '@/icons/mixins';
import { isBoolean } from '@/functions/boolean';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [mixinFaIcons],
  props: {
    ...mixinStatusProps,
    // TODO :: uses enum... use validation method once enum is available
    sync: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    isBoolean
  },
  computed: {
    selectedIcon() {
      const selected = getGatewayIconWithSyncStatus(this.status, {
        syncStatus: this.sync,
        disabled: this.disabled
      })

      const reversedIcon = !!selected.mask;

      return {
        ...selected,
        icon: reversedIcon ? selected.mask : selected.icon,
        mask: reversedIcon ? selected.icon : selected?.mask,
      }
    },
    tooltip() {
      const { selectedIcon, disabled } = this
      if (disabled || !selectedIcon?.value) return false
      return {
        triggers: ['click', 'hover', 'focus'],
        content: `Gateway: ${selectedIcon?.tooltip}`,
      }
    }
  }
});
</script>
