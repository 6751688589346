import { REPORT_DEFAULT_ICON } from '@/config/reports';
import { qx_ePrivilege_enum } from '@/graphql/types';
import { ConfigRouteObject } from '@/types';

export const REPORTS_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', REPORT_DEFAULT_ICON],
  UI_LABEL: 'Reports',
  ROUTE_PATH: 'reports',
  ROUTE_NAME: 'reports.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [qx_ePrivilege_enum.AccessReports]
};

const REPORT_VIEW_ROUTE_NAME_SHARED = 'reports.view';

// this route does not register it's ROUTE_NAME
// that is because it would duplicate the single route
export const REPORTS_VIEW_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', REPORT_DEFAULT_ICON],
  UI_LABEL: 'View Report',
  ROUTE_PATH: ':reportId',
  ROUTE_NAME: REPORT_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'reportId',
  PRIVILEGES: [qx_ePrivilege_enum.AccessReports]
};

export const REPORTS_VIEW_TEMPLATE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', REPORT_DEFAULT_ICON],
  UI_LABEL: 'Template',
  ROUTE_PATH: '',
  ROUTE_NAME: REPORT_VIEW_ROUTE_NAME_SHARED,
  ROUTE_PARAM: 'reportId',
  PRIVILEGES: [qx_ePrivilege_enum.AccessReports]
};
