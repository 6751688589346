import {
  CAMERAS_ROUTE,
  CAMERA_CREATE_ROUTE,
  CAMERA_EDIT_ROUTE,
  CAMERA_VIEW_ROUTE,
  CAMERA_VIEW_INFO_ROUTE,
  CAMERA_VIEW_CONFIG_ROUTE
} from '@/config/routes/cameras';

export default [
  /**
   * CONTAINER - All Camera Routes will pass through this container
   *  This container is responsible for handling any global Camera-related fetching.
   */
  {
    path: CAMERAS_ROUTE.ROUTE_PATH,
    components: {
      default: () => import(/* webpackChunkName: "container-cameras" */ './CamerasContainer.vue')
    },
    meta: {
      privileges: CAMERAS_ROUTE.PRIVILEGES
    },
    children: [
      /**
       * Add Camera
       */
      {
        path: CAMERA_CREATE_ROUTE.ROUTE_PATH,
        name: CAMERA_CREATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: CAMERA_CREATE_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "page-camera-create" */ './CameraCreatePage.vue')
        }
      },

      /**
       * List of Cameras
       */
      {
        path: '',
        name: CAMERAS_ROUTE.ROUTE_NAME,
        // meta: {},
        components: {
          default: () => import(/* webpackChunkName: "page-camera-list" */ './CameraListPage.vue')
        }
      },

      /**
       * CONTAINER - View Camera Routes will pass through this container
       *  Parent Route is a container to fetch the Camera object
       *  Child routes (includes view page) will reference the Camera object pulled in the container
       *  This container will hold some generic/global elements for all Camera View Pages
       */
      {
        path: CAMERA_VIEW_ROUTE.ROUTE_PATH,
        // name: CAMERA_VIEW_ROUTE.ROUTE_NAME,
        meta: {
          privileges: CAMERA_VIEW_ROUTE.PRIVILEGES,
          scopeSelectorDisabled: true
        },
        components: {
          default: () => import(/* webpackChunkName: "container-camera-view" */ './CameraContainerView.vue')
        },
        children: [
          /**
           * View Camera - Info
           */
          {
            path: CAMERA_VIEW_INFO_ROUTE.ROUTE_PATH,
            name: CAMERA_VIEW_INFO_ROUTE.ROUTE_NAME,
            meta: {
              privileges: CAMERA_VIEW_INFO_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-camera-view-info" */ './CameraViewInfoPage.vue')
            }
          },

          /**
           * View Camera - Info
           */
          {
            path: CAMERA_VIEW_CONFIG_ROUTE.ROUTE_PATH,
            name: CAMERA_VIEW_CONFIG_ROUTE.ROUTE_NAME,
            meta: {
              privileges: CAMERA_VIEW_CONFIG_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-camera-view-settings" */ './CameraViewSettingsPage.vue')
            }
          },

          /**
           * Edit Camera
           */
          {
            path: CAMERA_EDIT_ROUTE.ROUTE_PATH,
            name: CAMERA_EDIT_ROUTE.ROUTE_NAME,
            meta: {
              privileges: CAMERA_EDIT_ROUTE.PRIVILEGES
            },
            components: {
              default: () => import(/* webpackChunkName: "page-camera-edit" */ './CameraEditPage.vue')
            }
          }
        ]
      }
    ]
  }
];
