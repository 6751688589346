import { SharedLinkType } from "@/graphql/types";

// list view key
export const SAVED_CLIPS_LIST_KEY = 'saved-clips-list';

// TODO :: merge... identical to camera timelapse interval
export const CLIP_TIMELAPSE_DURATION_DECIMAL_ERROR = 'No decimal allowed';
export const CLIP_TIMELAPSE_DURATION_MIN = 10;
export const CLIP_TIMELAPSE_DURATION_MIN_ERROR = `Min ${CLIP_TIMELAPSE_DURATION_MIN} seconds`
export const CLIP_TIMELAPSE_DURATION_MAX = 3600; // 1 hour
export const CLIP_TIMELAPSE_DURATION_MAX_ERROR = `Max ${CLIP_TIMELAPSE_DURATION_MAX / 60 / 60} hours`
export const CLIP_TIMELAPSE_DURATION_ERROR = `Timelapse Duration must be either 0 (disabled), at minimum ${CLIP_TIMELAPSE_DURATION_MIN} seconds, or a max of ${CLIP_TIMELAPSE_DURATION_MAX / 60 / 60} hours (${CLIP_TIMELAPSE_DURATION_MAX} seconds).`
export const CLIP_TIMELAPSE_DURATION_TIP = 'Define the length of the clip to create from the timelapse snapshots available.';

// toast messages
export const SAVED_CLIP_INVALID_FORM = 'Please fill in all required fields';

export const SAVED_CLIP_CREATE_SUCCESS = 'Clip successfully saved/exported';
export const SAVED_CLIP_CREATE_ERROR = 'Error saving/uploading clip';

export const SAVED_CLIP_UPDATE_SUCCESS = 'Clip updated successfully';
export const SAVED_CLIP_UPDATE_ERROR = 'Error updating clip';

export const SAVED_CLIP_CONFIRM_DELETE = 'Are you sure you want to delete this clip?';
export const SAVED_CLIP_CONFIRM_DELETE_FN = (name: string) => `Are you sure you want to delete the clip, "${name}"?`;
export const SAVED_CLIP_DELETED_SUCCESS = 'Clip scheduled for deletion';
export const SAVED_CLIP_DELETED_SUCCESS_FN = (clipName: string) => `Clp "${clipName}" successfully deleted.`;
export const SAVED_CLIP_DELETED_ERROR = 'Error deleting clip';
export const SAVED_CLIP_DOWNLOAD_ERROR = 'Error downloading clip';

export const SHARED_LINK_INVALID_FORM = 'Please fill in all required fields';
export const SHARED_LINK_CREATE_SUCCESS = 'Clip shared successfully';
export const SHARED_LINK_CREATE_ERROR = 'Failed to create shared link';
export const SHARED_LINK_UPDATE_SUCCESS = 'Link updated successfully';
export const SHARED_LINK_UPDATE_ERROR = 'Error updating link';
export const SHARED_LINK_COPY_TO_CLIPBOARD = 'Copied link to clipboard';
export const SHARED_LINK_INVALID_EMAIL = 'Invalid email';
export const SHARED_LINK_CONFIRM_DELETE = 'Are you sure you want to delete this link?';
export const SHARED_LINK_DELETED_SUCCESS = 'Link successfully deleted';
export const SHARED_LINK_DELETED_ERROR = 'Error deleting link';

export const SHARED_LINK_CONFIRM_DELETE_ALL = 'Are you sure you want to delete all shared links?';
export const SHARED_LINK_DELETED_ALL_SUCCESS = 'All links successfully deleted';
export const SHARED_LINK_DELETED_ALL_ERROR = 'Error deleting all links';

export const COPIED_CHECKSUM_TO_CLIPBOARD = 'Copied checksum to clipboard';

export interface SavedClipFormData {
  id: string;
  name: string;
  description: string;
  startTime: Date | null;
  endTime: Date | null;
  mediaProfileID: string;
  expiresAt: Date | null;
  eventID: string | null;
  timelapse: boolean;
  timelapseDuration: number;
}

export const ClipFormDefault: SavedClipFormData = {
  id: '',
  name: '',
  description: '',
  startTime: null,
  endTime: null,
  mediaProfileID: '',
  expiresAt: null,
  eventID: null,
  timelapse: false,
  timelapseDuration: CLIP_TIMELAPSE_DURATION_MIN,
}

export const SHARED_LINK_FORM_OBJECT = Object.freeze({
  id: '',
  persons: [],
  emails: [],
  singleUse: false,
  expiresAt: null,
  linkableID: '',
  linkableType: SharedLinkType.Clip,
});

// number of days to show clip expiring soon icon in saved clip card
// for example, the half-full hourglass will show a week/7 days before
// the saved clip expires, and the expired hourglass will show when the
// saved clip expires within 24 hours
export const DAYS_TO_SHOW_EXPIRES_SOON = 7;
export const DAYS_TO_SHOW_EXPIRES_VERY_SOON = 1;

/**
 * Marketing Messages
 */
export const SAVED_CLIP_MARKETING_SHARE_CLOUD_ONLY =
  'Access to this feature requires a cloud subscription. To upgrade please contact your Qumulex Reseller or sales@qumulex.com.';

/**
 * Public Access Messages
 */
// messages shown when User is presented with an input field to gain access
export const SAVED_CLIP_PUBLIC_EMAIL_EMPTY = 'Please specify the associated email address.';

// User attempted to submit a value that was not a valid email address
export const SAVED_CLIP_PUBLIC_EMAIL_INVALID = 'Please supply a properly formatted email address.';

// This message shouldn't occur, it is a backup incase the email validation code and message above does not work.
export const SAVED_CLIP_PUBLIC_EMAIL_VALIDATION_FAILED = 'Failed to validate the provided email address.';

// User attempted to validate their access to the Clip; should only occur if using email.
export const SAVED_CLIP_PUBLIC_VALIDATE_ACCESS_FAILED = 'Failed to validate access.';

// Response from server if the Shared Link no longer exists or expired.
export const SAVED_CLIP_PUBLIC_LINK_MISSING = 'This link is not valid.';

// Response from server if the Clip no longer exists or expired.
export const SAVED_CLIP_PUBLIC_CLIP_MISSING = 'The file does not exist.';

// Response from server if the Clip no longer exists or expired.
export const SAVED_CLIP_PUBLIC_NOT_AUTHORIZED = 'You are not authorized.';

// Message shown on screen if User does not have Auth access and Emails are not available for the given SharedLink.
export const SAVED_CLIP_PUBLIC_NO_ACCESS = 'You do not have access.';

export const SAVED_CLIP_PUBLIC_SUPPORT_MESSAGE =
  'If you are experiencing issues accessing the file please contact your administrator.';

// Visible to the User when creating a clip and they choose "no expiration"
export const SAVED_CLIP_NO_EXPIRATION = 'Uploaded clip will have no expiration';

//
export const SAVED_CLIP_LINK_CREATED_TITLE = 'Share Link URL';
export const SAVED_CLIP_LINK_CREATED_MESSAGE = 'The link has been created and shared with anyone you may have added and/or selected.';
