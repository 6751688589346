// TODO :: https://router.vuejs.org/guide/migration/index.html#removed-star-or-catch-all-routes
import { createRouter, createWebHistory } from 'vue-router'
import { routeAuthorization } from './helpers';

// Import Routes
import AppRoutes from './app-routes';
import PublicRoutes from './public-routes';
import AuthRoutes from '@/views/auth/routes';

// Import Base Router Config Options
import { CONFIG_ROUTES } from '@/config/routes';

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-exact',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
    return { left: 0, top: 0 };
  },
  routes: [
    /**
     * Do not change the order of the routes below. It's
     * needed to maintain specificity of the routes.
     */
    ...AppRoutes,
    ...AuthRoutes,
    ...PublicRoutes,

    /**
     * Wildcard Redirect. Should the User find themselves at
     * a URL that exists outside the routes above they will
     * be redirected to the home page.
     */
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: CONFIG_ROUTES.APP_HOME }
    }
  ]
});

router.beforeEach(routeAuthorization);

export default router
