import { DialogOption, DialogActionMapType, DialogConfig, DefinedDialogOptions } from "./types";
import { defineAsyncComponent, reactive, ref, shallowRef, watch } from "vue";
import { defineStore, acceptHMRUpdate } from 'pinia';
import { defaultDialogOptions } from './config';
import { v4 } from 'uuid';
import { deepmerge } from 'deepmerge-ts';

export const useDialogStore = defineStore('dialog', () => {

  const DialogComponent = defineAsyncComponent(() =>
    import('@/store/dialog/Dialog.vue')
  );

  /**
   * It is assumed there is a component in the app
   * that uses this store and renders the Dialogs
   * when they are loaded into the below ref.
   */
  const dialogRefs = reactive(new Map());
  const instances = ref<DialogConfig[]>([]);

  /**
   * Use of a ref function to create custom
   * mapping back to the component.
   * https://vuejs.org/guide/essentials/template-refs.html#function-refs
   */
  function setRef(id: string, node: unknown) {
    dialogRefs.set(id, node);
  }

  /**
   * Every action calls `create` as it is the
   * primary method for generating a dialog.
   */
  function create(
    name: string,
    message: string,
    options = defaultDialogOptions[DialogOption.create],
    component = DialogComponent
  ) {
    return new Promise((resolve, reject) => {
      const config = {
        id: v4(),
        name,
        message,
        isResolving: false,
        promise: undefined, // not currently used, intent was to use this for handling type returns
        component: shallowRef(component),
        options: shallowRef({ ...defaultDialogOptions[DialogOption.create], ...options }),
        resolve,
        reject
      };

      instances.value.push(config);
    })
  }

  /**
   * When the list of available refs changes we
   * run through all the existing dialogs and
   * attempt to call the `open` method.
   */
  watch(dialogRefs, async () => {
    for (const config of instances.value) {
      try {
        const ref = dialogRefs.get(config.id)
        const response = await ref.open()
        config.resolve(response)
      } catch (error) {
        config.reject(error)
      } finally {
        // remove dialog config and ref after handling
        const index = instances.value.findIndex(val => val.id === config.id)
        if (index === -1) return;
        instances.value.splice(index, 1);
        dialogRefs.delete(config.id)
      }
    }
  }, { immediate: true, deep: true });

  /**
   * Predefined actions
   * ...info(), confirm(), warning(), danger(), success()
   */
  const preConfiguredDialogActions: Omit<DialogActionMapType, 'create'> = Object.keys(defaultDialogOptions).reduce((acc, key) => {
    if (key === DialogOption.create) return acc;
    Object.assign(acc, {
      [key]: async function(message: string, options?: Partial<DefinedDialogOptions>) {
        try {

          const incOptions = options || {}
          const mergedOptions = deepmerge({ ...defaultDialogOptions[key as keyof typeof defaultDialogOptions] }, incOptions)

          const customActions = Object.keys(incOptions?.actions || {});
          if (customActions.length) {
            for (const key of Object.keys(mergedOptions.actions)) {
              if (!customActions.includes(key)) {
                delete mergedOptions.actions[key as keyof typeof mergedOptions.actions];
              }
            }
          }

          return await create(key, message, mergedOptions)
        } catch (error) {
          throw error
        }
      }
    })
    return acc
  }, {} as DialogActionMapType);

  return {
    // state
    instances,
    setRef,

    // actions
    create,
    ...preConfiguredDialogActions,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDialogStore, import.meta.hot))
}
