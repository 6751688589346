import { AUDIT_VIEW_TEMPLATE_ROUTE, AUDIT_ROUTE } from '@/config/routes/audit';

export default [
  {
    path: AUDIT_ROUTE.ROUTE_PATH,
    name: AUDIT_ROUTE.ROUTE_NAME,
    components: {
      default: () => import('./AuditContainer.vue')
    },
    meta: {
      privileges: AUDIT_ROUTE.PRIVILEGES
    },
    children: [
      {
        path: AUDIT_VIEW_TEMPLATE_ROUTE.ROUTE_PATH,
        name: AUDIT_VIEW_TEMPLATE_ROUTE.ROUTE_NAME,
        meta: {
          privileges: AUDIT_VIEW_TEMPLATE_ROUTE.PRIVILEGES
        },
        components: {
          default: () => import('./AuditTemplatePage.vue')
        }
      }
    ]
  }
];
