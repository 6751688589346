import { defineComponent, onBeforeUnmount, onBeforeMount } from 'vue';
import { AppVisibilityBus } from '@/events/app-visibility-bus';
import { useSubscription } from '@vue/apollo-composable';
import { GqlErrorBus } from '@/events/gql-error-bus';
import { gql } from '@apollo/client/core';
import { useAuthStore } from './../auth';
import { useConnectionStore } from '.';
import { Interval } from '@/types';

const GQL_SUB_HEARTBEAT = gql`
  subscription GQL_SUB_HEARTBEAT {
    heartbeat
  }
`;

const HEARTBEAT_RETRY_INTERVAL = 15000;

export const ConnectionManager = defineComponent({
  name: 'ConnectionManager',
  setup(props: any, context: any) {

    /**
     * Contains ref data that we update via
     * the manager, so the rest of the app
     * can be informed.
     */
    const connection = useConnectionStore();

    const auth = useAuthStore();

    /**
     * Watch for changes to Agent Heartbeat
     */
    const { onResult, restart } = useSubscription(
      GQL_SUB_HEARTBEAT,
      null,
      () => ({
        enabled: !!auth.isReady
      })
    );

    let heartbeatInterval: Interval | null = null;
    function retryHeartbeat() {
      if (!!heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }
      heartbeatInterval = setInterval(() => {
        if (!connection.heartbeat) {
          console.warn('Retrying heartbeat subscription.')
          restart();
        }
      }, HEARTBEAT_RETRY_INTERVAL)
    }

    onResult((result) => {
      // if we have a heartbeat, but previously we did not, update it
      if (!!result.data?.heartbeat && !connection.heartbeat) {
        connection.heartbeat = !!result.data.heartbeat
        if (!!heartbeatInterval) {
          clearInterval(heartbeatInterval);
        }
      }
    });

    onBeforeMount(() => {

      /**
       * When the PWA comes back to the foreground, sometimes
       * subscriptions are lost and we need to reconnect.
       */
      AppVisibilityBus.on('visibility-change', ({ detail }) => {
        if (detail === 'visible' && !connection.heartbeat) {
          retryHeartbeat();
        }
      });

      /**
       * When GQL Network Errors occur.
       */
      GqlErrorBus.on('error', ({ detail }) => {

        if (!detail.networkError) return;

        // if related to heartbeat and heartbeat was considered enabled
        if (detail.operation.operationName === 'GQL_SUB_HEARTBEAT') {
          // if heartbeat is already false, do nothing, an interval is already retrying
          if (!connection.heartbeat) return;
          console.warn(`Heartbeat subscription failed, retrying in ${HEARTBEAT_RETRY_INTERVAL/1000} seconds.`)
          connection.heartbeat = false;
          retryHeartbeat();
        }
      });
    });

    onBeforeUnmount(() => {
      AppVisibilityBus.off('visibility-change', () => {});
      GqlErrorBus.off('error', () => {});
    });

    return () => context.slots.default({
      status: connection.status
    });
  },
});
