import { DOOR_DEFAULT_ICON, LOCK_DEFAULT_ICON, UNLOCK_DEFAULT_ICON } from '@/constants/door';
import { generateRuleActionConfig } from './generate-rule-action-config';
import { RuleActionOverrideType, RuleAction, } from './../types';
import { SCHEDULE_DEFAULT_ICON } from '@/constants/schedule';
import { CAMERA_DEFAULT_PTZ_ICON } from '@/constants/camera';
import { DEVICE_DEFAULT_OUTPUT_ICON } from '@/constants/device';
import { FlowActions } from '@/models/rule/types';
import { validateEmail } from '@/functions/email';
import { max, min } from '@vee-validate/rules';
import { asyncSome } from '@/functions/array';
import { isValidUrl } from '@/functions/url';
import { defineAsyncComponent } from 'vue';
import {
  RULE_ACTION_SMS_BODY_CHARACTER_MINIMUM,
  RULE_ACTION_SMS_BODY_CHARACTER_LIMIT,
  RULE_FORM_ERRORS,
} from '@/config/rule';

type RuleActionSmsForm = RuleAction & {
  arguments: {
    phoneNumbers: string[];
    people: string[];
    message: string;
  }
}

type RuleActionEmailForm = RuleAction & {
  arguments: {
    subject: string;
    body: string;
    emails: string[];
    people: string[];
  }
}

type RuleActionWebhookForm = RuleAction & {
  arguments: {
    url: string;
    headers: Record<string, string>;
  }
}

export const UNKNOWN_RULE_ACTION = {
  title: 'Unknown Action',
  desc: 'An action that is not recognized by the system. It is possible this action was created by Support for unique scenarios.',
  icon: ['fas', 'question'],
  arguments: {},
  validate: async () => true,
};

export const ruleActionOverrides: Partial<{
  [key in FlowActions]: Partial<RuleActionOverrideType>;
}> = {
  [FlowActions.PtzMoveToPreset]: {
    title: 'Camera: PTZ Move to Preset',
    desc: 'Position camera lens to a preset location',
    icon: ['fas', CAMERA_DEFAULT_PTZ_ICON],
    arguments: {
      videoSourceID: {
        props: {
          relation: 'videoSource',
          params: {
            where: {
              ptzSettings: {
                _contains: {
                  enabled: true,
                  absoluteMoveSetting: {
                    available: true
                  },
                  continuousMoveSetting: {
                    available: true
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  [FlowActions.LockDownDoor]: {
    title: 'Door: Lockdown',
    desc: 'Put a Door in Lockdown',
    icon: ['fas', LOCK_DEFAULT_ICON],
  },
  [FlowActions.LockDoor]: {
    title: 'Door: Lock',
    desc: 'Lock a Door',
    icon: ['fas', LOCK_DEFAULT_ICON],
  },
  [FlowActions.UnlockDoor]: {
    title: 'Door: Unlock',
    desc: 'Unlock a Door',
    icon: ['fas', UNLOCK_DEFAULT_ICON],
  },
  [FlowActions.ReleaseLockDownDoor]: {
    title: 'Door: Lockdown Release',
    desc: 'Release a Door from Lockdown',
    icon: ['fas', UNLOCK_DEFAULT_ICON],
  },
  [FlowActions.AccessDoor]: {
    title: 'Door: Access Door',
    desc: 'Perform a momentary unlock',
    icon: ['fas', DOOR_DEFAULT_ICON],
  },
  [FlowActions.RevertDoor]: {
    title: 'Door: Revert Schedule',
    desc: 'Revert Door Schedule to default state',
    icon: ['fas', SCHEDULE_DEFAULT_ICON],
  },
  // TODO :: remove custom components, uses custom language
  [FlowActions.LockDownPlace]: {
    title: 'Place: Lockdown',
    desc: 'Put a Scope in Lockdown',
    icon: ['fas', LOCK_DEFAULT_ICON],
    displayComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionLockDownPlace.vue')),
    formComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionLockDownPlaceForm.vue'))
  },
  // TODO :: remove custom components, uses custom language
  [FlowActions.ReleaseLockDownPlace]: {
    title: 'Place: Lockdown Release',
    desc: 'Remove a Scope from Lockdown',
    icon: ['fas', 'unlock-alt'],
    displayComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionReleaseLockDownPlace.vue')),
    formComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionReleaseLockDownPlaceForm.vue'))
  },
  // TODO :: remove custom components, uses custom language in display
  [FlowActions.TriggerOutput]: {
    title: 'Output: Trigger',
    desc: 'Activate relay of an Output',
    icon: ['far', DEVICE_DEFAULT_OUTPUT_ICON],
    displayComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionTriggerOutput.vue')),
    formComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionTriggerOutputForm.vue'))
  },
  // TODO :: remove custom components,uses custom language in display
  [FlowActions.ResetOutput]: {
    title: 'Output: Reset',
    desc: 'Reset the relay of an Output',
    icon: ['far', DEVICE_DEFAULT_OUTPUT_ICON],
    displayComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionResetOutput.vue')),
    formComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionResetOutputForm.vue'))
  },
  [FlowActions.NotificationEmail]: {
    title: 'Notification: Email',
    desc: 'Send an Email',
    icon: ['fas', 'envelope'],
    form: {
      type: FlowActions.NotificationEmail,
      arguments: {
        body: '',
        _data: '',
        emails: [],
        people: [],
        subject: ''
      },
      rateLimit: undefined
    },
    validate: async (form: RuleActionEmailForm) => {
      try {
        const hasEmails = hasAtLeastOneEmail(form.arguments.emails);
        const hasPeople = hasAtLeastOnePersonSelected(form.arguments.people);

        if (!hasEmails && !hasPeople) {
          throw new Error(RULE_FORM_ERRORS.RULE_ACTION_EMAIL_AT_LEAST_ONE_EMAIL_REQUIRED);
        }

        if (!form.arguments.subject) {
          throw new Error(RULE_FORM_ERRORS.RULE_ACTION_EMAIL_SUBJECT_REQUIRED);
        }

        if (!form.arguments.body) {
          throw new Error(RULE_FORM_ERRORS.RULE_ACTION_EMAIL_BODY_REQUIRED);
        }

        return true

      } catch (error) {
        throw error
      }
    },
    displayComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionNotificationEmail.vue')),
    formComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionNotificationEmailForm.vue'))
  },
  [FlowActions.NotificationSms]: {
    title: 'Notification: SMS',
    desc: 'Send a text message',
    icon: ['fas', 'message-sms'],
    form: {
      type: FlowActions.NotificationSms,
      arguments: {
        _data: '',
        phoneNumbers: [],
        people: [],
        message: ''
      },
      rateLimit: undefined
    },
    validate: async (form: RuleActionSmsForm) => {
      try {
        /**
         * Throw an error if the SMS copy is invalid.
         * There are 2 potential error messages.
         */
        throwIfInvalidSmsBody(form.arguments.message)

        /**
         * Confirm that at least a single phone
         * number or person was selected.
         */
        const hasPhoneNumber = hasAtLeastOnePhoneNumber(form.arguments.phoneNumbers)
        const hasPeople = hasAtLeastOnePersonSelected(form.arguments.people)
        if (!hasPhoneNumber && !hasPeople) {
          throw new Error(RULE_FORM_ERRORS.RULE_ACTION_SMS_AT_LEAST_ONE_PHONE_NUMBER_REQUIRED)
        }

        return true

      } catch (error) {
        throw error
      }
    },
    displayComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionNotificationSms.vue')),
    formComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionNotificationSmsForm.vue'))
  },
  [FlowActions.Webhook]: {
    title: 'Notification: Webhook',
    desc: 'Inform a 3rd party',
    icon: ['fas', 'webhook'],
    validate: async (form: RuleActionWebhookForm) => {
      try {

        /**
         * Throw an error if the SMS copy is invalid.
         * There are 2 potential error messages.
         */
         if (!isValidUrl(form.arguments.url)) {
          throw new Error(RULE_FORM_ERRORS.RULE_ACTION_WEBHOOK_INVALID_URL);
        }

        return true
      } catch (error) {
        throw error
      }
    },
    displayComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionWebhook.vue')),
    formComponent: defineAsyncComponent(() => import('@/models/rule/components/actions/action/RuleActionWebhookForm.vue'))
  },
};

/**
 * Determines whether the
 * sms message is valid.
 */
export function throwIfInvalidSmsBody(message: string): boolean {
  const minValid = min(message, { length: RULE_ACTION_SMS_BODY_CHARACTER_MINIMUM });
  const maxValid = max(message, { length: RULE_ACTION_SMS_BODY_CHARACTER_LIMIT });

  if (!minValid) {
    throw new Error(RULE_FORM_ERRORS.RULE_ACTION_SMS_BODY_BELOW_CHARACTER_MINIMUM);
  }

  if (!maxValid) {
    throw new Error(RULE_FORM_ERRORS.RULE_ACTION_SMS_BODY_EXCEEDED_CHARACTER_LIMIT);
  }

  return true
}

export function hasAtLeastOneEmail(emails: string[]): boolean {

  if (!emails.length) return false

  // if an email provided is invalid, throw an error
  for (const email of emails) {
    if (!validateEmail(email)) {
      throw new Error(`bad email: ${email}`)
    }
  }

  return true
}

export function hasAtLeastOnePhoneNumber(phoneNumbers: string[]): boolean {
  return !!phoneNumbers?.length
}

export function hasAtLeastOnePersonSelected(peopleIDs: string[]): boolean {
  return !!peopleIDs?.length
}

export const validateActions = async (actions: RuleAction[]): Promise<boolean> => {
  try {
    const hasInvalidActions = await asyncSome<RuleAction>(
      actions,
      async (action): Promise<boolean> => {
        try {
          // if action does not exist, assume user is in advance mode

          const config = await generateRuleActionConfig(action)
          if (!config) {
            return true;
          }

          const isValid = await config.validate(action);
          return !isValid
        } catch (error) {
          throw error
        }
      }
    )
    return !hasInvalidActions

  } catch (error) {
    throw error
  }
}
