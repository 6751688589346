import { DialogOption, DialogOptionsMapType } from "./types";

const defaultDialogOkText = 'Ok';
const defaultDialogCancelText = 'Cancel';

export const defaultDialogOptions: DialogOptionsMapType = {
  [DialogOption.create]: {
    color: 'neutral',
    actions: {
      ok: {
        text: defaultDialogOkText,
        color: 'primary',
        value: true,
      },
      cancel: {
        text: defaultDialogCancelText,
        color: 'neutral',
        value: false,
      },
    }
  },
  [DialogOption.info]: {
    title: 'Information',
    color: 'info',
    actions: {
      ok: {
        text: defaultDialogOkText,
        color: 'neutral',
        value: true
      },
    }
  },
  [DialogOption.confirm]: {
    title: 'Confirmation Required',
    color: 'primary',
    actions: {
      ok: {
        text: defaultDialogOkText,
        color: 'primary',
        value: true
      },
      cancel: {
        text: defaultDialogCancelText,
        color: 'neutral',
        value: false,
      },
    }
  },
  [DialogOption.warning]: {
    title: 'Warning',
    color: 'warning',
    actions: {
      ok: {
        text: defaultDialogOkText,
        color: 'danger',
        value: true
      },
      cancel: {
        text: defaultDialogCancelText,
        color: 'neutral',
        value: false
      },
    }
  },
  [DialogOption.danger]: {
    title: 'Attention Needed',
    color: 'danger',
    actions: {
      ok: {
        text: defaultDialogOkText,
        color: 'danger',
        value: true
      },
      cancel: {
        text: defaultDialogCancelText,
        color: 'neutral',
        value: false
      },
    }
  },
  [DialogOption.success]: {
    title: 'Success',
    color: 'success',
    actions: {
      ok: {
        text: defaultDialogOkText,
        color: 'neutral',
        value: true
      },
    }
  }
}
