import { qx_ePrivilege_enum } from '@/graphql/types';
import type { ConfigRouteObject } from '@/types';

export const LIVE_ROUTE: ConfigRouteObject = {
  UI_ICON: ['fal', 'chart-tree-map'],
  UI_LABEL: 'Live',
  ROUTE_PATH: 'live',
  ROUTE_NAME: 'live.index',
  ROUTE_PARAM: '',
  PRIVILEGES: [
    qx_ePrivilege_enum.LiveVideo,
    qx_ePrivilege_enum.DoorView,
    qx_ePrivilege_enum.CameraView,
    qx_ePrivilege_enum.DeviceView,
    qx_ePrivilege_enum.GatewayView,
    qx_ePrivilege_enum.LiveEvents,
  ]
};
