import { setLocale } from 'yup';
import type { App } from 'vue';

import defaultRules from './defaults';

export default async (app: App) => {

  setLocale({
    mixed: {
      required: ({ label }) => label ? `${label} is required` : 'required'
    }
  })

  defaultRules(app);

  return;
};
