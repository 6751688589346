import { createHead } from '@unhead/vue';
import type { App } from 'vue';

// https://unhead.unjs.io/setup/vue/installation
export default async (app: App) => {

  const head = createHead();
  app.use(head);

  return;
};
