import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

export const mixinFaIcons = {
  components: {
    FaLayer: FontAwesomeLayers,
  }
}

export const mixinStatusProps = {
  status: {
    required: false,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: undefined
  },
}
