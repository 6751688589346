import { TIMELINE_ROUTE } from '@/config/routes/timeline';

export default [
  {
    path: TIMELINE_ROUTE.ROUTE_PATH,
    name: TIMELINE_ROUTE.ROUTE_NAME,
    components: {
      default: () => import(/* webpackChunkName: "page-timeline" */'@/views/timeline/TimelinePage.vue'),
    },
    meta: {
      fillHeight: true,
      privileges: TIMELINE_ROUTE.PRIVILEGES
    }
  }
];
